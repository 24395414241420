<template>
  <div class="news-page-wrapper">
    <div class="image-wrapper">
      <img
        :src="blogPage.imageFile"
        :alt="blogPage.title"
      >
    </div>
    <h1>{{ blogPage.title }}</h1>
    <h3>{{ blogPage.subtitle }}</h3>
    <p>{{ blogPage.content }}</p>
    <p>
      Проект: <span>{{ project.nameEng }}</span>
    </p>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      blogPages: "blogPages",
      tokens: "tokens"
    }),

    blogPage() {
      return _.find(this.blogPages, { id: Number(this.$attrs.id) });
    },

    project() {
      return _.find(this.tokens, token => token.id == _.split(this.blogPage.auToken, "/")[3]);
    }
  }
};
</script>

<style lang="scss" scoped>
  .news-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .image-wrapper {
      img {
        width: 100%;
      }
    }
  }
</style>