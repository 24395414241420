<template>
  <div class="card">
    <div class="card__header">
      <div class="fs15">
        {{ $t("dashboard.tradingLeaders.label") }}
      </div>

      <a
        href="#"
        class="card__action"
      >
        {{ $t("dashboard.tradingLeaders.goTo") }}
        <AuIcon icon="chevron_right" />
      </a>
    </div>

    <div class="period">
      1 октября
    </div>

    <div class="trading-leaders">
      <TradingLeader
        v-for="(leader, index) in leaders"
        :key="index"
        :icon="leader.icon"
        :name="leader.name"
        :values="leader.values"
      />
    </div>
  </div>
</template>

<script>
import TradingLeader from "./TradingLeader.vue";

export default {
  components: {
    TradingLeader
  },

  data() {
    return {
      leaders: [
        { icon: "seb_logo", name: "SEB", values: [209100, 200041, 217936, 233659, 217036, 227936] },
        { icon: "hsbc_logo", name: "HSBC", values: [209100, 200041, 217936, 233659, 217036, 207936] },
        { icon: "tolyatti_bank_logo", name: "Тольяти-Банк", values: [201100, 202241, 217076, 213659, 217036, 217936] },
        { icon: "otkritie_bank_logo", name: "Открытие-Банк", values: [209100, 200041, 207936, 200659, 201036, 210936] }
      ]
    };
  }
};

</script>

<style scoped lang="scss">
.period {
  margin: 15px 0px;
  font-size: 11px;
}

.trading-leaders {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card {
  width: 100%;
  height: 305px;
  padding: 15px 22px 22px 22px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 11px;
  }

  &__action {
    font-size: 12px;
    color: var(--color-40);
    text-decoration: none;
  }
}
</style>