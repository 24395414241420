<template>
  <div class="profile-menu">
    <div class="fs14">
      {{ $auth.remember() }}
    </div>

    <div
      class="profile-menu__item"
      @click="openProfile"
    >
      {{ $t("profileMenu.profile") }}
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('referral program')"
    >
      {{ $t("profileMenu.referralProgram") }}
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('payment method')"
    >
      {{ $t("profileMenu.paymentMethod") }}
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('security')"
    >
      {{ $t("profileMenu.security") }}
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('settings')"
    >
      {{ $t("profileMenu.settings") }}
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('support')"
    >
      {{ $t("profileMenu.support") }}
    </div>

    <div class="logout">
      <div
        class="profile-menu__item "
        @click="logout"
      >
        {{ $t("profileMenu.logout") }}

        <AuIcon
          class="logout__icon"
          icon="logout"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$router.push("/logout");
    },

    openProfile() {
      this.$router.push({ path: "user", query: { username: this.name } });
    },

    openPage(name) {
      this.$router.push({ name });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-menu
{
  width: 245px;
  background-color: var(--menu-color);
  border-radius: 20px;
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 17px;

  &__item {
    font-size: 13px;
    color: var(--color-60);
    cursor: pointer;

    &:hover {
      color: var(--text-color);
    }
  }

  &__profile {
    font-size: 13px;
  }
}

.logout {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
  border-top: 1px dashed var(--color-60);
  margin-top: 15px;

  &__icon {
    margin-left: 5px;
  }
}
</style>