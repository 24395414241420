export const MARKET_TYPES = {
  LIMIT: 0,
  MARKET: 1,
  STOP_LIMIT: 2
};

export const ORDER_TYPES = {
  LOG: 0,
  COUNT: 1
};

export const MODES = {
  BUY: 0,
  SALE: 1
};

export const ORDER_MODES = {
  OPEN_ORDERS: 0,
  HISTORY_ORDERS: 1,
  TRANSACTION_HISTORY: 2,
  FACILITIES: 3
};

