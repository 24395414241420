<template>
  <div
    class="right-panel panel"
    :adaptive="$route.meta.adaptiveRightPanel"
  >
    <div class="panels">
      <Briefcase
        class="right-panel__briefcase"
      />

      <Bankcard class="right-panel__bankcard" />
    </div>
  </div>
</template>

<script>
import Bankcard from "./components/Bankcard";
import Briefcase from "./components/Briefcase";

export default {
  components: {
    Bankcard,
    Briefcase
  }
};
</script>

<style lang="scss" scoped>
.panels {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 1200px) and (min-width: 576px) {
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
  }

  @media screen and (max-width: 576px) {
    background-color: var(--main-color);
    width: 100%;
    max-width: 100%;
  }
}
.managers-panels {
  display: flex;
  flex-direction: column;
  gap: 20px
}
.rate {
  font-size: 13px;
  color: var(--color-60);
  text-decoration: none;

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.right-panel {
  max-width: 370px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-left: 1px solid var(--color-90);

  gap: 20px;

  padding: 25px;

  @media screen and (max-width: 1200px) and (min-width: 840px) {
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    background-color: var(--main-color);
    padding-left: 25px;
    padding-right: 30px;
    border-left: none;
    justify-content: space-between;
  }

  @media screen and (max-width: 840px) {
    background-color: var(--main-color);
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    align-items: stretch;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 25px;
  }

  &:not([adaptive=true]) {
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}
</style>