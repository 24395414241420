<template>
  <div
    :style="cssVars"
    class="card exchange-rate"
    @click="showTokenInfo"
  >
    <div class="exchange-rate__item">
      <div class="exchange-rate__header">
        <div class="exchange-rate__simbols">
          <div class="exchange-rate__icon-wrap">
            <img
              class="img-flag"
              :src="icon"
              alt=""
            >
          </div>

          <AuIcon
            class="follow"
            :icon="rateIcon"
            @mouseenter="hovered = true"
            @mouseleave="hovered = false"
            @click="starClick"
          />
        </div>

        <div class="rate-name">
          {{ name }}
        </div>
      </div>

      <div class="chart-size">
        <Apexchart
          :options="options"
          :series="series"
        />

        <div class="growth-percent">
          <AuIcon
            v-if="!isNaN(percent)"
            :icon="percent > 0 ? 'rate_up_green' : percent < 0 ? 'rate_down_red' : ''"
          />
          <div
            v-if="!isNaN(percent) && percent !== Infinity"
            class="fs12"
          >
            {{ Math.abs(percent) }}%
          </div>
        </div>
      </div>
    </div>
    <div class="balance-split-wrapper">
      <div class="crypto-balance">
        <span>
          {{ $t('exhangeRateBottom.cryptoBalance') }}
        </span>
        <AuDecimalStatic
          :value="value"
          :decimal="decimals"
        />
      </div>
      <div class="spot-balance">
        <span>
          {{ $t('exhangeRateBottom.spotBalance') }}
        </span>
        <AuDecimalStatic
          :value="spotBalance"
          :decimal="decimals"
        />
      </div>
    </div>
    <div class="exchange-rate__item">
      <div class="rate">
        <div>
          <AuDecimalStatic
            :value="fullValue"
            :addition="mnemonic"
            :decimal="decimals"
          />
        </div>
        <div
          v-tooltip.bottom="`1 ${mnemonic} = ${course} ${defaultTokenMnemonic}`"
          class="min-text"
        >
          <AuDecimalStatic
            :value="valueDefault"
            :addition="currency"
            :decimal="decimals"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import CurrencySupport from "@/help/currencySupport";
import { mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    values: {
      type: Array,
      required: true
    },
    valueDefault: {
      type: [String, Number],
      default: null
    },
    icon: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    decimals: {
      type: Number,
      default: 0
    },
    mnemonic: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      hovered: false,
      currencySup: new CurrencySupport()
    };
  },

  computed: {
    ...mapGetters({
      followings: "followings",
      exchangeBalances: "exchangeBalances",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),

    fullValue() {
      return (Number(this.value) + Number(this.spotBalance)).toString();
    },

    course() {
      let currency = this.currencySup.getCurrency(this.id, this.defaultTokenMnemonic);
      return currency ? Number(currency).toFixed(2) : 0;
    },

    rateIcon() {
      if (this.hovered) {
        return "star-hovered";
      }

      return this.isFollowing ? "star-following" : "star";
    },

    spotBalance() {
      return _.get(_.find(this.exchangeBalances, { token: `/api/au_tokens/${this.id}` }), "amount") || "0";
    },

    series() {
      return [{
        name: "Изменение показателя",
        data: this.values
      }];
    },

    isFollowing() {
      return _.some(this.followings, { auToken: "/api/au_tokens/" + this.id });
    },

    percent() {
      return Number(((this.values[0] - this.values[this.values.length - 1]) * -100 / this.values[0]).toFixed(1));
    },

    rate() {
      if (this.values[3]) {
        return this.values[3].toLocaleString("ru");
      }
      else {
        return "-";
      }
    },

    currentColor() {
      if (this.percent > 0) {
        return "#5AC396";
      }
      else if (this.percent < 0) {
        return "#E94545";
      }

      return "#fff";
    },

    cssVars() {
      return {
        "--color": this.currentColor
      };
    },

    options() {
      return {
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          width: 3,
          curve: "smooth"
        },
        chart: {
          type: "line",
          sparkline: {
            enabled: true
          },
          labels: {
            show: false
          }
        },
        title: {
          show: false
        },
        yaxis: {
          min: _.min(this.values) - _.sum(this.values) / 100,
          max: _.max(this.values) + _.sum(this.values) / 100,
          labels: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            colorStops: [
              [
                {
                  offset: 0,
                  color: this.currentColor,
                  opacity: 0
                },
                {
                  offset: 50,
                  color: this.currentColor,
                  opacity: 1
                },
                {
                  offset: 100,
                  color: this.currentColor,
                  opacity: 0
                }
              ]
            ]
          }
        }
      };
    }
  },

  methods: {
    showTokenInfo() {
      this.$router.push({ name: "token info", params: { id: this.id } });
    },

    starClick() {
      if (this.isFollowing) {
        this.$store.dispatch("unsetFollowing", this.id);
      }
      else {
        this.$store.dispatch("setFollowing", this.id);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.growth-percent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 5px;

  i,
  div {
    color: var(--color);
  }
}

.rate {
  font-weight: 500;
  font-size: 15px;
  color: var(--color-20);

  &-name {
    color: var(--color-50);
    font-weight: 500;
    font-size: 12px;
  }
}

.balance-split-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1270px) and (min-width: 1201px) {
    flex-direction: column;
  }

  @media screen and (max-width: 900px) and (min-width: 640px) {
    flex-direction: column;
  }

  @media screen and (max-width: 600px) and (min-width: 450px) {
    flex-direction: column;
  }

  .crypto-balance,
  .spot-balance {
    display: flex;
    flex-direction: column;

    span {
      &:first-child {
        color: var(--color-50);
        font-weight: 500;
        font-size: 12px;
      }

      &:last-child {
        font-size: 11px;
        color: var(--color-60);
      }
    }
  }
}

.exchange-rate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;


  height: 190px;
  flex: 1 1 auto;

  &:last-child {
    flex: 0 1 31%;
  }

  &__icon-wrap {
    width: 26px;
    height: 26px;

    background: var(--icon-background-color);
    border-radius: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__simbols {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 11px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1270px) and (min-width: 1201px) {
    height: 210px;
  }

  @media screen and (max-width: 900px) and (min-width: 640px) {
    height: 210px;
  }

  @media screen and (max-width: 600px) and (min-width: 450px) {
    height: 210px;
  }

  @media screen and (max-width: 640px) and (min-width: 450px) {
    flex: 1 1 calc((100% / 3) - 20px);
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    height: 163px;
  }
}

.img-flag {
  width: 20px;
  object-fit: cover;
}

.chart-size {
  width: 68px;
}

.min-text {
  font-size: 11px;
  color: var(--color-60);
}
</style>
