<template>
  <div>
    <AuBreadcrumb />

    <div class="card">
      <div>
        <div class="d-flex justify-content-between token-header">
          <div class="logo-wrap">
            <img
              class="logo"
              :src="icon"
              alt="cryptoactive"
            >

            {{ correctToken.nameEng }}
          </div>
          <div>
            <span>1 {{ correctToken.mnemonicEng }} <AuIcon icon="swap_horizontal_green" /> {{ course }} {{ defaultTokenMnemonic }}</span>
          </div>
        </div>
        <div class="mt-2">
          <span class="balance">
            <AuDecimalStatic
              class="balance__value"
              :value="balance"
              :addition="correctToken.mnemonicEng"
              :indent="correctToken.indent"
              :decimal="correctToken.decimals"
            />

            <div class="balance__string">
              {{ $t("tokeninfo.balance") }}
            </div>
            <div>
              {{ $t("tokeninfo.cryptoBalance") }} -
              <AuDecimalStatic
                :value="cryptoBalance"
                :addition="correctToken.mnemonicEng"
                :indent="correctToken.indent"
                :decimal="correctToken.decimals"
              />
            </div>
            <div>
              {{ $t("tokeninfo.spotBalance") }} -
              <AuDecimalStatic
                :value="spotBalance"
                :addition="correctToken.mnemonicEng"
                :indent="correctToken.indent"
                :decimal="correctToken.decimals"
              />
            </div>
          </span>
        </div>
        <div class="token-buttons__panel">
          <div class="token-buttons__address">
            <AuIcon
              icon="copy"
              clickable
              @click="copyToClipboard"
            />

            <div
              class="action-button"
              @click="goToScanner"
            >
              {{ $filters.shortAddress(correctToken.createdAddress) }}
            </div>
          </div>

          <div class="token-buttons">
            <div class="token-buttons__block">
              <AuButton
                center
                bordered
                font-size="12px"
                height="42px"
                width="100"
              >
                <div class="btn_metamask">
                  <div
                    class="metamask"
                    @click="metamaskAddEthereumCoin(correctToken)"
                  />
                  <div @click="metamaskAddEthereumCoin(correctToken)">
                    {{ $t("tokeninfo.addToWallet") }}
                  </div>
                </div>
              </AuButton>

              <AuButton
                center
                bordered
                font-size="12px"
                height="42px"
                width="100"
              >
                SWAP
              </AuButton>
            </div>

            <div class="token-buttons__block">
              <AuButton
                v-if="correctToken.isVoiting"
                center
                bordered
                font-size="12px"
                height="42px"
                width="100"
              >
                {{ $t("tokeninfo.dao") }}
              </AuButton>

              <AuButton
                center
                bordered
                font-size="12px"
                height="42px"
                width="100"
              >
                {{ $t("tokeninfo.refill") }}
              </AuButton>

              <AuButton
                center
                bordered
                font-size="12px"
                height="42px"
                width="100"
              >
                <RouterLink
                  class="a_btn"
                  :to="`/wallet/send-token/${id}`"
                >
                  {{ $t("tokeninfo.transfer") }}
                </RouterLink>
              </AuButton>

              <AuButton
                center
                bordered
                font-size="12px"
                height="42px"
                width="100"
              >
                {{ $t("tokeninfo.withdrawal") }}
              </AuButton>
            </div>
          </div>
        </div>

        <div class="filter-panel">
          <Datepicker
            v-model="date"
            locale="ru"
            auto-apply
            :max-date="new Date()"
            :enable-time-picker="false"
            :format="format"
            :placeholder="$t('transactions.datePickerPlaceholder')"
            range
            :dark="!isLightTheme"
            input-class-name="dp-custom"
          />

          <AuSelect
            :value="type"
            class="type-select"
            :options="typeArray"
            :placeholder="$t('transactions.transactionType.placeholder')"
            @input="value => type = value"
          />
        </div>
      </div>

      <div
        v-if="!empty"
      >
        <div class="card__header-line">
          <div class="card__header-date">
            <span>{{ typeTitle }} </span> <span class="card__header-month"> {{ dateTitle }} </span>
          </div>

          <div class="card__action">
            <div class="fs14">
              {{ spending }} {{ defaultTokenMnemonic }}
            </div>
            <AuButton
              :icon="isDetailView ? 'chevron_up' : 'chevron_down'"
              @click="switchView"
            />
          </div>
        </div>

        <div
          v-if="isDetailView"
          class="transactions-info"
        >
          <div class="transactions-info__chart">
            <AuButton
              icon="chevron_left_big"
            />

            <Apexchart
              type="donut"
              :options="options"
              :series="series"
              height="200px"
              width="200px"
            />

            <AuButton
              icon="chevron_right_big"
            />
          </div>

          <div class="transactions-info__cards">
            <TransactionCard
              v-for="(card, index) in cards"
              :key="index"
              :name="card.name"
              :value="getFormatedValue(card.value)"
              :color="card.color"
            />
          </div>
        </div>

        <div class="transactions-sector">
          <div
            v-for="(group, groupIndex) in transactions"
            :key="groupIndex"
            class="transactions-group"
          >
            <div class="period">
              {{ group.date }}
            </div>

            <div class="transactions">
              <LastTransaction
                v-for="(transaction, index) in group.transactions"
                :key="index"
                class="transaction"
                :icon="transaction.icon"
                :name="transaction.name"
                :link="transaction.link"
                :transaction-type="transaction.type"
                :amount="transaction.amount"
                :is-refill="transaction.isRefill"
                :card-type="transaction.cardType"
                :decimals="transaction.decimals"
                :mnemonic="transaction.mnemonic"
                :hash="transaction.hash"
                :address-eth="transaction.addressEth"
                :address-from="transaction.addressFrom"
                :address-to="transaction.addressTo"
              />
            </div>
          </div>
        </div>

        <div class="transactions-footer">
          <AuButton
            v-if="showGetMore < totalItems"
            height="42px"
            font-size="12px"
            bordered
            @click="getMore"
          >
            {{ $t("tokeninfo.loadMore") }}
          </AuButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import Datepicker from "@vuepic/vue-datepicker";
import LastTransaction from "@/components/lastTransactions/LastTransaction";
import MetaMask from "@/mixins/MetaMask";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";

import TransactionCard from "@/components/lastTransactions/TransactionCard.vue";

export default {
  name: "TokenInfo",

  components: {
    LastTransaction,
    Datepicker,
    TransactionCard
  },

  mixins: [MetaMask],

  props: {
    id: {
      type: String,
      default: "0"
    }
  },

  data() {
    return {
      currencySup: new CurrencySupport(),
      scanURL: process.env.VUE_APP_SCAN_BASE_URL,
      addressEth: null,
      transactions: [],
      page: 1,
      totalItems: 1,
      showGetMore: 0,
      balance: null,
      cryptoBalance: null,
      spotBalance: null,
      correctToken: {},
      typeArray: [{ display: this.$t("transactions.transactionType.all"), value: "all" },
        { display: this.$t("transactions.transactionType.receipts"), value: "receipts" },
        { display: this.$t("transactions.transactionType.spending"), value: "spending" }],
      type: null,
      date: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
      isDetailView: false,
      cards: [
        { value: 2164927, name: "Переводы", color: "#489EE1" },
        { value: 679608.75, name: "Инвестиции", color: "#E2A64D" },
        { value: 95020, name: "Товары", color: "#299667" },
        { value: 679382.75, name: "Биржа", color: "#9720F6" }
      ]
    };
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses",
      isLightTheme: "isLightTheme",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      contacts: "marketplace/userContact",
      spot: "spot"
    }),

    series() {
      return _.map(this.cards, "value");
    },

    options() {
      return {
        labels: _.map(this.cards, "name"),
        pie: {
          expandOnClick: false
        },
        colors: _.map(this.cards, "color"),
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return value.toLocaleString("ru", { style: "currency", currency: "RUB" }).replace(",00", "");
            }
          }
        },
        legend: {
          show: false
        }
      };
    },

    typeTitle() {
      return this.$t("transactions.titleType.spending");
    },

    dateTitle() {
      let dateTitle = "всё время";
      if (this.date) {
        dateTitle = this.format(this.date);
      }
      return dateTitle;
    },

    spending() {
      return Number(this.balance);
    },

    course() {
      let currency = this.currencySup.getCurrency(this.id, this.defaultTokenMnemonic);
      return currency ? Number(currency).toFixed(2) : 0;
    },

    empty() {
      return this.transactions.length === 0;
    },

    ourEth() {
      return _.find(this.arrEth, eth => !_.isNil(eth.verifiedAt))?.value;
    },

    icon() {
      return _.get(this.correctToken, "logoMain", "/svg/token/criptoactive_icon.svg");
    }
  },

  async mounted() {
    const userId = this.$auth.user().id;
    await this.getTransactions();
    await this.getToken();
    await this.getUserContact({ page: 1, itemsPerPage: 30 });
    const owner = await this.getAuOwnerByTokenId({ tokenId: this.id, userId: userId });
    await this.getSpot({ userId: userId, tokenId: this.id });
    this.cryptoBalance = owner[0].amount;
    this.spotBalance = _.get(this.spot, "[0].amount", "0");
    this.balance = BigNumber(this.cryptoBalance).plus(this.spotBalance).toString(10);
  },

  methods: {
    ...mapActions({
      getTransactionsPageOwnerFrom: "getTransactionsPage",
      getUserContact: "marketplace/getUserContact",
      getAuOwnerByTokenId: "getAuOwnerByTokenId",
      getSpot: "getSpot"
    }),

    switchView() {
      this.isDetailView = !this.isDetailView;
    },

    getFormatedValue(value) {
      return value.toLocaleString("ru", { style: "currency", currency: "RUB" }).replace(",00", "");
    },

    format(date) {
      const day1 = date[0].getDate();
      const month1 = date[0].getMonth() + 1;
      const year1 = date[0].getFullYear();

      const day2 = date[1].getDate();
      const month2 = date[1].getMonth() + 1;
      const year2 = date[1].getFullYear();

      if (year1 == year2 && month1 == month2 && day1 == day2) {
        return `${moment(date[0]).locale("ru").format("L")}`;
      }

      if (year1 == year2 && month1 == month2 && day1 == 1 && (day2 == new Date(year2, month2, 0).getDate() || day2 == new Date().getDate())) {
        let month = moment(date[0]).locale("ru").format("MMMM");
        return month[0].toUpperCase() + month.slice(1) + " " + year1;
      }

      if (year1 == year2 && month1 == 1 && day1 == 1 && month2 == 12 && day2 == 31) {
        return year1;
      }

      return `${moment(date[0]).locale("ru").format("L")} - ${moment(date[1]).locale("ru").format("L")}`;
    },

    async getToken() {
      let token = await this.axios.get(`/api/au_tokens/${this.$route.params.id}`);
      this.correctToken = token.data;
    },

    async getTransactions() {
      let resp = await this.getTransactionsPageOwnerFrom({
        pageId: this.page,
        auTokenId: this.id,
        adr: this.ourEth
      });
      let data = resp.data;
      this.totalItems = resp.totalItems;
      this.showGetMore += data.length;

      data.forEach(transaction => {
        const time = moment(transaction.createdAt).format("DD.MM.YYYY");
        let records = this.transactions.find(el => el.date == time)?.transactions;
        if (records === undefined) {
          this.transactions.push({ "date": time, "sort": transaction.createdAt, "transactions": [] });
          records = this.transactions.at(-1).transactions;
        }
        this.arrEth.forEach((eth) => {
          if ((eth.value).toLowerCase() === (transaction.addressFrom).toLowerCase()) {
            this.addressEth = transaction.addressTo;
          }
          if ((eth.value).toLowerCase() === (transaction.addressTo).toLowerCase()) {
            this.addressEth = transaction.addressFrom;
          }
          if (transaction.addressFrom == 0) {
            this.addressEth = transaction.addressTo;
          }
        });
        let type = _.find(this.contacts, contact => contact.address == transaction.addressTo);
        type = _.get(type, "name", transaction.addressFrom == 0 ? "Эмиссия" : "Перевод");
        records.push({
          icon: transaction.auToken.logoMain,
          name: this.addressEth.slice(0, 4) + "..." + this.addressEth.slice(-4),
          link: process.env.VUE_APP_SCAN_BASE_URL + "address/" + transaction.addressTo,
          type: type,
          addressFrom: transaction.addressFrom,
          addressTo: transaction.addressTo,
          addressEth: this.addressEth,
          amount: transaction.amount,
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill: this.ourEth == transaction?.addressTo,
          decimals: transaction.auToken.decimals,
          mnemonic: transaction.auToken.mnemonicEng,
          createdAt: transaction.createdAt,
          hash: transaction.hash
        });
      });
    },

    async getMore() {
      this.page++;
      await this.getTransactions();
    },

    goToScanner() {
      window.location.href = `${this.scanURL}token/${this.correctToken.createdAddress}`;
    },

    copyToClipboard() {
      navigator.clipboard.writeText(this.correctToken.createdAddress)
        .then(() => {
          this.$toast.success(this.$t("tokeninfo.copySuccess"), {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        })
        .catch(() => {
          this.$toast.error(this.$t("tokeninfo.copyError"), {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 100%;
  padding: 12px 22px 22px 20px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 40px;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 25px;

    &-date {
      font-size: 14px;
    }

    &-line {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-70);
      margin-bottom: 20px;
      padding-bottom: 10px;
    }

    &-month {
      color: #279063
    }
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: -15px;
  }
}

.period {
  margin: 15px 0px;
  font-size: 11px;
}

.transactions-header {
  border-bottom: 1px solid var(--color-70);
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &-group {
    margin-top: 12px;
  }

  &-footer {
    margin-top: 40px;
    padding-top: 20px;
    display: flex;
    justify-content: center;

    border-top: 1px dashed var(--color-70);
  }

  &-info {
    display: flex;
    border-bottom: 1px solid var(--color-70);

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }

    &__chart {
      display: flex;
      align-items: center;

      @media screen and (max-width: 576px) {
        justify-content: space-between;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-right: -15px;
    }

    &__cards {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      padding: 25px 0px;
    }
  }
}

.btn_metamask {
  display: flex;
  justify-content: center;
  align-items: center;
}
.metamask {
  background: url(../assets/img/metamask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  margin-right: 10px;
}
.count-text{
  font-size: 12px;
  color: var(--color-60);
}

.logo {
  height: 26px;
}

.logo-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.token-header {
  margin-bottom: 25px;
}

.balance {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__value {
    width: 600px;
    font-size: 24px;
  }

  &__string {
    font-size: 12px;
    color: var(--color-50)
  }
}

.token-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 655px) {
    flex-direction: column;
    gap: 10px;
  }

  &__block {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 344px) {
    flex-direction: column;
  }
  }

  &__panel {
    display: flex;
    padding: 30px 0px;
    flex-direction: column;
    gap: 30px;
    border-bottom: 1px dashed var(--color-70);
  }

  &__address {
    display: flex;
    gap: 10px;
    align-items: center;

    font-size: 14px;
    color: var(--color-30);
    text-decoration: underline;

    cursor: pointer;
  }
}

.filter-panel {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px 40px 0px;
}

.action-button{
  cursor: pointer;
}
</style>
