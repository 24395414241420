<template>
  <div class="depth">
    <div class="depth__header">
      <div class="w-50">
        Цена {{ mode == MODES.SALE ? tokenTo?.mnemonicEng : tokenFrom?.mnemonicEng }}
      </div>

      <div class="w-25">
        Объём {{ mode == MODES.SALE ? tokenFrom?.mnemonicEng : tokenTo?.mnemonicEng }}
      </div>

      <div class="w-25">
        Время
      </div>
    </div>

    <div class="depth__body">
      <div
        v-for="(item, index) in orders"
        :key="index"
        class="depth__body-item"
        @click="$emit('select', item.price, countAsksTotal(index))"
      >
        <div class="w-50">
          {{ item.price }}
        </div>

        <div class="w-25">
          {{ item.value }}
        </div>

        <div class="w-25">
          {{ item.createdAt }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import { MODES } from "./marketType";

import moment from "moment";

export default {
  emits: ["change"],

  data() {
    return {
      MODES: MODES
    };
  },

  computed: {
    ...mapGetters({
      asks: "tradePro/asks",
      bids: "tradePro/bids",
      tokenIdFrom: "tradePro/tokenIdFrom",
      tokenIdTo: "tradePro/tokenIdTo",
      tokens: "tokens",
      trades: "trades",
      pairs: "pairs"
    }),

    tokenFrom() {
      return _.find(this.tokens, { id: this.tokenIdFrom });
    },

    tokenTo() {
      return _.find(this.tokens, { id: this.tokenIdTo });
    },

    currentPair() {
      return _.find(this.pairs, pair => pair.token.id == this.tokenIdFrom && pair.tokenQuote.id == this.tokenIdTo);
    },

    orders() {
      return _.chain(this.trades).take(15).map(trade => ({
        price: trade.price,
        value: trade.value,
        createdAt: moment(trade.createdAt).lang("ru").format("LTS")
      }));
    }
  },

  watch: {
    currentPair: {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.getTrades({ pairId: value.id });
        }
      }
    }
  },

  methods: {
    ...mapActions({
      getTrades: "getTrades"
    })
  }
};
</script>

  <style lang="scss" scoped>
  .depth {
    &__header {
      height: 16px;
      width: 100%;
      margin: 10px;

      display: flex;

      color: var(--color-50);
    }

    &__body {
      &-item {
        padding-left: 10px;
        position: relative;
        cursor: pointer;
        display: flex;

        &:hover {
          background: var(--color-80);
        }
      }

      &-deal {
        font-size: 18px;
        text-align: center;
        color: red;
      }
    }
  }
  </style>