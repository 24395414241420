<template>
  <div class="block-trade">
    <div class="block-trade__header">
      <div class="block-trade__header-left">
        <span class="block-trade__title">
          BlockTrade
        </span>

        <AuButton
          center
          bordered
          border-radius="58"
          width="200px"
          height="44px"
        >
          Автоматическая покупка
        </AuButton>
      </div>

      <div class="block-trade__header-right">
        <div class="block-trade__page">
          <div class="block-trade__page-header">
            Лист 1
          </div>

          <AuButton
            center
            bordered
            border-radius="58"
            width="100px"
            height="42px"
            icon="chevron_left_big"
          />

          <div>
            0.0786 – 0.0794
          </div>
        </div>

        <div class="block-trade__page">
          <div
            disabled
            class="block-trade__page-header"
          >
            Лист 2
          </div>

          <AuButton
            center
            bordered
            disabled
            border-radius="58"
            width="100px"
            height="42px"
            icon="chevron_right_big"
          />

          <div>
            0.0795 – 0.0824
          </div>
        </div>
      </div>
    </div>

    <div class="block-trade__blocks">
      <TradeBlock
        v-for="(block, index) in blockInfo.cols"
        :key="index"
        :block="block"
        :sell-limit="blockInfo.sellLimit"
        :buy-limit="blockInfo.buyLimit"
      />
    </div>

    <TradeHistory />
    <OrderHistory />
  </div>
</template>

<script>
import TradeBlock from "@/components/blockTrade/TradeBlock.vue";

import OrderHistory from "@/appPages/OrderHistory.vue";
import TradeHistory from "@/appPages/TradeHistory.vue";

export default {
  components: {
    TradeBlock,
    TradeHistory,
    OrderHistory
  },

  data() {
    return {
      blockInfo:
      {
        sellLimit: 1002,
        buyLimit: 100000,
        cols: [
          {
            sell: { price: 0.0786, value: 1002 },
            buy: { price: 7.52, value: 100000 }
          },
          {
            sell: { price: 0.0786, value: 100 },
            buy: { price: 7.52, value: 1000 }
          },
          {
            sell: { price: 0.0786, value: 400 },
            buy: { price: 7.52, value: 15500 }
          },
          {
            sell: { price: 0.0786, value: 300 },
            buy: { price: 7.52, value: 12500 }
          },
          {
            sell: { price: 0.0786, value: 1000 },
            buy: { price: 7.52, value: 45500 }
          },
          {
            sell: { price: 0.0786, value: 1000 },
            buy: { price: 7.52, value: 45500 }
          },
          {
            sell: { price: 0.0786, value: 1000 },
            buy: { price: 7.52, value: 45500 }
          },
          {
            sell: { price: 0.0786, value: 0 },
            buy: { price: 7.52, value: 100000 }
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.block-trade {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 14px;

    &-header[disabled] {
      color: var(--color-60)
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &-left {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    &-right {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }

  &__title {
    font-size: 24px;
  }

  &__blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 20px;

    @media screen and (max-width: 1024px) {
      column-gap: 15px;
      row-gap: 25px;
    }
  }
}
</style>