<template>
  <div>
    <div class="block-place">
      <div class="left-block">
        <span>
          SoluExchange SWAP
        </span>
      </div>
      <div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between">
            <div
              class="swap-tab"
              :class="{'checked_tab': isMarket}"
              @click="isMarket=true"
            >
              <span class="history-text">Рынок</span>
            </div>
            <div
              class="swap-tab"
              :class="{'checked_tab': !isMarket}"
              @click="isMarket=false"
            >
              <span class="history-text">Лимит</span>
            </div>
          </div>
          <div class="d-flex">
            <div
              class="mode_switcher me-2"
              :class="{'checked_switch': isBuy}"
              @click="isBuy=true"
            >
              <span class="history-text">Buy</span>
            </div>
            <div
              class="mode_switcher"
              :class="{'checked_switch': !isBuy}"
              @click="isBuy=false"
            >
              <span class="history-text">Sell</span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div
            :class="{ 'spot_checked': isCryptoToken }"
            class="spot d-flex align-items-center"
            @click="isCryptoToken=true"
          >
            <div class="metamask" />
            <AuDecimalStatic
              v-if="tokenCrypto !== null"
              :value="tokenCrypto"
              :decimal="tokenOwner?.auToken?.decimals"
              :indent="tokenOwner?.auToken?.indent"
            />
            <span v-else>Крипто</span>
          </div>
          <div
            :class="{ 'spot_checked': !isCryptoToken }"
            class="spot d-flex align-items-center"
            @click="isCryptoToken=false"
          >
            <div class="spot_icon">
              <AuIcon icon="wallet" />
            </div>
            <AuDecimalStatic
              v-if="tokenSpot !== null"
              :value="tokenSpot"
              :decimal="tokenOwner?.auToken?.decimals"
              :indent="tokenOwner?.auToken?.indent"
            />
            <span v-else>Спотовый</span>
          </div>
        </div>
        <SwapInput
          v-model="swapFrom"
          header="From"
          :options="options"
          :show-price="!isMarket"
          :token-quote="swapTo.swapToken"
        />
        <div class="d-flex justify-content-center">
          <div
            class="circle"
            @click="changeObject"
          >
            <AuIcon
              icon="swap"
              @click="changeObject"
            />
          </div>
        </div>
        <div class="d-flex justify-content-center course-text">
          Курс - {{ course }}
        </div>
        <div class="d-flex justify-content-between">
          <div
            :class="{ 'spot_checked': isCryptoTokenQuote }"
            class="spot d-flex align-items-center"
            @click="isCryptoTokenQuote=true"
          >
            <div class="metamask" />
            <AuDecimalStatic
              v-if="tokenQuoteCrypto !== null"
              :value="tokenQuoteCrypto"
              :decimal="tokenQuoteOwner?.auToken?.decimals"
              :indent="tokenQuoteOwner?.auToken?.indent"
            />
            <span v-else>Крипто</span>
          </div>
          <div
            :class="{ 'spot_checked': !isCryptoTokenQuote }"
            class="spot d-flex align-items-center"
            @click="isCryptoTokenQuote=false"
          >
            <div class="spot_icon">
              <AuIcon icon="wallet" />
            </div>
            <AuDecimalStatic
              v-if="tokenQuoteSpot !== null"
              :value="tokenQuoteSpot"
              :decimal="tokenQuoteOwner?.auToken?.decimals"
              :indent="tokenQuoteOwner?.auToken?.indent"
            />
            <span v-else>Спотовый</span>
          </div>
        </div>
        <SwapInput
          v-model="swapTo"
          faded
          label="Примерно:"
          header="To"
          :options="options"
        />
        <div class="btn-indent">
          <AuButton
            bordered
            border-radius="6"
            width="400px"
            center
            active-bg-color="#279063"
            :disabled="isZeroPrice"
            @click="showAdrDialog"
          >
            Обменять
          </AuButton>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div
          class="right-block mb-4"
          @click="goToTradeHistory"
        >
          <span class="history-text">
            История конвертаций
          </span>
          <AuIcon icon="arrow-right" />
        </div>
        <div
          class="right-block mb-4"
          @click="goToOrderHistory"
        >
          <span class="history-text">
            Мои ордера
          </span>
          <AuIcon icon="arrow-right" />
        </div>
        <div
          class="right-block"
          @click="goToMarketState"
        >
          <span class="history-text">
            Состояние рынка
          </span>
          <AuIcon icon="arrow-right" />
        </div>
      </div>
    </div>
    <div class="question">
      <div class="question_header">
        Часто задаваемые вопросы
      </div>
      <div
        v-for="item in questions"
        :key="item.question"
        class="quest"
      >
        <div
          class="d-flex justify-content-between question-indent"
          @click="showHide(item)"
        >
          <span class="history-text">{{ item.question }}</span>
          <div>
            <AuIcon
              v-if="item.show"
              icon="minus"
            />
            <AuIcon
              v-else
              icon="plus"
            />
          </div>
        </div>
        <div
          v-if="item.show"
          class="history-text answear"
        >
          {{ item.answear }}
        </div>
      </div>
    </div>
    <AuModal
      v-model="adrDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          Выберете адрес
        </span>
      </template>
      <template #body>
        <span>
          Выберете адрес для завершения операции
        </span>
        <AuSelect
          :value="ethAdr"
          :options="ethOptions"
          label="value"
          @input="v => ethAdr = v"
        />
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="checkSwap"
            >
              SWAP
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="adrDialog=false"
            >
              Отмена
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment/moment";
import SwapInput from "@/components/SwapInput";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "OldSwap",
  components: { SwapInput },

  data() {
    return {
      adrDialog: false,
      ethAdr: null,
      isMarket: true,
      isBuy: true,
      isCryptoToken: true,
      isCryptoTokenQuote: true,
      course: null,
      swapFrom: {
        swapValue: "",
        swapToken: null,
        swapPrice: "",
        isActive: null
      },
      swapTo: {
        swapValue: "",
        swapToken: null,
        swapPrice: "",
        isActive: null
      },
      questions: [
        {
          question: "1. Устанавливаются ли какие-либо торговые комиссии? ",
          answear: "При использовании SoluExchange SWAP комиссии не взимаются. " +
              "В режиме «Рынок» котировки обновляются в режиме реального времени в зависимости от текущих рыночных условий. " +
              "Указанная сумма активов — это фактическая сумма активов, которую вы получите на свой счет при совершении сделки. " +
              "В режиме «Лимит», когда ваш ордер исполняется по указанной лимитной цене, актив будет конвертирован по этой цене.",
          show: true
        },
        {
          question: "2. Каковы преимущества использования SoluExchange SWAP и как начать работу с платформой?",
          answear: "-",
          show: false
        },
        {
          question: "3. Каковы условия использования портала?",
          answear: "-",
          show: false
        },
        {
          question: "4. Каковы минимальные и максимальные суммы сделок?",
          answear: "-",
          show: false
        },
        {
          question: "5. Как осуществляется расчет?",
          answear: "-",
          show: false
        },
        {
          question: "6. Как вносить и выводить средства?",
          answear: "-",
          show: false
        },
        {
          question: "7. Что делать, если возникла ошибка «Недостаточно средств»?",
          answear: "-",
          show: false
        },
        {
          question: "8. В чем разница между режимом «Рынок» и режимом «Стоп-лимит»?",
          answear: "-",
          show: false
        },
        {
          question: "9. Почему цена котировки отличается от цены последней сделки на бирже?",
          answear: "-",
          show: false
        },
        {
          question: "10. Где я могу посмотреть свою историю сделок?",
          answear: "-",
          show: false
        },
        {
          question: "11. Могу ли я обменять «старые» версии токенов, которые подверглись миграции, на SoluExchange SWAP?",
          answear: "-",
          show: false
        },
        {
          question: "12. Включаются ли сделки по конвертации в объем спотовых сделок для расчета VIP-программы?",
          answear: "-",
          show: false
        },
        {
          question: "13. Сколько токенов и торговых пар поддерживает SoluExchange SWAP?",
          answear: "-",
          show: false
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      ethAddresses: "ethAddresses",
      auOwners: "auOwners",
      exchangeBalances: "exchangeBalances"
    }),

    options() {
      return [...this.tokensFiat, ...this.tokensGold].map(token => {
        return {
          label: token.mnemonicEng,
          value: token.nameEng,
          image: token.logoMain
        };
      });
    },

    tokenOwner() {
      return this.auOwners.find(owner => owner.auToken.nameEng == this.swapFrom.swapToken);
    },

    tokenQuoteOwner() {
      return this.auOwners.find(owner => owner.auToken.nameEng == this.swapTo.swapToken);
    },

    tokenCrypto() {
      return _.get(this.tokenOwner, "amountFree", null);
    },

    tokenSpot() {
      return _.get(
        _.find(this.exchangeBalances, item => item.token == `/api/au_tokens/${this.tokenOwner?.id}`),
        "amount",
        null);
    },

    tokenQuoteSpot() {
      return _.get(
        _.find(this.exchangeBalances, item => item.token == `/api/au_tokens/${this.tokenQuoteOwner?.id}`),
        "amount",
        null);
    },

    tokenQuoteCrypto() {
      return _.get(this.tokenQuoteOwner, "amountFree", null);
    },

    ethOptions() {
      return this.ethAddresses.map(adr => {
        return {
          value: adr.value
        };
      });
    },

    isZeroPrice() {
      return !this.isMarket && this.swapFrom.swapPrice == 0;
    }
  },

  watch: {
    async swapFrom(swapNew, swapOld) {
      if (swapNew.swapToken !== swapOld.swapToken) {
        this.course = await this.getRate();
      }
      const buf = BigNumber(this.course);
      this.swapTo.swapValue = buf.multipliedBy(this.swapFrom.swapValue).toString(10);
    },

    async swapTo(swapNew, swapOld) {
      if (swapNew.swapToken !== swapOld.swapToken) {
        this.course = await this.getRate();
        const buf = BigNumber(this.course);
        this.swapTo.swapValue = buf.multipliedBy(this.swapFrom.swapValue).toString(10);
      }
      if (this.swapTo.isActive) {
        const buf = BigNumber(this.swapTo.swapValue);
        this.swapFrom.swapValue = buf.dividedBy(this.course).toString(10);
      }
    }
  },

  methods: {
    ...mapActions({
      sendTokenEvents: "sendTokenEvents",
      getCourse: "getCourse"
    }),

    showHide(q) {
      q.show = !q.show;
    },

    changeObject() {
      [this.swapTo, this.swapFrom] = [this.swapFrom, this.swapTo];
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    getIriByName(name) {
      return `/api/au_tokens/${[...this.tokensFiat, ...this.tokensGold].find(item => item.nameEng == name).id}`;
    },

    checkSwap() {
      this.adrDialog = false;
      if (this.swapTo.swapToken === null || this.swapFrom.swapToken === null) {
        this.showError("Выберите токены для совершения сделки");
        return;
      }
      if (this.swapFrom.swapValue == 0) {
        this.showError("Суммы токенов не должны быть нулевыми");
        return;
      }
      if (this.ethAdr === null) {
        this.showError("Выберите корректный адрес");
        return;
      }
      this.doSwap();
    },

    async doSwap() {
      const params = {
        token: this.getIriByName(this.swapFrom.swapToken),
        tokenQuote: this.getIriByName(this.swapTo.swapToken),
        user: `/api/users/${this.$auth.user().id}`,
        createdAt: moment().format("DD.MM.YYYY hh:mm:ss"),
        type: this.isMarket ? "token_order_market_create" : "token_order_limit_create",
        description: this.isBuy ? "buy" : "sale",
        mode: this.isBuy ? "buy" : "sale",
        value: this.swapFrom.swapValue,
        priceString: this.swapFrom.swapPrice,
        address: this.isCryptoToken ? this.ethAdr : null,
        isTask: true
      };
      try {
        await this.sendTokenEvents(params);
        this.showSuccess("Ордер успешно создан");
      }
      catch (e) {
        this.showError("Ошибка при создании ордера");
      }
    },

    goToOrderHistory() {
      this.$router.push({ name: "order history" });
    },

    goToTradeHistory() {
      this.$router.push({ name: "trade history" });
    },

    goToMarketState() {
      this.$router.push({ name: "state of market" });
    },

    async getRate() {
      const tokenId = [...this.tokensFiat, ...this.tokensGold].find(token => token.nameEng === this.swapFrom?.swapToken)?.id;
      const tokenQuoteId = [...this.tokensFiat, ...this.tokensGold].find(token => token.nameEng === this.swapTo?.swapToken)?.id;
      if (_.isNil(tokenId) || _.isNil(tokenQuoteId)) {
        return 0;
      }
      return await this.getCourse({ "tokenId": tokenId, "tokenQuoteId": tokenQuoteId });
    },

    showAdrDialog() {
      if (this.isCryptoToken) {
        if (this.ethOptions.length === 1) {
          this.ethAdr = _.get(this.ethOptions, "[0].value", null);
          this.doSwap();
        }
        else {
          this.adrDialog = true;
        }
      }
      else {
        this.doSwap();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.block-place{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.left-block{
  font-weight: 600;
  font-size: 22px;
}
.right-block{
  padding: 10px 15px;
  background: var(--menu-color);
  border-radius: 10px;
  cursor: pointer;
}
.history-text{
  font-weight: 400;
  font-size: 14px;
}
.swap-tab{
  display: flex;
  padding: 15px 25px;
  border-radius: 9px;
  color: var(--color-60);
  background: var(--color-90);
  margin-right: 10px;
  cursor: pointer;
}
.checked_tab{
  color: var(--text-color);
  background: var(--menu-color);
}
.spot{
  width: 45%;
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 13px;
  color: var(--color-60);
  background: var(--color-90);
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
}
.spot_icon{
  margin-right: 5px;
}
.spot_checked{
  color: var(--text-color);
  background: var(--menu-color);
}
.circle{
  border-radius: 50%;
  padding: 10px 15px;
  background: var(--menu-color);
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
}
.btn-indent{
  margin-top: 25px;
}
.question{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 140px;
}
.quest{
  border-bottom: 1px solid var(--color-70);
}
.question_header{
  font-weight: 500;
  font-size: 20px;
  color: var(--color-60);
  padding-bottom: 40px;
  border-bottom: 1px solid var(--color-70);
}
.answear{
  color: var(--color-60);
  width: 80%;
  margin-bottom: 30px;
}
.question-indent {
  margin-top: 25px;
  margin-bottom: 30px;
  cursor: pointer;
}
.modal_head_text {
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.mode_switcher{
  font-weight: 400;
  font-size: 13px;
  color: var(--color-60);
  background: var(--color-90);
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
}
.checked_switch{
  color: var(--text-color);
  background: var(--menu-color)
}
.metamask {
  background: url(../assets/img/metamask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  margin-right: 5px;
}
.course-text{
  color: var(--color-80);
  font-size: 11px;
}
</style>
