import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  globalInjection: true,
  messages: {
    ru: {
      router: {
        wallet: "Wallet",
        walletSubTite: "Мой портфель",
        exchange: "Обменять",
        exchangeSubTite: "Обмен активов",
        blockDex: "Торговать",
        blockDexSubTite: "Простая торговля",
        pages: {
          dashboard: "Dashboard",
          requisite: "Мои счета",
          goods: "Токены (заявки)",
          projects: "Мои проекты",
          projectsEdit: "Заявка на эмиссию",
          companies: "Мои компании",
          companyManager: "Управление компаниями",
          daoTokens: "SoluExchange DAO",
          briefcase: "Мой портфель",
          sendToken: "Отправка актива",
          transactions: "Транзакции",
          accounts: "Мои счета",
          dividends: "Дивиденты",
          dividendsHistory: "История выплат",
          counterparties: "Контрагенты",
          editCounterparty: "Заявка на эмиссию",
          reports: "Отчёты",
          investProjects: "Проекты",
          investProjectsApplication: "Заявка",
          account: "Аккаунт",
          news: "Новости",
          editNews: "Новость",
          user: "Профиль",
          referralProgram: "Реферальная программа",
          paymentMethod: "Способ оплаты",
          security: "Безопасность",
          settings: "Настройки",
          support: "Поддержка",
          editGood: "Заявка на токенизацию товара",
          assetsEmitent: "Мои токены",
          assetsInvest: "Криптоактивы",
          autSales: "Препродажа AUt (NEW)",
          pays: "Данные о платеже",
          daoRequests: "DAO голосования",
          daoEvents: "Результаты голосования",
          documentation: "Отчетность"
        }
      },
      exhangeRateBottom: {
        cryptoBalance: "Крипто",
        spotBalance: "Спот"
      },
      referralProgram: {
        userRefLabel: "Реферальная ссылка",
        refSourceLabel:
          "Вы можете дополнительно ввести источник данных для статистики:",
        refSourcePlaceholder: "Введите источник данных"
      },
      settings: {
        language: {
          label: "Язык",
          ru: "Русский язык",
          en: "Английский язык",
          uz: "Узбекский язык"
        },
        defaultToken: {
          label: "Валюта по умолчанию"
        },
        welcomeLanguage: {
          ru: "Русский",
          en: "Английский",
          uz: "Узбекский"
        }
      },
      validations: {
        "requiredPassword": "Поле Пароль является обязательным",
        "requiredEmail": "Поле Email является обязательным",
        "email": "Введённый адрес не является корректным",
        "minLength": "Минимальная длина пароля - {min} символов.",
        "containsUppercase": "Пароль должен содержать как минимум 1 заглавную букву",
        "containsLowercase": "Пароль должен содержать как минимум 1 строчную букву",
        "containsNumber": "Пароль должен содержать как минимум 1 цифру"
      },
      login: {
        fName: "Имя *",
        sName: "Фамилия *",
        mName: "Отчество",
        enter: "Вход",
        register: "Регистрация",
        password: "Пароль *",
        passwordRepeat: "Повторите пароль *",
        passwordWeak: "Пароль слабый",
        passwordDontMatch: "Пароли не совпадают",
        passwordStrong: "Минимум: 8 символов, 1 заглавный символ, 1 цифра",
        passwordNotRemember: "Не помню",
        requiredFields: "Заполните обязательные поля",
        googleCode: "Введите код из Google",
        incognito: "Используйте режим инкогнито на чужом компьютере"
      },
      paymentMethod: {
        addCard: "Добавить карту",
        noCards: "Карты не добавлены",
        table: {
          name: "Название",
          holder: "Держатель",
          number: "Номер",
          expiresAt: "Дата окончания",
          actions: "Действия"
        },
        modal: {
          save: "Сохранить",
          cancel: "Отмена",
          edit: "Редактировать",
          delete: "Удалить",
          yes: "Да",
          no: "Нет",
          editHeader: "Редактировать карту",
          addHeader: "Добавить карту",
          deleteHeader: "Удалить карту",
          deleteMessage: "Вы уверены что хотите удалить карту?"
        },
        toast: {
          editSuccess: "Карта успешно отредактирована",
          editError: "Ошибка редактирования карты",
          addSuccess: "Карта успешно добавлена",
          addError: "Ошибка добавления карты",
          deleteSuccess: "Карта успешно удалена",
          deleteError: "Ошибка удаления карты"
        }
      },
      ethAddresses: {
        titleAddress: "Мои адреса",
        title2faActive: "(активно)",
        title2faNoActive: "(не активно)",
        titlePassword: "Сменить пароль",
        addAddressTitle: "Добавить адрес",
        ethAddress: "ETH адрес",
        ethAddressError: "Адрес неправильный или поле пустое",
        pasteFromWalletButton: "Вставить из криптокошелька",
        addEthButton: "Добавить в мои адреса",
        myAddressesTitle: "Мои адреса",
        validateButton: "Верифицировать",
        validatedButton: "Верифицирован",
        addToRegistryButton: "Добавление в реестр",
        addedToRegistryButton: "Добавлен в реестр",
        modal: {
          title: "Удаление адреса",
          text: "Вы точно хотите удалить ETH:",
          removeEthButton: "Удалить",
          resetButton: "Отмена"

        }
      },
      twoFA: {
        title2faActive: "(активно)",
        title2faNoActive: "(не активно)",
        titlePassword: "Сменить пароль",
        descriptionOne: "Скачате приложение Google Authenticator. Отсканируйте код на сотовом. Убедитесь, что код с сайта SoluExchange и нажмите \"Активировать\"",
        descriptionTwo: "Для отключание 2FA введите код из Google Authenticator",
        on: "Включить 2FA",
        off: "Выключить 2FA",
        enterGoogleCode: "Введите Google Code *"
      },
      changePassword: {
        titlePassword: "Сменить пароль",
        requiredFields: "Заполните обязательные поля",
        passwordDontMatch: "Пароли не совпадают",
        passwordDifficult: "Усложните пароль",
        passwordRemember: "Запомните ваш пароль!",
        passwordNew: "Придумайте новый пароль.",
        passwordStrong: "8 символов, 1 заглавный символ, 1 цифра",
        passwordValid: "Действующий пароль *",
        passwordNewPass: "Новый пароль *",
        passwordRepeat: "Повторите пароль *",
        passwordChange: "Сменить пароль"
      },
      welcome: {
        nextButton: "Далее",
        backButton: "Назад",
        error: "Не все обязательные поля заполнены!",
        firstStep: {
          stepName: "Приветствие",
          label: "Добро пожаловать в ",
          text: {
            firstParagraph: "Благодарим Вас за выбор нашей платформы",
            secondParagraph:
              "SoluExchange – это глобальная финансовая блокчейн экосистема функционирующая на SoluChain с применением кастомизированных смарт-контрактов.",
            thirdParagraph:
              "Мы объединяем традиционные финансы и блокчейн технологии, позволяя любому человеку или компании привлекать инвестиции и инвестировать в цифровые активы а также управлять своим инвестиционным портфелем.",
            fourthParagraph:
              "Предлагаем Вам ознакомиться с описанием функциональных характеристик которые доступны в SoluExchange:",
            fifthParagraph:
              "Мы уверены что наше взаимное сотрудничество будет продуктивным, так как мы создали продукт который способствует ведению бизнеса по всему миру ускоряя все бизнес-процессы."
          },
          list: {
            firstLine: {
              highlight: "Инвестируйте",
              text: " в компании со всего мира и получайте дополнительный доход."
            },
            secondLine: {
              highlight: "Привлекайте",
              text: " инвестиции для собственных проектов и расширения бизнеса."
            },
            thirdLine: {
              highlight: "Покупайте и продавайте",
              text: " оцифрованные товары и услуги."
            },
            fourthLine: {
              highlight: "Проводите",
              text: " финансовые операции без ограничений."
            }
          }
        },
        secondStep: {
          stepName: "Выбор роли",
          label: "Пожалуйста, выберите ваш статус",
          text: "Мы будем использовать эту информацию для настройки вашей учетной записи SoluExchange.",
          subTitle: "Выберите ваш статус",
          investorRole: {
            label: "Инвестор",
            text: "Мы будем использовать эту информацию для настройки вашей учетной записи SoluExchange."
          },
          issuerRole: {
            label: "Эмитент",
            text: "Мы будем использовать эту информацию для настройки вашей учетной записи SoluExchange."
          }
        },
        thirdStep: {
          stepName: "Персональные данные",
          label: {
            investorRole: "Вы выбрали статус «Инвестор»",
            issuerRole: "Вы выбрали статус «Эмитент»",
            noRole: "Пожалуйста, выберите роль"
          },
          text: "Пожалуйста, расскажите нам больше о себе, заполнив поля ниже.",
          subTitle: "Ваши данные",
          form: {
            bankAccountTypeLabel: "Тип счета",
            bankAccountTypePlaceholder: "Выберите тип счета",
            bankAccountTypeEntity: "Юридическое лицо",
            bankAccountTypeIndividual: "Физическое лицо",
            nameRusLabel: "Наименование компании",
            nameRusPlaceholder: "Введите наименование компании",
            typeOfActivityLabel: "Вид деятельности",
            typeOfActivityPlaceholder: "Введите ваш вид деятельности",
            rusInnLabel: "ИНН",
            rusInnPlaceholder: "ИНН",
            rusBikLabel: "БИК",
            rusBikPlaceholder: "БИК",
            bankLabel: "Наименование банка",
            bankPlaceholder: "Введите наименование банка",
            lastNameLabelIndividual: "Данные о вас",
            lastNameLabelEntity: "Лицо принимающее решение",
            lastNamePlaceholder: "Фамилия",
            firstNamePlaceholder: "Имя",
            patronymicPlaceholder: "Отчество",
            postPlaceholder: "Должность",
            countryLabel: "Страна",
            countryPlaceholder: "Выберите вашу страну",
            cityLabel: "Город",
            cityPlaceholder: "Введите ваш город",
            addressRusLabel: "Адрес",
            addressRusPlaceholder: "Введите вашу улицу, номер дома",
            passportFileLabel: "Паспорт",
            charterFileLabel: "Устав",
            lprFileLabel: "Паспорт ЛПР",
            innFileLabel: "ИНН",
            ogrnFileLabel: "ОГРН",
            egrulFileLabel: "ЕГРЮЛ",
            registrationFileLabel: "С-во о регистрации",
            ogrnEgrulFileLabel: "ОГРН / ЕГРЮЛ",
            cardFileLabel: "Карточка предприятия",
            protocolFileLabel: "Протокол / решение",
            tosAgree: {
              text: "Я подтверждаю, что прочитал и согласен с ",
              highlightToS: "Условиями обслуживания",
              highlightPP: "Политикой конфиденциальности.",
              and: " и "
            },
            newsLettersAgree:
              "Я хочу получать рекламные электронные письма о новых инвестиционных возможностях и обновлениях нашей платформы (вы можете отказаться от подписки в любое время в настройках конфиденциальности)."
          }
        },
        finalStep: {
          stepName: "Завершение",
          label: {
            noRole: "Пожалуйста, выберите роль и заполните необходимые данные",
            noData: "Пожалуйста, заполните необходимые данные",
            successfulSending: "Успешная регистрация!"
          },
          text: "Спасибо за регистрацию, ожидайте подтверждение на ваш e-mail, совсем скоро мы с вами свяжемся"
        }
      },
      profile: {
        connectWallet: "Подключить кошелек",
        connectWithWallet: "Соединиться с кошельком"
      },
      profileMenu: {
        profile: "Профиль",
        referralProgram: "Реферальная программа",
        paymentMethod: "Способ оплаты",
        security: "Безопасность",
        settings: "Настройки",
        support: "Поддержка",
        logout: "Выйти"
      },
      dashboard: {
        rates: {
          buttons: {
            all: "Все",
            favorites: "Избранное"
          },
          products: {
            name: "Товары"
          },
          cryptoassets: {
            name: "Криптоактивы"
          },
          goodsNamesDictionary: {
            name: "Мои товары",
            one: "товар",
            no: "товара",
            many: "товаров"
          },
          projectsNamesDictionary: {
            name: "Мои проекты",
            one: "проект",
            no: "проекта",
            many: "проектов"
          },
          companiesNamesDictionary: {
            name: "Мои компании",
            one: "компания",
            no: "компании",
            many: "компаний"
          },
          assetsNamesDictionary: {
            name: "Криптоактивы",
            one: "эмиссия",
            no: "эмиссии",
            many: "эмиссий"
          }
        },
        tradingLeaders: {
          label: "Лидеры по торгам",
          goTo: "Перейти"
        },
        changeChart: {
          label: "Изменение цены портфеля",
          all: "Все",
          oneMonth: "М",
          fourMonths: "М",
          sixMonths: "М",
          oneYear: "Г",
          datePickerPlaceholder: "Выберите период"
        },
        products: {
          label: "Мои новые товарные заявки",
          goTo: "Перейти"
        },
        transactions: {
          label: "Последние транзакции",
          goTo: "Перейти"
        },
        news: {
          label: "Новости по эмитентам",
          goTo: "Перейти"
        }
      },
      briefcase: {
        fiatTabLabel: "Фиатные токены",
        cryptoTabLabel: "Криптовалюты",
        investTabLabel: "Инвестиционные",
        label: "Мой портфель",
        subtext: "Стоимость",
        firstColumn: "Название",
        secondColumn: "Кол-во токенов",
        thirdColumn: "Цена за токен",
        fourthColumn: "Доходность, % год.",
        fifthColumn: "Итого в AUg",
        sumColumn: "Сумма",
        sendAction: "Отправить",
        changeAction: "Обменять",
        bringOutAction: "Вывести"
      },
      counterparties: {
        label: "Список контрагентов",
        addButton: "Добавить контакт",
        firstColumn: "Номер кошелька",
        secondColumn: "ФИО владельца",
        thirdColumn: "Статус контрагента",
        fourthColumn: "Действие",
        editAction: "Редактировать",
        deleteAction: "Удалить",
        dividendsAction: "Дивиденды",
        daoRequests: "DAO голосование",
        deleteDialog: {
          title: "Удалить контакт",
          subtitle: "Уверены что хотите удалить контакт?",
          agree: "Да",
          cancel: "Отмена"
        },
        form: {
          label: "Новый контакт",
          backButton: "Отменить",
          FIOlabel: "ФИО владельца",
          FIOPlaceholder: "Введите имя контакта",
          addressLabel: "Адрес контакта",
          addressPlaceholder: "Введите адрес контакта",
          statusLabel: "Статус контакта",
          statusPlaceholder: "Введите статус контакта",
          saveBtn: "Сохранить",
          error: "Заполнены не все обязательные поля!"
        }
      },
      transactions: {
        cards: {
          transfersCard: "Переводы",
          investmentsCard: "Инвестиции",
          goodsCard: "Товары",
          exchangeCard: "Биржа"
        },
        transferType: "Перевод",
        emissionType: "Эмиссия",
        unspecified: "Не определено",
        label: "Транзакции",
        allPlaceholder: "Все",
        moreButton: "Загрузить ещё",
        cryptoAssetPlaceholder: "Криптоактив",
        datePickerPlaceholder: "Выберите период",
        confirmАddress:
          "Для просмотра транзакций подтвердите свой адрес через крипто кошелёк",
        goTo: "Перейти",
        transactionType: {
          placeholder: "Тип транзакции",
          all: "Все",
          receipts: "Приход",
          spending: "Расход"
        },
        titleType: {
          all: "Траты и поступления за",
          receipts: "Поступления за",
          spending: "Траты за"
        }
      },
      companies: {
        label: "Мои компании",
        addCompanyButton: "Добавить компанию",
        companiesCount: "Кол-во компаний",
        firstColumn: "Наим. компании",
        secondColumn: "Страна",
        thirdColumn: "Описание",
        fourthColumn: "Статус",
        fifthColumn: "Действие",
        companyVerified: "Подтверждена",
        companyNotVerified: "На рассмотрении",
        companyIsDraft: "Черновик",
        deleteDialog: {
          title: "Удалить компанию",
          subtitle: "Уверены что хотите удалить компанию",
          agree: "Да",
          cancel: "Отмена"
        },
        form: {
          addCompanyLabel: "Добавить компанию",
          editCompanyLabel: "Редактировать компанию",
          cancel: "Отмена",
          nameLabel: "Название",
          nameRusPlaceholder: "Название компании(на русском)",
          nameEngPlaceholder: "Название компании(на английском)",
          descriptionLabel: "Описание",
          descriptionRusPlaceholder: "Сфера деятельности(на русском)",
          descriptionEngPlaceholder: "Сфера деятельности(на английском)",
          addressLabel: "Адрес",
          addressRusPlaceholder: "Адрес (рус)",
          addressEngPlaceholder: "Адрес (анг)",
          companyInfoLabel: "Данные о компании",
          countryPlaceholder: "Страна",
          URLPlaceholder: "URL сайта компании",
          companyReqLabel: "Реквизиты",
          INNPlaceholder: "ИНН",
          OGRNPlaceholder: "ОГРН",
          KPPPlaceholder: "КПП (для РФ)",
          bankPlaceholder: "Банковские реквизиты (для РФ)",
          reqLabel: "Заявки",
          reqPlaceholder:
            "Запрос верификации компании (ред-е станет недоступно)",
          saveAsDraft: "Сохранить как черновик"
        }
      },
      tokeninfo: {
        balance: "Общий баланс",
        cryptoBalance: "Крипто-баланс",
        spotBalance: "Спот-баланс",
        addToWallet: "Добавить в криптокошелек",
        pay: "Оплатить",
        refill: "Пополнить",
        dao: "DAO",
        transfer: "Перевести",
        withdrawal: "Вывод",
        transactions: "Транзакции",
        loadMore: "Загрузить ещё",
        copySuccess: "Скопировано в буфер обмена",
        copyError: "Ошибка при копировании в буфер"
      },
      reports: {
        label: "Заказать отчет",
        tableLabel: "Полученные отчеты",
        pickCompanyText:
          "Выберите компанию и период, за который Вы бы хотели получить отчет",
        companyPlaceholder: "Наименование компании",
        oneMonth: "М",
        fourMonths: "М",
        sixMonths: "М",
        oneYear: "Г",
        orderReport: "Заказать отчет",
        firstColumn: "Компания",
        secondColumn: "Дата формирования",
        thirdColumn: "Период отчета",
        fourthColumn: "Действие"
      },
      daoPage: {
        title: "DAO голосования",
        button: "Добавить",
        firstColumn: "Название",
        secondColumn: "Кол-во токенов",
        thirdColumn: "Начало",
        fourthColumn: "Окончание",
        fifthColumn: "Действие",
        menuEdit: "Редактировать",
        menuDelete: "Удалить",
        menuResult: "Результаты",
        actionAdd: "Добавить",
        actionEdit: "Редактировать",
        actionDelete: "Удалить",
        tabVote: "Голосование",
        tabAnswer: "Варианты ответов",
        modalName: "Название",
        modalDescription: "Описание",
        modalLimit: "Кол-во токенов",
        modalStart: "Дата начала",
        modalEnd: "Дата конца",
        modalAnswer: "Вариант ответа",
        buttonCancel: "Отмена",
        modalIsDelete: "Уверенны что хотите удалить данный элемент?",
        success: {
          add: "Элемент успешно добавлен",
          edit: "Элемент успешно отредактирован",
          delete: "Элемент успешно удален"
        },
        error: {
          add: "Ошибка при добавлении элемента",
          edit: "Ошибка в процессе редактирования",
          delete: "Ошибка в процессе удаления"
        },
        eventTitle: "Результаты голосования",
        eventFirstColumn: "Вариант ответа",
        eventSecondColumn: "Кол-во голосов"
      },
      documentation: {
        label: "Отчетность",
        button: "Загрузить отчетность",
        firstColumn: "Имя",
        secondColumn: "Тип",
        thirdColumn: "Размер",
        fourthColumn: "Создан",
        fifthColumn: "Изменен",
        sixthColumn: "Действие",
        actionDelete: "Удалить",
        modalHeader: "Уверены что хотите удалить файл?",
        modalButtonYes: "Да",
        modalButtonCancel: "Отмена",
        success: {
          upload: "Файл успешно загружен",
          delete: "Файл успешно удален"
        },
        error: {
          upload: "Ошибка при загрузке файла",
          delete: "Ошибка при удалении файла"
        }
      },
      chat: {
        title: "Сообщения",
        placeholder: "Написать сообщение..."
      },
      dividendsHistory: {
        label: "История выплат",
        firstColumn: "Количество токенов",
        secondColumn: "Резервный токен",
        thirdColumn: "Статус",
        fourthColumn: "Создан",
        fifthColumn: "Кошелек"
      },
      news: {
        label: "Мои новости",
        newsCount: "Количество новостей",
        addNewsButton: "Добавить новость",
        firstColumn: "Изображение",
        secondColumn: "Заголовок",
        thirdColumn: "Статус",
        fourthColumn: "Действие",
        deleteDialog: {
          title: "Удалить новость",
          subtitle: "Уверены что хотите удалить новость?",
          agree: "Да",
          cancel: "Отмена"
        },
        form: {
          label: "Загрузка новостей",
          imageLabel: "Изображение",
          titleLabel: "Заголовок",
          titlePlaceholder: "Введите заголовок",
          subtitleLabel: "Подзаголовок",
          subtitlePlaceholder: "Введите подзаголовок",
          contentLabel: "Описание",
          contentPlaceholder: "Введите описание",
          auTokenLabel: "Проект",
          auTokenPlaceholder: "Выберите проект",
          publishNewsButton: "Опубликовать",
          cancelButton: "Отмена",
          addToDraftButton: "В черновики",
          error: "Заполнены не все обязательные поля!",
          download: "Загрузить"
        }
      },
      sendToken: {
        balance: "Ваш баланс",
        address: "Адрес кошелька",
        addressPlaceholder: "Введите адрес",
        incorrectAddress: "Неверный адрес",
        myCounterparties: "Мои контакты",
        haveCounterparty: "Данный адрес есть в ваших контактах",
        noCounterparty: "Данного адреса нет в ваших контактах",
        newCounterpartyNameLabel: "Имя нового контрагента",
        newCounterpartyNamePlaceholder: "Введите имя",
        newCounterpartyAdd: "Добавить",
        newCounterpartyAdded: "Добавлен",
        transferSum: "Сумма перевода",
        transferSumPlaceholder: "Введите сумму",
        cancel: "Отмена",
        send: "Отправить",
        sendTroughCryptoWallet: "Отправить через криптокошелёк",
        form: {
          label: "Мои контакты",
          searchPlaceholder: "Адрес, имя пользователя",
          noSearchResults: "По вашему запросу ничего не найдено"
        }
      },
      rightPanel: {
        dividents: {
          label: "Дата дивидентных выплат",
          downloadButton: "Загрузить отчётность"
        },
        shareholders: {
          label: "Собрание акционеров",
          createButton: "Создать собрание"
        },
        bankcard: {
          label: "Банковский аккаунт",
          addCardButton: "Добавить карту"
        },
        briefcase: {
          label: "Мой портфель"
        }
      },
      footer: {
        officialSite: "официальный сайт"
      },
      requisites: {
        label: "Реквизиты компании",
        fullNameLabel: "Полное наименование",
        abbrNameLabel: "Сокращенное наименование",
        INNKPPLabel: "ИНН/ КПП",
        OGRNLabel: "ОГРН",
        registeredAddressLabel: "Юр. адрес",
        bankDetailsLabel: "Банковские реквизиты"
      },
      goods: {
        label: "Мои товарные позиции",
        goodsCount: "Кол-во товаров",
        addGoodButton: "Подача заявки на токенизацию товара",
        loadMoreButton: "Загрузить ещё",
        firstColumn: "Номер",
        secondColumn: "Наим. товара",
        thirdColumn: "Выпущено",
        fourthColumn: "Статус",
        fifthColumn: "Остаток",
        sixthColumn: "В продаже",
        seventhColumn: "Цена",
        eightColumn: "Мультитокен",
        nineColumn: "Дробление",
        statusDraft: "Черновик",
        statusSended: "Заявка",
        statusIssued: "Выпущен",
        editAction: "Редактировать",
        deleteAction: "Удалить",
        sendAction: "Отправить",
        tokenizeAction: "Токенизировать",
        setInMarketplace: "На продажу",
        changePrice: "Изменить цену",
        removeFromSale: "Снять с продажи",
        chartName: "Изменение спроса",
        form: {
          goodNameLabel: "Название товара",
          goodNamePlaceholder: "Введите название товара",
          goodDescLabel: "Описание",
          goodDescPlaceholder: "Введите описание товара",
          goodImageLabel: "Изображение",
          goodCountLabel: "Количество",
          goodMarketLabel: "Размещение",
          goodMarketPlaceholder: "Выставить товар в маркетплейсе",
          goodTokensLabel: "Токены",
          goodTokensPlaceholder: "Выберите токен из списка",
          goodPriceLabel: "Цена",
          goodCompanyLabel: "Компания",
          goodCompanyPlaceholder: "Выберите компанию из списка",
          goodSerialNumLabel: "Серийный номер",
          goodSerialNumPlaceholder: "Введите cерийный номер товара",
          goodSectionLabel: "Раздел",
          goodSectionPlaceholder: "Выберите раздел из списка",
          goodItemNumberLabel: "Артикул",
          goodItemNumberPlaceholder: "Введите артикул товара",
          goodInternalСodeLabel: "Внутренний код",
          goodInternalСodePlaceholder: "Введите внутренний код товара",
          goodUnitLabel: "Единица измерения",
          goodUnitPlaceholder: "Введите единицу измерения",
          goBackButton: "Назад",
          sendButton: "Отправить",
          emptyFiledMessage: "Заполните все обязательные поля"
        },
        modal: {
          goodsChangeHeader: "Изменение цены",
          goodsChangePlaceholder: "Новая цена",
          goodsRemoveHeader: "Снять с продажи",
          goodsRemovePlaceholder: "Количество снимаемого товара",
          goodsButtonChange: "Изменить",
          goodsButtonRemove: "Снять",
          goodsButtonCancel: "Отмена"
        }
      },
      swap: {
        tabs: {
          market: "Рынок",
          limit: "Лимит"
        },
        swapInput: {
          youPay: "Вы заплатите",
          youGet: "Вы получите",
          balance: "Спот баланс:",
          value: "Значение: ",
          rate: "Курс -",
          price: "Цена: ",
          approximately: "Примерно:",
          exchangeButton: "Обменять"
        },
        buttons: {
          history: "История конвертаций",
          orders: "Мои ордера",
          accountManagement: "Управление счетами",
          marketStructure: "Структура рынка"
        },
        faq: {
          label: "Часто задаваемые вопросы"
        }
      },
      toasts: {
        unsubscribe: {
          success: "Вы успешно отписались от всех рассылок",
          unsuccess: "Ошибка отписания, возможно у вас нет рассылок"
        },
        success: {
          companyDelete: "Компания удалена",
          companyAdd: "Новая компания успешно добавлена",
          companySecondaryAdd: "Данные успешно сохранены, повторная отправка не требуется",
          companyEdit: "Компания успешно отредактирована",
          contactDelete: "Контакт успешно удалён",
          contactAdd: "Контакт успешно сохранён",
          contactEdit: "Контакт успешно обновлён",
          goodEdit: "Товар изменён",
          goodAdd: "Товар добавлен",
          goodSend: "Запрос на токенизацию отправлен",
          goodDelete: "Товар удалён",
          goodMarketplace: "Запрос для размещения в маркетплейсе отправлен",
          goodChangePrice: "Запрос на изменение цены отправлен",
          goodRemove: "Запрос на снятие товара успешно отправлен",
          projectDelete: "Заявка удалена",
          projectVerify: "Проект отправлен на утверждение",
          newsDelete: "Новость успешно удалена",
          newsPublish: "Новость успешно опубликована",
          newsUptPublish: "Новость успешно обновлена и опубликована",
          newsDraft: "Новость добавлена в черновики",
          newsUptDraft: "Новость обновлена и добавлена в черновики",
          emissionSend: "Заявка на эмиссию создана",
          emissionEdit: "Заявка на эмиссию отредактирована",
          contactCreate: "Контакт успешно сохранён",
          tokenSend: "Токены успешно отправлены",
          ethAdd: "ETH добавлен",
          ethDelete: "ETH удален"
        },
        error: {
          companyDelete: "Ошибка при добавлении компании",
          companyAdd: "Что-то пошло не так",
          contactAdd: "Ошибка при сохранении контакта",
          companyEdit: "Редактирование доступно только для черновиков",
          contactDelete: "Ошибка при удалении контакта",
          goodEdit: "Ошибка при изменении товара",
          goodAdd: "Ошибка при добавлении товара",
          goodSend: "Ошибка запроса на токенизацию",
          goodDelete: "Ошибка при удалении товара",
          goodMarketplace: "Ошибка запроса размещения в маркетплейсе",
          goodChangePrice: "Ошибка запроса на изменение цены",
          goodRemove: "Ошбика операции снятия товара",
          goodAmountExcess: "Нельзя вернуть больше единиц, чем размещено в маркете",
          projectDelete: "Что-то пошло не так",
          projectVerify: "Что-то пошло не так",
          projectNoVerify:
            "Заявка на рассмотрении, повторная верификация не требуется",
          projectEdit: "Заявка на рассмотрении, редактирование невозможно",
          projectHistory: "Проект на завершен или история не доступна",
          newsDelete: "Ошибка при удалении новости",
          newsPublish: "Ошибка при публикации новости",
          newsDraft: "Ошибка при добавлении новости в черновики",
          emissionSend: "Ошибка при создании заявки на эмиссию",
          emissionEdit: "Ошибка при редактировании заявки на эмиссию",
          emissionFile: "Ошибка при загрузке файла",
          contactCreate: "Ошибка при сохранении контакта",
          tokenSend: "Ошибка при отправке токена",
          ethAdd: "Ошибка при добавлении ETH",
          ethDelete: "Ошибка при удалении ETH"
        }
      }
    },
    en: {
      router: {
        wallet: "Wallet",
        walletSubTite: "My briefcase",
        exchange: "Exchange",
        exchangeSubTite: "Asset exchange",
        blockDex: "Trade",
        blockDexSubTite: "Simple trading",
        pages: {
          dashboard: "My office",
          requisite: "My accounts",
          goods: "Commodity tokens",
          projects: "My projects",
          projectsEdit: "Application for emission",
          companies: "My companies",
          companyManager: "Company management",
          daoTokens: "SoluExchange DAO",
          briefcase: "My briefcase",
          sendToken: "Sending token",
          transactions: "Transactions",
          accounts: "My accounts",
          dividends: "Dividends",
          dividendsHistory: "Payment history",
          counterparties: "Counterparties",
          editCounterparty: "Contact form",
          reports: "Reports",
          investProjects: "Projects",
          investProjectsApplication: "Request",
          account: "Account",
          news: "News",
          editNews: "News",
          user: "Profile",
          referralProgram: "Referral program",
          paymentMethod: "Payment method",
          security: "Security",
          settings: "Settings",
          support: "Support",
          editGood: "Application for products tokenization",
          assetsEmitent: "My tokens",
          assetsInvest: "Cryptoassets",
          autSales: "AUt presale (NEW)",
          pays: "Payment details",
          daoRequests: "DAO voting",
          daoEvents: "Voting results",
          documentation: "Reporting"
        }
      },
      exhangeRateBottom: {
        cryptoBalance: "Crypto",
        spotBalance: "Spot"
      },
      referralProgram: {
        userRefLabel: "Referral link",
        refSourceLabel:
          "You can optionally enter a data source for statistics:",
        refSourcePlaceholder: "Enter data source"
      },
      settings: {
        language: {
          label: "Language",
          ru: "Russian language",
          en: "English language",
          uz: "Uzbek language"
        },
        defaultToken: {
          label: "Default currency"
        },
        welcomeLanguage: {
          ru: "Russian",
          en: "English",
          uz: "Uzbek"
        }
      },
      validations: {
        "requiredPassword": "The Password field is required",
        "requiredEmail": "The Email field is required",
        "email": "The entered address is not valid",
        "minLength": "Minimum password length is {min} characters.",
        "containsUppercase": "Password must contain at least 1 uppercase letter",
        "containsLowercase": "Password must contain at least 1 lowercase letter",
        "containsNumber": "Password must contain at least 1 number"
      },
      login: {
        fName: "Name *",
        sName: "Second name *",
        mName: "Middle name",
        enter: "Enter",
        register: "Registration",
        password: "Password *",
        passwordRepeat: "Password repeat *",
        passwordWeak: "The password is weak",
        passwordDontMatch: "Passwords don't match",
        passwordStrong: "Minimum: 8 characters, 1 capital character, 1 digit",
        passwordNotRemember: "I do not remember",
        requiredFields: "Fill in the required fields",
        googleCode: "Enter the code from Google",
        incognito: "Use incognito mode on someone else's computer"
      },
      paymentMethod: {
        addCard: "Add a card",
        noCards: "No cards added",
        table: {
          name: "Name",
          holder: "Holder",
          number: "Number",
          expiresAt: "Expiration date",
          actions: "Actions"
        },
        modal: {
          save: "Save",
          cancel: "Cancel",
          edit: "Edit",
          delete: "Delete",
          yes: "Yes",
          no: "No",
          editHeader: "Edit card",
          addHeader: "Add card",
          deleteHeader: "Delete card",
          deleteMessage: "Are you sure you want to delete the card?"
        },
        toast: {
          editSuccess: "Card edited successfully",
          editError: "Error editing card",
          addSuccess: "Card added successfully",
          addError: "Error adding card",
          deleteSuccess: "Card deleted successfully",
          deleteError: "Error deleting card"
        }
      },
      ethAddresses: {
        titleAddress: "My addresses",
        title2faActive: "(active)",
        title2faNoActive: "(not active)",
        titlePassword: "Change password",
        addAddressTitle: "Add address",
        ethAddress: "ETH address",
        ethAddressError: "The address is incorrect or the field is empty",
        pasteFromWalletButton: "Paste from crypto wallet",
        addEthButton: "Add to my addresses",
        myAddressesTitle: "My addresses",
        validateButton: "Verify",
        validatedButton: "Verified",
        addToRegistryButton: "Add to the registry",
        addedToRegistryButton: "Added to the registry",
        modal: {
          title: "Deleting an address",
          text: "Are you sure you want to delete ETH:",
          removeEthButton: "Remove",
          resetButton: "Cancel"
        }
      },
      twoFA: {
        title2faActive: "(active)",
        title2faNoActive: "(not active)",
        titlePassword: "Change password",
        descriptionOne: "Download the Google Authenticator app. Scan the code on your cell phone. Make sure that the code is from the Authentic website and click \"Activate\"",
        descriptionTwo: "To disable 2FA, enter the code from Google Authenticator",
        on: "Turn on 2FA",
        off: "Turn off 2FA",
        enterGoogleCode: "Enter Google Code *"
      },
      changePassword: {
        titlePassword: "Change password",
        requiredFields: "Fill in the required fields",
        passwordDontMatch: "Passwords don't match",
        passwordDifficult: "Complicate the password",
        passwordRemember: "Remember your password!",
        passwordNew: "Come up with a new password.",
        passwordStrong: "8 characters, 1 capital character, 1 digit",
        passwordValid: "Valid password *",
        passwordNewPass: "New password *",
        passwordRepeat: "Repeat the password *",
        passwordChange: "Change Password"
      },
      welcome: {
        nextButton: "Next",
        backButton: "Back",
        error: "Not all required fields are filled in!",
        firstStep: {
          stepName: "Welcome",
          label: "Welcome to",
          text: {
            firstParagraph: "Thank you for choosing our platform",
            secondParagraph:
              "SoluExchange is a global financial blockchain ecosystem functioning on SoluChain using customized smart-contracts.",
            thirdParagraph:
              "We combine traditional finance and blockchain technologies, allowing any person or company to attract investments and invest in digital assets, as well as manage their investment portfolio.",
            fourthParagraph:
              "We invite you to familiarize yourself with the description of the functional characteristics that are available in SoluExchange:",
            fifthParagraph:
              "We are confident that our mutual cooperation will be productive since we have created a product that facilitates doing business around the world by speeding up all business processes."
          },
          list: {
            firstLine: {
              highlight: "Invest",
              text: " in companies from all over the world and get additional income."
            },
            secondLine: {
              highlight: "Attract",
              text: " investments for your own projects and business expansion."
            },
            thirdLine: {
              highlight: "Buy and sell",
              text: " digitized goods and services."
            },
            fourthLine: {
              highlight: "Spend",
              text: " financial transactions without restrictions."
            }
          }
        },
        secondStep: {
          stepName: "Role Selection",
          label: "Please select your status",
          text: "We will use this information to set up your SoluExchange account.",
          subTitle: "Select your status",
          investorRole: {
            label: "Investor",
            text: "We will use this information is for setting up your SoluExchange account."
          },
          issuerRole: {
            label: "Issuer",
            text: "We will use this information to set up your SoluExchange account."
          }
        },
        thirdStep: {
          stepName: "Personal data",
          label: {
            investorRole: "You have chosen the status of «Investor»",
            issuerRole: "You have chosen the status of «Issuer»",
            noRole: "Please choose a role"
          },
          text: "Please tell us more about yourself by filling in the fields below.",
          subTitle: "Your details",
          form: {
            bankAccountTypeLabel: "Account type",
            bankAccountTypePlaceholder: "Select account type",
            bankAccountTypeEntity: "Legal entity",
            bankAccountTypeIndividual: "Individual",
            nameRusLabel: "Company name",
            nameRusPlaceholder: "Enter company name",
            typeOfActivityLabel: "Type of activity",
            typeOfActivityPlaceholder: "Enter your type of activity",
            rusInnLabel: "INN ",
            rusInnPlaceholder: "INN",
            rusBikLabel: "BIC",
            rusBikPlaceholder: "BIC",
            bankLabel: "Name of the bank",
            bankPlaceholder: "Enter the name of the bank",
            lastNameLabelIndividual: "Information about you",
            lastNameLabelEntity: "Decision maker",
            lastNamePlaceholder: "Surname",
            firstNamePlaceholder: "Name",
            patronymicPlaceholder: "Patronymic",
            postPlaceholder: "Position",
            countryLabel: "Country",
            countryPlaceholder: "Choose your country",
            cityLabel: "City",
            cityPlaceholder: "Enter your city",
            addressRusLabel: "Address",
            addressRusPlaceholder: "Enter your street, house number",
            passportFileLabel: "Passport",
            charterFileLabel: "Charter",
            lprFileLabel: "LPR passport",
            innFileLabel: "INN",
            ogrnFileLabel: "OGRN",
            egrulFileLabel: "USRLE",
            registrationFileLabel: "Registration certificate",
            ogrnEgrulFileLabel: "OGRN / USRLE",
            cardFileLabel: "Company card",
            protocolFileLabel: "Protocol /solution",
            tosAgree: {
              text: "I confirm that I have read and agree with ",
              highlightToS: "Terms of Service",
              highlightPP: "Privacy Policy.",
              and: " and "
            },
            newsLettersAgree:
              "I want to receive promotional emails about new investment opportunities and updates to our platform (you can unsubscribe at any time in the privacy settings)."
          }
        },
        finalStep: {
          stepName: "Completion",
          label: {
            noRole: "Please select a role and fill in the required data",
            noData: "Please fill in the required data",
            successfulSending: "Successful registration!"
          },
          text: "Thank you for registering, expect confirmation by your e-mail, we will contact you very soon"
        }
      },
      profile: {
        connectWallet: "Connect wallet",
        connectWithWallet: "Connect with wallet"
      },
      profileMenu: {
        profile: "Profile",
        referralProgram: "Referral program",
        paymentMethod: "Payment method",
        security: "Security",
        settings: "Settings",
        support: "Support",
        logout: "Logout"
      },
      dashboard: {
        rates: {
          products: {
            name: "Products"
          },
          buttons: {
            all: "All",
            favorites: "Favorites"
          },
          cryptoassets: {
            name: "Сryptoassets"
          },
          goodsNamesDictionary: {
            name: "My products",
            one: "product",
            no: "products",
            many: "products"
          },
          projectsNamesDictionary: {
            name: "My projects",
            one: "project",
            no: "projects",
            many: "projects"
          },
          companiesNamesDictionary: {
            name: "My companies",
            one: "company",
            no: "companies",
            many: "companies"
          },
          assetsNamesDictionary: {
            name: "Crypto assets",
            one: "emission",
            no: "emissions",
            many: "emissions"
          }
        },
        changeChart: {
          label: "Portfolio price changes",
          all: "ALL",
          oneMonth: "М",
          fourMonths: "М",
          sixMonths: "М",
          oneYear: "Y",
          datePickerPlaceholder: "Select period"
        },
        products: {
          label: "My new product requests",
          goTo: "Go to"
        },
        transactions: {
          label: "Recent transactions",
          goTo: "Go to"
        },
        news: {
          label: "News by issuers",
          goTo: "Go to"
        },
        tradingLeaders: {
          label: "Bidding leaders",
          goTo: "Go to"
        }
      },
      briefcase: {
        fiatTabLabel: "Fiat tokens",
        cryptoTabLabel: "Cryptocurrencies",
        investTabLabel: "Investments",
        label: "My briefcase",
        subtext: "Cost",
        firstColumn: "Name",
        secondColumn: "Tokens count",
        thirdColumn: "Price per token",
        fourthColumn: "Profitability, % APR.",
        fifthColumn: "Total in AUg",
        sumColumn: "Total",
        sendAction: "Send",
        changeAction: "Exchange",
        bringOutAction: "Take out"
      },
      transactions: {
        cards: {
          transfersCard: "Transfers",
          investmentsCard: "Investments",
          goodsCard: "Goods",
          exchangeCard: "Exchange"
        },
        transferType: "Transfer",
        emissionType: "Emission",
        unspecified: "Unspecified",
        label: "Transactions",
        allPlaceholder: "All",
        moreButton: "More",
        cryptoAssetPlaceholder: "Crypto-asset",
        datePickerPlaceholder: "Select a period",
        confirmАddress:
          "To view transactions, confirm your address through a crypto wallet",
        goTo: "Go to",
        transactionType: {
          placeholder: "Transaction type",
          all: "All",
          receipts: "Receipts",
          spending: "Spending"
        },
        titleType: {
          all: "Spending and Receipts for",
          receipts: "Receipts for",
          spending: "Spending for"
        }
      },
      counterparties: {
        label: "Counterparties list",
        addButton: "Add counterparty",
        firstColumn: "Wallet number",
        secondColumn: "Owner name",
        thirdColumn: "Counterparty status",
        fourthColumn: "Action",
        editAction: "Edit",
        deleteAction: "Delete",
        dividendsAction: "Dividends",
        daoRequests: "DAO voting",
        deleteDialog: {
          title: "Delete counterparty",
          subtitle: "Are you sure you want to delete a counterparty?",
          agree: "Yes",
          cancel: "Cancel"
        },
        form: {
          label: "New counterparty",
          backButton: "Cancel",
          FIOlabel: "Сounterparty's name",
          FIOPlaceholder: "Enter сounterparty's name",
          addressLabel: "Counterparty's address",
          addressPlaceholder: "Enter сounterparty's address",
          statusLabel: "Counterparty's status",
          statusPlaceholder: "Enter сounterparty's status",
          saveBtn: "Save",
          error: "Not all required fields are filled!"
        }
      },
      tokeninfo: {
        balance: "Overall balance",
        cryptoBalance: "Crypto-balance",
        spotBalance: "Spot-balance",
        addToWallet: "Add to Crypto Wallet",
        pay: "Pay",
        refill: "Refill",
        dao: "DAO",
        transfer: "Transfer",
        withdrawal: "Withdrawal",
        transactions: "Transactions",
        loadMore: "More",
        copySuccess: "Copied to clipboard",
        copyError: "Error while copying to clipboard"
      },
      companies: {
        label: "My companies",
        addCompanyButton: "Add company",
        companiesCount: "Number of companies",
        firstColumn: "Company name",
        secondColumn: "Country",
        thirdColumn: "Description",
        fourthColumn: "Status",
        fifthColumn: "Action",
        companyVerified: "Confirmed",
        companyNotVerified: "Under review",
        companyIsDraft: "Draft",
        deleteDialog: {
          title: "Delete company",
          subtitle: "Are you sure you want to delete the company",
          agree: "Yes",
          cancel: "Cancel"
        },
        form: {
          addCompanyLabel: "Add company",
          editCompanyLabel: "Edit company",
          cancel: "Cancel",
          nameLabel: "Name",
          nameRusPlaceholder: "Company name (in Russian)",
          nameEngPlaceholder: "Company name(in English)",
          descriptionLabel: "Description",
          descriptionRusPlaceholder: "The field of activity (in Russian)",
          descriptionEngPlaceholder: "The field of activity(in English)",
          addressLabel: "Address",
          addressRusPlaceholder: "Address (Russian)",
          addressEngPlaceholder: "Address (English)",
          companyInfoLabel: "Company Info",
          countryPlaceholder: "Country",
          URLPlaceholder: "URL of the site",
          companyReqLabel: "Details",
          INNPlaceholder: "INN",
          OGRNPlaceholder: "PSRN",
          KPPPPlaceholder: "KPP (for Russia)",
          bankPlaceholder: "Bank details (for Russia)",
          reqLabel: "Applications",
          reqPlaceholder:
            "Company Verification Request (ed. will become unavailable)",
          saveAsDraft: "Save as Draft"
        }
      },
      reports: {
        label: "Order a report",
        tableLabel: "Reports received",
        pickCompanyText:
          "Select the company and the period for which you would like to receive the report",
        companyPlaceholder: "Company name",
        oneMonth: "M",
        fourMonths: "M",
        sixMonths: "M",
        oneYear: "Y",
        orderReport: "Order a report",
        firstColumn: "Company",
        secondColumn: "Formation date",
        thirdColumn: "Reporting period",
        fourthColumn: "Action"
      },
      daoPage: {
        title: "DAO voting",
        button: "Add",
        firstColumn: "Name",
        secondColumn: "Number of tokens",
        thirdColumn: "Start",
        fourthColumn: "Ending",
        fifthColumn: "Action",
        menuEdit: "Edit",
        menuDelete: "Delete",
        menuResult: "Results",
        actionAdd: "Add",
        actionEdit: "Edit",
        actionDelete: "Delete",
        tabVote: "Vote",
        tabAnswer: "Answer options",
        modalName: "Name",
        modalDescription: "Description",
        modalLimit: "Number of tokens",
        modalStart: "Start date",
        modalEnd: "End date",
        modalAnswer: "Possible answer",
        buttonCancel: "Cancel",
        modalIsDelete: "Are you sure you want to delete this item?",
        success: {
          add: "Item added successfully",
          edit: "Item edited successfully",
          delete: "Item deleted successfully"
        },
        error: {
          add: "Error adding element",
          edit: "Error during editing",
          delete: "Error during uninstall process"
        },
        eventTitle: "Voting results",
        eventFirstColumn: "Possible answer",
        eventSecondColumn: "Number of votes"
      },
      documentation: {
        label: "Reporting",
        button: "Download reports",
        firstColumn: "Name",
        secondColumn: "Type",
        thirdColumn: "Size",
        fourthColumn: "Created",
        fifthColumn: "Changed",
        sixthColumn: "Action",
        actionDelete: "Delete",
        modalHeader: "Are you sure you want to delete the file?",
        modalButtonYes: "Yes",
        modalButtonCancel: "Cancel",
        success: {
          upload: "File uploaded successfully",
          delete: "File deleted successfully"
        },
        error: {
          upload: "Error uploading file",
          delete: "Error deleting file"
        }
      },
      chat: {
        title: "Messages",
        placeholder: "To write a message..."
      },
      dividendsHistory: {
        label: "Payment history",
        firstColumn: "Number of tokens",
        secondColumn: "Reserve token",
        thirdColumn: "Status",
        fourthColumn: "Created",
        fifthColumn: "Wallet"
      },
      news: {
        label: "My news",
        newsCount: "News count",
        addNewsButton: "Add news",
        firstColumn: "Image",
        secondColumn: "Title",
        thirdColumn: "Status",
        fourthColumn: "Action",
        deleteDialog: {
          title: "Delete news",
          subtitle: "Are you sure you want to delete the news?",
          agree: "Yes",
          cancel: "Cancel"
        },
        form: {
          label: "Loading News",
          imageLabel: "Image",
          titleLabel: "Title",
          titlePlaceholder: "Enter title",
          subtitleLabel: "Subtitle",
          subtitlePlaceholder: "Enter subtitle",
          contentLabel: "Description",
          contentPlaceholder: "Enter description",
          auTokenLabel: "Project",
          auTokenPlaceholder: "Enter project",
          publishNewsButton: "Publish",
          cancelButton: "Cancel",
          addToDraftButton: "To draft",
          error: "Not all required fields are filled!",
          download: "Download"
        }
      },
      sendToken: {
        balance: "Your balance",
        address: "Wallet address",
        addressPlaceholder: "Enter address",
        incorrectAddress: "Incorrect address",
        myCounterparties: "My contacts",
        haveCounterparty: "This address is in your contacts",
        noCounterparty: "This address is not in your contacts",
        newCounterpartyNameLabel: "New counterparty name",
        newCounterpartyNamePlaceholder: "Enter name",
        newCounterpartyAdd: "Add",
        newCounterpartyAdded: "Added",
        transferSum: "Transfer amount",
        transferSumPlaceholder: "Enter the amount",
        cancel: "Cancel",
        send: "Send",
        sendTroughCryptoWallet: "Send via Crypto Wallet",
        form: {
          label: "My counterparties",
          searchPlaceholder: "Address, username",
          noSearchResults: "Nothing was found for your query"
        }
      },
      rightPanel: {
        dividents: {
          label: "Dividend payments date",
          downloadButton: "Download reports"
        },
        shareholders: {
          label: "Shareholders meeting",
          createButton: "Create a meeting"
        },
        bankcard: {
          label: "Bank account",
          addCardButton: "Add card"
        },
        briefcase: {
          label: "My briefcase"
        }
      },
      footer: {
        officialSite: "official site"
      },
      goods: {
        label: "My commodity items",
        goodsCount: "Quantity of goods",
        addGoodButton: "Request for tokenization of goods",
        loadMoreButton: "Load more",
        firstColumn: "Number",
        secondColumn: "Item Name",
        thirdColumn: "Released",
        fourthColumn: "Status",
        fifthColumn: "Remainder",
        sixthColumn: "On sale",
        seventhColumn: "Price",
        eightColumn: "Multi-token",
        nineColumn: "Fragmentation",
        statusDraft: "Draft",
        statusSended: "Request",
        statusIssued: "Released",
        editAction: "Edit",
        deleteAction: "Delete",
        sendAction: "Send",
        tokenizeAction: "Tokenize",
        setInMarketplace: "For sale",
        changePrice: "Change the price",
        removeFromSale: "Remove from sale",
        chartName: "Change in Demand",
        form: {
          goodNameLabel: "Product Name",
          goodNamePlaceholder: "Enter product name",
          goodDescLabel: "Description",
          goodDescPlaceholder: "Enter product description",
          goodImageLabel: "Image",
          goodCountLabel: "Quantity",
          goodMarketLabel: "Accommodation",
          goodMarketPlaceholder: "Display the product in the marketplace",
          goodTokensLabel: "Tokens",
          goodTokensPlaceholder: "Select a token",
          goodPriceLabel: "Price",
          goodCompanyLabel: "Company",
          goodCompanyPlaceholder: "Select a company",
          goodSerialNumLabel: "Serial Number",
          goodSerialNumPlaceholder: "Enter product serial number",
          goodSectionLabel: "Section",
          goodSectionPlaceholder: "Select a section",
          goodItemNumberLabel: "Article",
          goodItemNumberPlaceholder: "Enter product part number",
          goodInternalСodeLabel: "Internal Code",
          goodInternalСodePlaceholder: "Enter internal product code",
          goodUnitLabel: "Unit",
          goodUnitPlaceholder: "Enter unit of measurement",
          goBackButton: "Back",
          sendButton: "Send",
          emptyFiledMessage: "Fill in all required fields"
        },
        modal: {
          goodsChangeHeader: "Change the price",
          goodsChangePlaceholder: "New price",
          goodsRemoveHeader: "Remove from sale",
          goodsRemovePlaceholder: "Quantity of removed goods",
          goodsButtonChange: "Change",
          goodsButtonRemove: "Remove",
          goodsButtonCancel: "Cancel"
        }
      },
      requisites: {
        label: "Company requisites",
        fullNameLabel: "Full Name",
        abbrNameLabel: "Short name",
        INNKPPLabel: "INN / KPP",
        OGRNLabel: "PSRN",
        registeredAddressLabel: "Legal address",
        bankDetailsLabel: "Bank details"
      },
      swap: {
        tabs: {
          market: "Market",
          limit: "Limit"
        },
        swapInput: {
          youPay: "You pay",
          youGet: "You get",
          balance: "Spot balance:",
          value: "Value: ",
          rate: "Rate -",
          price: "Price: ",
          approximately: "Approximately:",
          exchangeButton: "Exchange"
        },
        buttons: {
          history: "Conversion history",
          orders: "My orders",
          accountManagement: "Account Management",
          marketStructure: "Market Structure"
        },
        faq: {
          label: "Frequently Asked Questions"
        }
      },
      toasts: {
        unsubscribe: {
          success: "You have successfully unsubscribed from all mailing lists",
          unsuccess: "Unsubscribe error, maybe you don't have mailing lists"
        },
        success: {
          companyDelete: "Company deleted",
          companyAdd: "New company successfully added",
          companySecondaryAdd: "Data saved successfully, no resending required",
          companyEdit: "Company successfully edited",
          contactDelete: "Contact successfully deleted",
          contactAdd: "Contact successfully saved",
          contactEdit: "Contact successfully updated",
          goodEdit: "Product changed",
          goodAdd: "Goods added",
          goodSend: "Tokenization request sent",
          goodDelete: "Item removed",
          goodMarketplace: "Marketplace request sent",
          goodChangePrice: "Price change request sent",
          goodRemove: "Cancellation request successfully sent",
          projectDelete: "Request deleted",
          projectVerify: "Project sent for approval",
          newsDelete: "News successfully deleted",
          newsPublish: "The news was successfully published",
          newsUptPublish: "The news was successfully updated and published",
          newsDraft: "The news has been added to drafts",
          newsUptDraft: "News updated and added to drafts",
          emissionSend: "The emission request has been created",
          emissionEdit: "The emission request has been edited",
          contactCreate: "Contact successfully saved",
          tokenSend: "Tokens successfully sent",
          ethAdd: "ETH added",
          ethDelete: "ETH removed"
        },
        error: {
          companyDelete: "Something went wrong",
          companyAdd: "Something went wrong",
          contactAdd: "Error saving contact",
          companyEdit: "Editing is only available for drafts",
          contactDelete: "Error when deleting contact",
          goodEdit: "Error when changing the product",
          goodAdd: "Error adding a product",
          goodSend: "Tokenization request error",
          goodDelete: "Error when deleting merchandise",
          goodMarketplace: "Marketplace request error",
          goodChangePrice: "Error when requesting a price change",
          goodRemove: "Item removal operation error",
          goodAmountExcess: "You can not return more units than placed in the market",
          projectDelete: "Something went wrong",
          projectVerify: "Something went wrong",
          projectNoVerify:
            "The request is pending, no reverification is required",
          projectEdit: "Application under review, editing not possible",
          projectHistory:
            "The project is not completed or the history is not available",
          newsDelete: "Error in deleting news",
          newsPublish: "Error publishing the news",
          newsDraft: "Error adding news to drafts",
          emissionSend: "Error in creating an emission request",
          emissionEdit: "Error when editing the emission request",
          emissionFile: "Error while loading the file",
          contactCreate: "Error in saving the contact",
          tokenSend: "Error sending token",
          ethAdd: "Error adding ETH",
          ethDelete: "Error in deleting ETH"
        }
      }
    },
    uz: {
      router: {
        wallet: "Wallet",
        walletSubTite: "Mening portfelim",
        exchange: "Ayirboshlash",
        exchangeSubTite: "Aktivlar almashinuvi",
        blockDex: "Savdo",
        blockDexSubTite: "Oddiy savdo",
        pages: {
          dashboard: "Mening kabinetim",
          requisite: "Mening hisoblarim",
          goods: "Tovar belgilari",
          projects: "Mening loyihalarim",
          projectsEdit: "Emissiya uchun ariza",
          companies: "Mening kompaniyalarim",
          companyManager: "Kompaniyalarni boshqarish",
          daoTokens: "SoluExchange DAO",
          briefcase: "Mening portfelim",
          sendToken: "Aktivni yuborish",
          transactions: "Operatsiyalar",
          accounts: "Mening hisoblarim",
          dividends: "Dividendlar",
          dividendsHistory: "To'lov tarixi",
          counterparties: "Kontragentlar",
          editCounterparty: "Emissiya uchun ariza",
          reports: "Hisobotlar",
          investProjects: "Loyihalar",
          investProjectsApplication: "Ilova",
          account: "Hisob",
          news: "Yangiliklar",
          editNews: "Yangiliklar",
          user: "Profil",
          referralProgram: "Yo'naltiruvchi dastur",
          paymentMethod: "To'lov usuli",
          security: "Xavfsizlik",
          settings: "Sozlamalar",
          support: "Qo'llab-quvvatlash",
          editGood: "Tovarni tokenizatsiya qilish uchun ariza",
          assetsEmitent: "Mening tokenlarim",
          assetsInvest: "Kripto aktivlari",
          autSales: "Qayta sotish AUt",
          pays: "To'lov tafsilotlari",
          daoRequests: "DAO ovoz berish",
          daoEvents: "Ovoz berish natijalari",
          documentation: "Hisobot berish"
        }
      },
      referralProgram: {
        userRefLabel: "yo'naltiruvchi link",
        refSourceLabel:
          "siz ixtiyoriy ravishda statistika uchun ma'lumot manbasini kiritishingiz mumkin:",
        refSourcePlaceholder: "ma'lumotlar manbasini kiriting"
      },
      settings: {
        language: {
          label: "Til",
          ru: "Rus tili",
          en: "Ingliz tili",
          uz: "O'zbek tili"
        },
        defaultToken: {
          label: "Standart valyuta"
        },
        welcomeLanguage: {
          ru: "Rus",
          en: "Ingliz",
          uz: "O'zbek"
        }
      },
      validations: {
        "requiredPassword": "Parol maydoni kerak",
        "requiredEmail": "Elektron pochta maydoni shart",
        "email": "Kiritilgan manzil noto'g'ri",
        "minLength": "Minimal parol uzunligi {min} belgidan iborat.",
        "containsUppercase": "Parol kamida 1 ta bosh harfdan iborat bo'lishi kerak",
        "containsLowercase": "Parol kamida 1 ta kichik harfdan iborat bo'lishi kerak",
        "containsNumber": "Parolda kamida 1 raqam bo'lishi kerak"
      },
      login: {
        fName: "Ism *",
        sName: "Familiyasi *",
        mName: "Otasining ismi",
        enter: "Kirish",
        register: "Ro'yxatdan o'tish",
        password: "Parol *",
        passwordRepeat: "Parolni takrorlang *",
        passwordWeak: "Parol zaif",
        passwordDontMatch: "Parollar mos kelmaydi",
        passwordStrong: "Minimal: 8 ta belgi, 1 ta bosh belgi, 1 ta raqam",
        passwordNotRemember: "Esimda yo'q",
        requiredFields: "Majburiy maydonlarni to'ldiring",
        googleCode: "Google-dan kodni kiriting",
        incognito: "Boshqa birovning kompyuterida inkognito rejimidan foydalaning"
      },
      paymentMethod: {
        addCard: "Karta qo'shing",
        noCards: "Hech qanday karta qo'shilmagan",
        table: {
          name: "tugash muddati",
          holder: "Ushlagich",
          number: "Raqam",
          expiresAt: "Tugash muddati",
          actions: "Harakatlar"
        },
        modal: {
          save: "Saqlash",
          cancel: "Bekor qilish",
          edit: "Tahrirlash",
          delete: "o`chirish",
          yes: "Ha",
          no: "Yo'q",
          editHeader: "Xaritani tahrirlash",
          addHeader: "Xarita qo'shish",
          deleteHeader: "Xaritani o'chirish",
          deleteMessage: "Haqiqatan ham xaritani o'chirib tashlamoqchimisiz?"
        },
        toast: {
          editSuccess: "Xarita muvaffaqiyatli tahrir qilindi",
          editError: "Xaritani tahrirlashda xatolik",
          addSuccess: "Xarita muvaffaqiyatli qo'shildi",
          addError: "Xarita qo'shishda xatolik",
          deleteSuccess: "Xarita muvaffaqiyatli o'chirildi",
          deleteError: "Xaritani oʻchirishda xatolik yuz berdi"
        }
      },
      ethAddresses: {
        titleAddress: "Mening manzillarim",
        title2faActive: "(faol)",
        title2faNoActive: "(faol emas)",
        titlePassword: "Parolni o'zgartiring"
      },
      twoFA: {
        title2faActive: "(faol)",
        title2faNoActive: "(faol emas)",
        titlePassword: "Parolni o'zgartiring",
        descriptionOne: "Google Authenticator dasturini yuklab oling. Uyali telefonda kodni skanerlang. Kod Authentic saytidan ekanligiga ishonch hosil qiling va \"faollashtirish\"tugmasini bosing",
        descriptionTwo: "Uchun 2FA-ni o'chirib qo'ying Google Authenticator-dan kodni kiriting",
        on: "Yoqish 2FA",
        off: "O'chirish 2FA",
        enterGoogleCode: "Kiriting Google Code *"
      },
      changePassword: {
        titlePassword: "Parolni o'zgartiring",
        requiredFields: "Majburiy maydonlarni to'ldiring",
        passwordDontMatch: "Parollar mos kelmaydi",
        passwordDifficult: "Parolni murakkablashtiring",
        passwordRemember: "Parolingizni eslang!",
        passwordNew: "Yangi parol bilan chiqing.",
        passwordStrong: "8 ta belgi, 1 ta bosh belgi, 1 ta raqam",
        passwordValid: "Haqiqiy parol *",
        passwordNewPass: "Yangi parol *",
        passwordRepeat: "Parolni takrorlang *",
        passwordChange: "Parolni o'zgartiring"
      },
      welcome: {
        nextButton: "keyingi",
        backButton: "orqaga",
        error: "barcha majburiy maydonlar to'ldirilmagan!",
        firstStep: {
          stepName: "salomlashish",
          label: "xush kelibsiz ",
          text: {
            firstParagraph: "platformamizni tanlaganingiz uchun tashakkur",
            secondParagraph:
              "SoluExchange-bu SoluChain-da ishlaydigan global moliyaviy blokcheyn ekotizimi, moslashtirilgan aqlli shartnomalar yordamida.",
            thirdParagraph:
              "biz an'anaviy moliya va blokcheyn har qanday shaxs yoki kompaniyaga investitsiyalarni jalb qilish va investitsiya qilish imkonini beruvchi texnologiya raqamli aktivlar shuningdek, ularning investitsiya portfelini boshqarish.",
            fourthParagraph:
              "SoluExchange-da mavjud bo'lgan funktsional xususiyatlarning tavsifi bilan tanishishingizni tavsiya qilamiz:",
            fifthParagraph:
              "biz o'zaro hamkorligimiz samarali bo'lishiga aminmiz, chunki biz mahsulotni yaratdik bu butun dunyo bo'ylab biznes yuritishga yordam beradi barcha biznes jarayonlarini tezlashtirish orqali."
          },
          list: {
            firstLine: {
              highlight: "investitsiya qiling",
              text: " butun dunyo bo'ylab kompaniyada va qo'shimcha daromad oling."
            },
            secondLine: {
              highlight: "jalb qiling",
              text: " o'z loyihalaringizga sarmoya kiritish va biznesni kengaytirish."
            },
            thirdLine: {
              highlight: "sotib oling va soting",
              text: " raqamli tovarlar va xizmatlar."
            },
            fourthLine: {
              highlight: "o'tkazish",
              text: " moliyaviy operatsiyalar cheklovlarsiz."
            }
          }
        },
        secondStep: {
          stepName: "rolni tanlash",
          label: "iltimos, holatingizni tanlang",
          text: "biz ushbu ma'lumotdan SoluExchange hisobingizni sozlash uchun foydalanamiz.",
          subTitle: "statusingizni tanlang",
          investorRole: {
            label: "investor",
            text: "biz ushbu ma'lumotdan SoluExchange hisobingizni sozlash uchun foydalanamiz."
          },
          issuerRole: {
            label: "emitent",
            text: "biz ushbu ma'lumotdan SoluExchange hisobingizni sozlash uchun foydalanamiz."
          }
        },
        thirdStep: {
          stepName: "shaxsiy ma'lumotlar",
          label: {
            investorRole: "siz «investor» maqomini tanladingiz",
            issuerRole: "siz «emitent» maqomini tanladingiz",
            noRole: "iltimos, rolni tanlang"
          },
          text: "iltimos, quyidagi maydonlarni to'ldirib, o'zingiz haqingizda ko'proq ma'lumot bering.",
          subTitle: "Sizning ma'lumotlaringiz",
          form: {
            bankAccountTypeLabel: "hisob turi",
            bankAccountTypePlaceholder: "hisob turini tanlang",
            bankAccountTypeEntity: "yuridik shaxs",
            bankAccountTypeIndividual: "jismoniy shaxs",
            nameRusLabel: "kompaniya nomi",
            nameRusPlaceholder: "kompaniya nomini kiriting",
            typeOfActivityLabel: "faoliyat turi",
            typeOfActivityPlaceholder: "faoliyatingizni kiriting",
            rusInnLabel: "tin",
            rusInnPlaceholder: "tin",
            rusBikLabel: "Bik",
            rusBikPlaceholder: "Bik",
            bankLabel: "bank nomi",
            bankPlaceholder: "bank nomini kiriting",
            lastNameLabelIndividual: "siz haqingizda ma'lumotlar",
            lastNameLabelEntity: "qaror qabul qiluvchi",
            lastNamePlaceholder: "familiya",
            firstNamePlaceholder: "Ism",
            patronymicPlaceholder: "otasining ismi",
            postPlaceholder: "lavozim",
            countryLabel: "mamlakat",
            countryPlaceholder: "mamlakatingizni tanlang",
            cityLabel: "shahar",
            cityPlaceholder: "shaharingizni kiriting",
            addressRusLabel: "manzil",
            addressRusPlaceholder: "ko'changizni, uy raqamingizni kiriting",
            passportFileLabel: "pasport",
            charterFileLabel: "Nizom",
            lprFileLabel: "LPR pasporti",
            innFileLabel: "tin",
            ogrnFileLabel: "OGRN",
            egrulFileLabel: "USRUL",
            registrationFileLabel: "ro'yxatdan o'tish to'g'risida",
            ogrnEgrulFileLabel: "OGRN / USRUL",
            cardFileLabel: "korxona kartasi",
            protocolFileLabel: "protokol / yechim",
            tosAgree: {
              text: "men o'qiganimni va rozi ekanligimni tasdiqlayman ",
              highlightToS: "xizmat ko'rsatish shartlari",
              highlightPP: "maxfiylik siyosati.",
              and: " va "
            },
            newsLettersAgree:
              "men platformamizning yangi investitsiya imkoniyatlari va yangilanishlari haqida reklama elektron pochta xabarlarini olishni xohlayman (istalgan vaqtda maxfiylik sozlamalarida obunani bekor qilishingiz mumkin)."
          }
        },
        finalStep: {
          stepName: "tugatish",
          label: {
            noRole:
              "iltimos, rolni tanlang va kerakli ma'lumotlarni to'ldiring",
            noData: "iltimos, kerakli ma'lumotlarni to'ldiring",
            successfulSending: "muvaffaqiyatli ro'yxatdan o'tish!"
          },
          text: "ro'yxatdan o'tganingiz uchun tashakkur, elektron pochtangizda tasdiqlashni kuting, biz tez orada siz bilan bog'lanamiz"
        }
      },
      profile: {
        connectWallet: "Hamyonni ulang",
        connectWithWallet: "Hamyon bilan ulaning"
      },
      profileMenu: {
        profile: "Profil",
        referralProgram: "Yo'naltiruvchi dastur",
        paymentMethod: "To'lov usuli",
        security: "To'lov usuli",
        settings: "Sozlamalar",
        support: "Qo'llab-quvvatlash",
        logout: "Chiqish"
      },
      dashboard: {
        rates: {
          buttons: {
            all: "Hammasi",
            favorites: "Sevimlilar"
          },
          products: {
            name: "mahsulotlar"
          },
          cryptoassets: {
            name: "kripto aktivlari"
          },
          goodsNamesDictionary: {
            name: "Mening narsalarim",
            one: "mahsulot",
            no: "tovarlar",
            many: "mahsulotlar"
          },
          projectsNamesDictionary: {
            name: "Mening loyihalarim",
            one: "loyiha",
            no: "loyihalar",
            many: "loyihalar"
          },
          companiesNamesDictionary: {
            name: "Mening kompaniyalarim",
            one: "kompaniya",
            no: "kompaniyalar",
            many: "kompaniyalar"
          },
          assetsNamesDictionary: {
            name: "Kripto aktivlar",
            one: "emissiya",
            no: "emissiyalar",
            many: "emissiyalar"
          }
        },
        tradingLeaders: {
          label: "savdo rahbarlari",
          goTo: "O'tish"
        },
        changeChart: {
          label: "Portfel narxining o'zgarishi",
          all: "hammasi",
          oneMonth: "М",
          fourMonths: "М",
          sixMonths: "М",
          oneYear: "G",
          datePickerPlaceholder: "Davrni tanlang"
        },
        products: {
          label: "Mening yangi mahsulot so'rovlarim",
          goTo: "O'tish"
        },
        transactions: {
          label: "So'nggi operatsiyalar",
          goTo: "O'tish"
        },
        news: {
          label: "Emitentlar haqidagi yangiliklar",
          goTo: "O'tish"
        }
      },
      briefcase: {
        label: "Mening portfelim",
        subtext: "Xarajat",
        firstColumn: "Ism",
        secondColumn: "Tokenlar soni",
        thirdColumn: "Token uchun narx",
        fourthColumn: "Hosildorlik, % yil.",
        fifthColumn: "AUg natijalari",
        sumColumn: "S'om",
        sendAction: "Yuborish",
        changeAction: "Almashtirish",
        bringOutAction: "Chiqish"
      },
      counterparties: {
        label: "Kontragentlar ro'yxati",
        addButton: "kontakt Qo'shish",
        firstColumn: "hamyon raqami",
        secondColumn: "egasining ismi",
        thirdColumn: "kontragent maqomi",
        fourthColumn: "harakat",
        editAction: "tahrirlash",
        deleteAction: "o'chirish",
        dividendsAction: "dividendlar",
        daoRequests: "DAO ovoz berish",
        deleteDialog: {
          title: "kontaktni o'chirish",
          subtitle: "kontaktni o'chirishni xohlayotganingizga aminmisiz?",
          agree: "Ha",
          cancel: "bekor qilish"
        },
        form: {
          label: "yangi aloqa",
          backButton: "bekor qilish",
          FIOlabel: "egasining ismi",
          FIOPlaceholder: "kontakt nomini kiriting",
          addressLabel: "aloqa Manzili",
          addressPlaceholder: "kontakt manzilini kiriting",
          statusLabel: "aloqa holati",
          statusPlaceholder: "kontakt holatini kiriting",
          saveBtn: "saqlash",
          error: "barcha majburiy maydonlar To'ldirilmagan!"
        }
      },
      transactions: {
        label: "operatsiyalar",
        allPlaceholder: "hammasi",
        moreButton: "ko'proq yuklash",
        cryptoAssetPlaceholder: "Kriptoaktiv",
        datePickerPlaceholder: "davrni tanlang",
        confirmАddress:
          "Tranzaktsiyalarni ko'rish uchun kripto hamyoningiz orqali manzilingizni tasdiqlang",
        goTo: "O'tish",
        transactionType: {
          placeholder: "Tranzaksiya turi",
          all: "hammasi",
          receipts: "kelish",
          spending: "iste'mol"
        },
        titleType: {
          all: "uchun xarajatlar va tushumlar",
          receipts: "uchun qabul qilish",
          spending: "uchun chiqindilar"
        }
      },
      companies: {
        label: "mening kompaniyalarim",
        addCompanyButton: "kompaniyani Qo'shish",
        companiesCount: "kompaniyalar soni",
        firstColumn: "kompaniya nomi",
        secondColumn: "mamlakat",
        thirdColumn: "tavsif",
        fourthColumn: "holat",
        fifthColumn: "harakat",
        companyVerified: "tasdiqlangan",
        companyNotVerified: "ko'rib chiqilmoqda",
        companyIsDraft: "qoralama",
        deleteDialog: {
          title: "kompaniyani olib tashlash",
          subtitle: "siz kompaniyani olib tashlamoqchi ekanligingizga aminman",
          agree: "Ha",
          cancel: "bekor qilish"
        },
        form: {
          addCompanyLabel: "kompaniya Qo'shish",
          editCompanyLabel: "kompaniyani tahrirlash",
          cancel: "bekor qilish",
          nameLabel: "Ism",
          nameRusPlaceholder: "kompaniya nomi (rus tilida)",
          nameEngPlaceholder: "kompaniya nomi (ingliz tilida)",
          descriptionLabel: "tavsif",
          descriptionRusPlaceholder: "faoliyat sohasi (rus tilida)",
          descriptionEngPlaceholder: "faoliyat sohasi (ingliz tilida)",
          addressLabel: "manzil",
          addressRusPlaceholder: "manzil (rus)",
          addressEngPlaceholder: "manzil (ang)",
          companyInfoLabel: "kompaniya ma'lumotlari",
          countryPlaceholder: "mamlakat",
          URLPlaceholder: "kompaniya saytining URL manzili",
          companyReqLabel: "tafsilotlar",
          INNPlaceholder: "tin",
          OGRNPlaceholder: "OGRN",
          KPPPlaceholder: "KPP (Rossiya federatsiyasi uchun)",
          bankPlaceholder: "bank rekvizitlari (Rossiya federatsiyasi uchun)",
          reqLabel: "arizalar",
          reqPlaceholder:
            "kompaniyani tekshirish so'rovi (qizil rang mavjud bo'lmaydi)",
          saveAsDraft: "qoralama sifatida saqlash"
        }
      },
      tokeninfo: {
        balance: "Umumiy balans",
        cryptoBalance: "Kripto balansi",
        spotBalance: "Spot balansi",
        addToWallet: "kripto hamyoniga Qo'shish",
        pay: "to'lash",
        refill: "to'ldirish",
        dao: "DAO",
        transfer: "Transfer",
        withdrawal: "olib tashlash",
        transactions: "operatsiyalar",
        loadMore: "ko'proq yuklash",
        copySuccess: "buferga nusxalandi",
        copyError: "buferga nusxalashda xatolik yuz berdi"
      },
      reports: {
        label: "hisobotga buyurtma bering",
        tableLabel: "Qabul qilingan hisobotlar",
        pickCompanyText: "hisobot olishni istagan kompaniya va davrni tanlang",
        companyPlaceholder: "kompaniya nomi",
        oneMonth: "М",
        fourMonths: "М",
        sixMonths: "М",
        oneYear: "G",
        orderReport: "hisobotga buyurtma bering",
        firstColumn: "kompaniya",
        secondColumn: "shakllanish sanasi",
        thirdColumn: "hisobot davri",
        fourthColumn: "harakat"
      },
      daoPage: {
        title: "DAO ovoz berish",
        button: "Qo'shish",
        firstColumn: "Ism",
        secondColumn: "Tokenlar soni",
        thirdColumn: "Boshlash",
        fourthColumn: "Tugatish",
        fifthColumn: "Harakat",
        menuEdit: "Tahrirlash",
        menuDelete: "Oʻchirish",
        menuResult: "Natijalar",
        actionAdd: "Qo'shish",
        actionEdit: "Tahrirlash",
        actionDelete: "Oʻchirish",
        tabVote: "Ovoz berish",
        tabAnswer: "Javob variantlari",
        modalName: "Ism",
        modalDescription: "Tavsif",
        modalLimit: "Tokenlar soni",
        modalStart: "Boshlanish sanasi",
        modalEnd: "Tugash sanasi",
        modalAnswer: "Mumkin javob",
        buttonCancel: "Bekor qilish",
        modalIsDelete: "Haqiqatan ham bu elementni oʻchirib tashlamoqchimisiz?",
        success: {
          add: "Element muvaffaqiyatli qo‘shildi",
          edit: "Element muvaffaqiyatli tahrirlandi",
          delete: "Element muvaffaqiyatli oʻchirildi"
        },
        error: {
          add: "Element qo‘shishda xatolik yuz berdi",
          edit: "Tahrirlash paytida xato",
          delete: "O'chirish jarayonida xato"
        },
        eventTitle: "Ovoz berish natijalari",
        eventFirstColumn: "Mumkin javob",
        eventSecondColumn: "Ovozlar soni"
      },
      documentation: {
        label: "Hisobot",
        button: "Hisobotlarni yuklab olish",
        firstColumn: "Ism",
        secondColumn: "Turi",
        thirdColumn: "Hajmi",
        fourthColumn: "Yaratilgan",
        fifthColumn: "O'zgartirildi",
        sixthColumn: "Harakat",
        actionDelete: "Oʻchirish",
        modalHeader: "Haqiqatan ham faylni oʻchirib tashlamoqchimisiz?",
        modalButtonYes: "Ha",
        modalButtonCancel: "Bekor qilish",
        success: {
          upload: "Fayl muvaffaqiyatli yuklandi",
          delete: "Fayl muvaffaqiyatli oʻchirildi"
        },
        error: {
          upload: "Faylni yuklashda xatolik yuz berdi",
          delete: "Faylni oʻchirishda xatolik yuz berdi"
        }
      },
      chat: {
        title: "Xabarlar",
        placeholder: "Xabar yozish uchun..."
      },
      dividendsHistory: {
        label: "To'lov tarixi",
        firstColumn: "Tokenlar soni",
        secondColumn: "Zaxira belgisi",
        thirdColumn: "Holat",
        fourthColumn: "Yaratilgan",
        fifthColumn: "Hamyon"
      },
      news: {
        label: "mening yangiliklarim",
        newsCount: "yangiliklar soni",
        addNewsButton: "yangiliklar Qo'shish",
        firstColumn: "tasvir",
        secondColumn: "sarlavha",
        thirdColumn: "holat",
        fourthColumn: "harakat",
        deleteDialog: {
          title: "yangiliklarni o'chirish",
          subtitle: "yangiliklarni o'chirishni xohlayotganingizga aminmisiz?",
          agree: "Ha",
          cancel: "bekor qilish"
        },
        form: {
          label: "yangiliklarni yuklab olish",
          imageLabel: "rasm",
          titleLabel: "sarlavha",
          titlePlaceholder: "sarlavhani kiriting",
          subtitleLabel: "subtitr",
          subtitlePlaceholder: "pastki sarlavhani kiriting",
          contentLabel: "tavsif",
          contentPlaceholder: "tavsifni kiriting",
          auTokenLabel: "loyiha",
          auTokenPlaceholder: "loyihani tanlang",
          publishNewsButton: "nashr qilish",
          cancelButton: "bekor qilish",
          addToDraftButton: "qoralamalarga",
          error: "barcha majburiy maydonlar To'ldirilmagan!",
          download: "yuklab olish"
        }
      },
      sendToken: {
        balance: "sizning balansingiz",
        address: "hamyon Manzili",
        addressPlaceholder: "manzilni kiriting",
        incorrectAddress: "noto'g'ri manzil",
        myCounterparties: "mening aloqalarim",
        haveCounterparty: "ushbu manzil sizning kontaktlaringizda",
        noCounterparty: "ushbu manzil sizning kontaktlaringizda yo'q",
        newCounterpartyNameLabel: "yangi kontragentning nomi",
        newCounterpartyNamePlaceholder: "ismni kiriting",
        newCounterpartyAdd: "Qo'shish",
        newCounterpartyAdded: "Qo'shilgan",
        transferSum: "transfer summasi",
        transferSumPlaceholder: "miqdorni kiriting",
        cancel: "bekor qilish",
        send: "yuborish",
        sendTroughCryptoWallet: "kripto hamyon orqali yuborish",
        form: {
          label: "mening kontaktlarim",
          searchPlaceholder: "manzil, foydalanuvchi nomi",
          noSearchResults: "sizning so'rovingiz bo'yicha hech narsa topilmadi"
        }
      },
      rightPanel: {
        dividents: {
          label: "dividend to'lash sanasi",
          downloadButton: "hisobotni yuklab oling"
        },
        shareholders: {
          label: "aktsiyadorlar yig'ilishi",
          createButton: "uchrashuv yaratish"
        },
        bankcard: {
          label: "bank hisobi",
          addCardButton: "kartani Qo'shish"
        },
        briefcase: {
          label: "mening portfelim"
        }
      },
      footer: {
        officialSite: "rasmiy veb-sayt"
      },
      requisites: {
        label: "kompaniya tafsilotlari",
        fullNameLabel: "to'liq ism",
        abbrNameLabel: "qisqartirilgan ism",
        INNKPPLabel: "tin / KPP",
        OGRNLabel: "OGRN",
        registeredAddressLabel: "yuridik manzil",
        bankDetailsLabel: "bank ma'lumotlari"
      },
      goods: {
        label: "Mening tovarlarim",
        goodsCount: "tovarlar soni",
        addGoodButton: "tovarni tokenizatsiya qilish uchun ariza berish",
        loadMoreButton: "ko'proq yuklash",
        firstColumn: "raqam",
        secondColumn: "mahsulot nomi",
        thirdColumn: "chiqarilgan",
        fourthColumn: "holat",
        fifthColumn: "qolgan",
        sixthColumn: "sotuvda",
        seventhColumn: "narx",
        eightColumn: "ko'p tokenli",
        nineColumn: "ajralish",
        statusDraft: "qoralama",
        statusSended: "ilova",
        statusIssued: "ozod qilingan",
        editAction: "tahrirlash",
        deleteAction: "o'chirish",
        sendAction: "yuborish",
        tokenizeAction: "tokenizatsiya qilish",
        setInMarketplace: "sotuvda",
        changePrice: "narxni o'zgartirish",
        removeFromSale: "sotuvdan olib tashlash",
        chartName: "talabning o'zgarishi",
        form: {
          goodNameLabel: "mahsulot nomi",
          goodNamePlaceholder: "mahsulot nomini kiriting",
          goodDescLabel: "tavsif",
          goodDescPlaceholder: "mahsulot tavsifini kiriting",
          goodImageLabel: "rasm",
          goodCountLabel: "miqdori",
          goodMarketLabel: "turar joy",
          goodMarketPlaceholder: "mahsulotni bozorda ko'rsatish",
          goodTokensLabel: "Tokenlar",
          goodTokensPlaceholder: "ro'yxatdan Tokenni tanlang",
          goodPriceLabel: "narx",
          goodCompanyLabel: "kompaniya",
          goodCompanyPlaceholder: "ro'yxatdan kompaniyani tanlanga",
          goodSerialNumLabel: "Seriya raqami",
          goodSerialNumPlaceholder: "mahsulot seriya raqamini kiriting",
          goodSectionLabel: "bo'lim",
          goodSectionPlaceholder: "ro'yxatdan bo'limni tanlang",
          goodItemNumberLabel: "maqola",
          goodItemNumberPlaceholder: "mahsulot maqolasini kiriting",
          goodInternalСodeLabel: "ichki kod",
          goodInternalСodePlaceholder: "ichki mahsulot kodini kiriting",
          goodUnitLabel: "birlik",
          goodUnitPlaceholder: "o'lchov birligini kiriting",
          goBackButton: "orqaga",
          sendButton: "yuborish",
          emptyFiledMessage: "barcha kerakli maydonlarni to'ldiring"
        },
        modal: {
          goodsChangeHeader: "narxni o'zgartiring",
          goodsChangePlaceholder: "yangi narx",
          goodsRemoveHeader: "sotishdan voz kechish",
          goodsRemovePlaceholder: "olib tashlangan tovarlar miqdori",
          goodsButtonChange: "o'zgartirish",
          goodsButtonRemove: "qo'ying",
          goodsButtonCancel: "bekor qilish"
        }
      },
      swap: {
        tabs: {
          market: "bozor",
          limit: "limit"
        },
        swapInput: {
          youPay: "siz to'laysiz",
          youGet: "siz olasiz",
          balance: "spot balans:",
          value: "qiymat: ",
          rate: "kurs -",
          price: "narxi: ",
          approximately: "taxminan:",
          exchangeButton: "savdo qilish"
        },
        buttons: {
          history: "konvertatsiya tarixi",
          orders: "mening buyurtmalarim",
          accountManagement: "hisoblarni boshqarish",
          marketStructure: "bozor tuzilishi"
        },
        faq: {
          label: "tez-tez so'raladigan savollar"
        }
      },
      toasts: {
        unsubscribe: {
          success: "Siz barcha pochta roʻyxatlaridagi obunani muvaffaqiyatli bekor qildingiz",
          unsuccess: "Obunani bekor qilishda xatolik yuz berdi, ehtimol sizda pochta roʻyxatlari yoʻqdir"
        },
        success: {
          companyDelete: "kompaniya olib tashlandi",
          companyAdd: "yangi kompaniya muvaffaqiyatli qo'shildi",
          companySecondaryAdd:
            "ma'lumotlar muvaffaqiyatli saqlandi, qayta yuborish shart emas",
          companyEdit: "kompaniya muvaffaqiyatli tahrirlangan",
          contactDelete: "kontakt muvaffaqiyatli o'chirildi",
          contactAdd: "kontakt muvaffaqiyatli saqlandi",
          contactEdit: "kontakt muvaffaqiyatli yangilandi",
          goodEdit: "mahsulot o'zgartirildi",
          goodAdd: "mahsulot qo'shildi",
          goodSend: "tokenizatsiya so'rovi yuborildi",
          goodDelete: "mahsulot olib tashlandi",
          goodMarketplace: "marketplace so'rovi yuborildi",
          goodChangePrice: "narxni oʻzgartirish soʻrovi yuborildi",
          goodRemove: "bekor qilish soʻrovi muvaffaqiyatli yuborildi",
          projectDelete: "ilova o'chirildi",
          projectVerify: "loyiha tasdiqlash uchun yuborilgan",
          newsDelete: "Yangiliklar muvaffaqiyatli o'chirildi",
          newsPublish: "Yangiliklar muvaffaqiyatli nashr etildi",
          newsUptPublish:
            "Yangiliklar muvaffaqiyatli yangilandi va nashr etildi",
          newsDraft: "yangiliklar qoralamalarga qo'shildi",
          newsUptDraft: "yangiliklar yangilandi va qoralamalarga qo'shildi",
          emissionSend: "emissiya uchun ariza yaratildi",
          emissionEdit: "emissiya arizasi tahrirlangan",
          contactCreate: "kontakt muvaffaqiyatli saqlandi",
          tokenSend: "Tokenlar muvaffaqiyatli yuborildi",
          ethAdd: "ETH qo'shildi",
          ethDelete: "ETH olib tashlandi"
        },
        error: {
          companyDelete: "biror narsa noto'g'ri ketdi",
          companyAdd: "biror narsa noto'g'ri ketdi",
          contactAdd: "kontaktni saqlashda xato",
          companyEdit: "tahrirlash faqat qoralamalar uchun mavjud",
          contactDelete: "kontaktni o'chirishda xato",
          goodEdit: "mahsulotni o'zgartirishda xato",
          goodAdd: "mahsulotni qo'shishda xato",
          goodSend: "tokenizatsiya so'rovi xatosi",
          goodDelete: "mahsulotni o'chirishda xato",
          goodMarketplace: "marketplace so'rovi xatosi",
          goodChangePrice: "narxni oʻzgartirishni soʻrashda xatolik yuz berdi",
          goodRemove: "elementni olib tashlashda xatolik",
          goodAmountExcess: "siz bozorda joylashtirilganidan ko'proq birliklarni qaytarib bera olmaysiz",
          projectDelete: "biror narsa noto'g'ri ketdi",
          projectVerify: "biror narsa noto'g'ri ketdi",
          projectNoVerify:
            "ariza ko'rib chiqilmoqda, qayta tekshirish talab qilinmaydi",
          projectEdit: " ariza ko'rib chiqilmoqda, tahrirlash mumkin emas",
          projectHistory: "Loyiha tugallanmagan yoki tarix mavjud emas",
          newsDelete: "yangiliklarni o'chirishda xato",
          newsPublish: "yangiliklarni nashr etishda xato",
          newsDraft: "qoralamalarga yangiliklar qo'shishda xato",
          emissionSend: "emissiya arizasini yaratishda xato",
          emissionEdit: "emissiya arizasini tahrirlashda xato",
          emissionFile: "faylni yuklashda xato",
          contactCreate: "kontaktni saqlashda xato",
          tokenSend: "Tokenni yuborishda xato",
          ethAdd: "ETH qo'shishda xato",
          ethDelete: "ETH o'chirishda xato"
        }
      }
    }
  }
});

export default i18n;
