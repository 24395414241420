export default {
  namespaced: true,

  state: {
    tokenIdFrom: null,
    tokenIdTo: null,
    marketType: 0,
    lastDeal: {
      price: 44
    },
    trades: [],
    asks: [
      {
        price: "52",
        count: "4",
        time: "2023-04-22T11:13:47"
      },
      {
        price: "51",
        count: "4",
        time: "2023-04-22T11:13:27"
      },
      {
        price: "50",
        count: "9",
        time: "2023-04-22T13:15:34"
      },
      {
        price: "49",
        count: "8",
        time: "2023-04-22T13:15:50"
      },
      {
        price: "48",
        count: "4",
        time: "2023-04-22T13:16:50"
      },
      {
        price: "47",
        count: "6",
        time: "2023-04-22T13:17:50"
      },
      {
        price: "46",
        count: "3",
        time: "2023-04-22T13:18:50"
      },
      {
        price: "45",
        count: "7",
        time: "2023-04-22T13:19:50"
      },
      {
        price: "44",
        count: "4",
        time: "2023-04-22T13:25:50"
      }
    ],
    bids: [
      {
        price: "44",
        count: "3",
        time: "2023-04-22T11:13:28"
      },
      {
        price: "43",
        count: "1",
        time: "2023-04-22T11:14:28"
      },
      {
        price: "42",
        count: "3",
        time: "2023-04-22T11:15:24"
      },
      {
        price: "41",
        count: "3",
        time: "2023-04-22T11:16:24"
      },
      {
        price: "40",
        count: "3",
        time: "2023-04-22T11:17:24"
      },
      {
        price: "39",
        count: "8",
        time: "2023-04-22T11:18:24"
      },
      {
        price: "38",
        count: "4",
        time: "2023-04-22T11:19:24"
      },
      {
        price: "37",
        count: "6",
        time: "2023-04-22T11:19:44"
      },
      {
        price: "36",
        count: "3",
        time: "2023-04-22T13:15:24"
      }
    ]
  },

  getters: {
    tokenIdFrom: state => {
      return state.tokenIdFrom;
    },

    tokenIdTo: state => {
      return state.tokenIdTo;
    },

    marketType: state => {
      return state.marketType;
    },

    asks: state => {
      return state.asks;
    },

    bids: state => {
      return state.bids;
    },

    lastDeal: state => {
      return state.lastDeal;
    }
  },

  mutations: {
    setTokenIdFrom(state, tokenIdFrom) {
      state.tokenIdFrom = tokenIdFrom;
    },

    setTokenIdTo(state, tokenIdTo) {
      state.tokenIdTo = tokenIdTo;
    },

    setMarketType(state, marketType) {
      state.marketType = marketType;
    }
  },

  actions: {
    setTokenIdFrom({ commit }, tokenIdFrom) {
      commit("setTokenIdFrom", tokenIdFrom);
    },

    setTokenIdTo({ commit }, tokenIdTo) {
      commit("setTokenIdTo", tokenIdTo);
    },

    setMarketType({ commit }, marketType) {
      commit("setMarketType", marketType);
    }
  }
};
