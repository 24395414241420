import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import auth from "@/auth";

import NftController from "@/api/nftController";

const nftController = new NftController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async getNfts({ commit }, { page = 1 }) {
    const params = {
      pagination: true,
      page: page,
      itemsPerPage: 30
    };

    let resp = await nftController.getNfts(params);
    const data = resp.data;
    const totalItems = resp.totalItems;
    commit("setNfts", data);
    return { data, totalItems };
  }

  @setLoadingState
  async getCurrentUserNfts({ commit }, { page = 1 }) {
    const params = {
      pagination: true,
      page: page,
      itemsPerPage: 30,
      user: auth.user().id
    };

    let resp = await nftController.getNfts(params);
    const data = resp.data;
    const totalItems = resp.totalItems;
    commit("setNfts", data);
    return { data, totalItems };
  }

  @setLoadingState
  async postNft({ dispatch }, body) {
    await nftController.postNft(body);
    await dispatch("getNfts", { page: 1 });
  }

  @setLoadingState
  async patchNft({ dispatch }, body) {
    await nftController.patchNft(body);
    await dispatch("getNfts", { page: 1 });
  }

  @setLoadingState
  async removeNft({ dispatch }, id) {
    await nftController.removeNft(id);
    await dispatch("getNfts", { page: 1 });
  }

  @setLoadingState
  async getNftById(_context, id) {
    return await nftController.getNftById(id);
  }

  @setLoadingState
  async getUnits({ commit }) {
    const data = await nftController.getUnits();
    commit("setUnits", data);
  }
}


export default (new Actions).asPlainObject();