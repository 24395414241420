<template>
  <div
    v-if="showCounterpartiesTable"
    class="card parties"
  >
    <div class="card-parties-header">
      <div class="title">
        {{ $t("counterparties.label") }}
      </div>
      <AuButton
        class="add-btn"
        bordered
        center
        border-radius="10"
        @click="addCounterparty"
      >
        {{ $t("counterparties.addButton") }}
      </AuButton>
    </div>

    <div>
      <AuTable
        class="parties-table"
        :columns="columns"
        :items="counterpartiesInfo"
        :action-column="actionColumn"
        :icons="null"
        action-type="company"
        @del="openDeleteDialog"
        @edit="contactToEdit"
      />
      <div class="parties-accordion">
        <AuAccordion
          v-for="(party, index) in counterpartiesInfo"
          :key="party"
          :last-child="(index == counterpartiesInfo.length - 1)"
        >
          <template #title>
            <span class="company-header">{{ ( index + 1 ) }}</span>
          </template>
          <template #content>
            <div class="company-info container-fluid">
              <div class="row">
                <div class="col info-title">
                  <span>{{ $t('counterparties.firstColumn') }}</span>
                </div>
                <div class="col">
                  <span>{{ party.address }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col info-title">
                  <span>{{ $t('counterparties.secondColumn') }}</span>
                </div>
                <div class="col">
                  <span>{{ party.name }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col info-title">
                  <span>{{ $t('counterparties.thirdColumn') }}</span>
                </div>
                <div class="col">
                  <span>{{ party.status }}</span>
                </div>
              </div>
            </div>
          </template>
        </AuAccordion>
      </div>
    </div>
    <AuModal
      v-model="deleteDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          {{ $t("counterparties.deleteDialog.title") }}
        </span>
      </template>
      <template #body>
        <span>
          {{ $t("counterparties.deleteDialog.subtitle") }}
        </span>
      </template>
      <template #footer>
        <div class="vertical-buttons">
          <AuButton
            bordered
            center
            width="80px"
            @click="del"
          >
            {{ $t("counterparties.deleteDialog.agree") }}
          </AuButton>
          <AuButton
            bordered
            center
            width="80px"
            @click="preventDel"
          >
            {{ $t("counterparties.deleteDialog.cancel") }}
          </AuButton>
        </div>
      </template>
    </AuModal>
  </div>
  <RouterView v-else />
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Counterparties",
  data() {
    return {
      columns: [{ name: this.$t("counterparties.firstColumn"), align: "start" },
        { name: this.$t("counterparties.secondColumn") },
        { name: this.$t("counterparties.thirdColumn") },
        { name: this.$t("counterparties.fourthColumn"), align: "end" }],
      actionColumn: { label: "Действия", icon: "more" },
      deleteDialog: false,
      delId: null
    };
  },

  computed: {
    ...mapGetters({
      counterparties: "counterparties"
    }),

    counterpartiesInfo() {
      return _.map(this.counterparties, c => ({ address: this.$filters.shortAddress(c.address), name: c.name, status: c.status }));
    },

    showCounterpartiesTable() {
      return !_.includes(this.$route.path, "edit-counterparty");
    }
  },

  methods: {
    ...mapActions({
      deleteCounterparty: "deleteCounterparty"
    }),

    contactToEdit(ind) {
      this.$router.push({ name: "edit counterparty", params: { id: this.counterparties[ind].id } });
    },

    async del() {
      this.deleteDialog = false;
      try {
        await this.deleteCounterparty(this.delId);
        this.$toast.success(this.$t("toasts.success.contactDelete"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
        this.$router.push({ name: "counterparties" });
      }
      catch (error) {
        this.$toast.error(this.$t("toasts.error.contactDelete"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    },

    preventDel() {
      this.deleteDialog = false;
      this.delId = null;
    },

    openDeleteDialog(ind) {
      this.delId = this.counterparties[ind].id;
      this.deleteDialog = true;
    },

    addCounterparty() {
      this.$router.push({ name: "edit counterparty", params: { id: "new" } });
    }
  }
};
</script>

<style lang="scss" >
.parties{
  padding: 20px;
  font-family: 'Suisse Intl',serif;
  font-style: normal;

  &-table {
    @media screen and (max-width:560px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:560px) {
      display: block;
    }
  }
}

.col {
  padding: 0;
  font-size: 12px;
  &:not(.info-title) {
    text-align: end;
  }
}

.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }
  &-info {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: 20px
  }
}

.card-parties-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (max-width:350px) {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 30px;

    @media screen and (max-width:350px) {
      margin-bottom: 20px;
    }
  }

  .add-btn {
    width: fit-content;
  }
}
</style>