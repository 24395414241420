<template>
  <!-- More two variable  -->
  <div class="container-fluid proposal-info">
    <div class="row proposal-info-first-line">
      <div class="col-12 col-sm-3 col-md-3">
        <span class="mobile-body">
          {{ momentDate(daoRequest.endAt).format("DD.MM.YYYY HH:mm:ss Z") }}
        </span>
      </div>
      <div class="col-12 col-sm-4 col-md-4 left-indent">
        <span class="mobile-body">
          {{ daoRequest.name }}
        </span>
      </div>
      <div class="col-12 col-sm-5 col-md-5">
        <span class="mobile-body">
          {{ daoRequest.description }}
        </span>
      </div>
    </div>
    <div class="row proposal-info-second-line">
      <div class="separator" />
      <div class="col-6">
        <div class="wrapper">
          <span>Результаты %</span>
          <div class="chart-wrapper">
            <Apexchart
              height="160px"
              class="left-panel__card-chard"
              type="donut"
              :options="options"
              :series="series.perсent"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="wrapper vote">
          <span>Результаты количества</span>
          <div class="vote-wrapper">
            <div
              v-for="(item, index) in daoRequest.result"
              :key="index"
            >
              <span> {{ item.name }} - {{ item.count }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    daoRequest: {
      type: Object,
      default: () => {},
      required: true
    },
    userData: {
      type: Object,
      default: () => {},
      required: true
    },
    ownerAmount: {
      type: Number,
      default: 0,
      required: true
    }
  },

  computed: {
    series() {
      const count = [];
      const perсent = [];
      this.daoRequest.result.forEach((element) => {
        count.push(element.count);
      });
      const summCount = count.reduce((a, b) => a + b, 0);

      count.forEach((el) => {
        perсent.push(Math.round(((el * 100) / summCount) * 10) / 10);
      });
      return { count, perсent };
    },
    options() {
      const labels = [];
      this.daoRequest.result.forEach((element) => {
        labels.push(element.name);
      });

      return {
        labels: labels,
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          fontSize: "11px",
          fontFamily: "Suisse Intl",
          labels: {
            colors: [this.isLightTheme ? "#080808" : "#FFFFFF"]
          },
          markers: {
            width: 12,
            height: 8
          },
          formatter: function (seriesName, opts) {
            return [
              seriesName,
              " - ",
              opts.w.globals.series[opts.seriesIndex],
              "%"
            ];
          }
        }
      };
    },
    isLightTheme() {
      return this.$store.getters.isLightTheme;
    }
  },

  methods: {
    momentDate(date) {
      return moment(date);
    }
  }
};
</script>

<style lang="scss" scoped>
.proposal-info {
  &-first-line {
    padding-top: 30px;
    padding-bottom: 25px;
    font-weight: 450;
    font-size: 12px;
    border-bottom: 1px dashed var(--color-70);
    @media screen and (max-width: 576px) {
      padding-bottom: 0px;
      .col-12 {
        margin-bottom: 35px;
        &:first-child {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          border-top: 1px solid var(--color-80);
          border-bottom: 1px solid var(--color-80);
          .mobile-header {
            margin-bottom: 0;
          }
        }
        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }
  &-second-line {
    padding-top: 20px;
    padding-bottom: 40px;
    border-bottom: 1px dashed var(--color-40);
    position: relative;
    @media screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      border-bottom: none;
    }
    .separator {
      position: absolute;
      height: 50px;
      width: 0px;
      border-right: 0.01px dashed var(--color-70);
      padding: 0;
      left: 55%;
      top: 0;
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      @media screen and (max-width: 576px) {
        align-items: flex-start;
      }
      &.vote {
        @media screen and (max-width: 576px) {
          margin-top: 30px;
          row-gap: 20px;
        }
      }
      span {
        font-weight: 450;
        font-size: 12px;
      }
    }
  }
}
.mobile-header {
  font-size: 12px;
  color: var(--color-60);
  font-weight: 450;
  display: none;
  margin-bottom: 8px;
  @media screen and (max-width: 576px) {
    display: flex;
  }
}

.mobile-body {
  font-size: 14px;
}
.chart-wrapper {
  width: 350px;
  position: relative;
  @media screen and (max-width: 576px) {
    margin-left: -120px;
  }
}
.col,
.col-3,
.col-4,
.col-5,
.col-7 {
  padding: 0;
}

.row > * {
  padding-left: 0;
  padding-right: 0;
}
input {
  display: none;
}
.vote-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.left-indent {
  padding-right: 39px;
}
</style>
