<template>
  <div class="transaction">
    <img
      class="t-image"
      :src="icon"
      alt="cryptoactive"
      @click="toToken"
    >

    <div class="transaction-info">
      <div class="transaction-info__item transaction-info__left">
        <AuEthAddress
          class="fs14 transaction-info__item-name a_link"
          :volume="addressEth"
          :name="name"
        />

        <div class="fs11 transaction-info__type">
          {{ transactionType }}
        </div>
      </div>

      <div class="transaction-info__item transaction-info__right">
        <div
          class="fs14 transaction-info__amount"
        >
          {{ sign }}
          <AuDecimalStatic
            :value="amount"
            :decimal="decimals"
            :indent="decimals"
            :addition="mnemonic"
          />
        </div>

        <div class="fs11 transaction-info__card-type">
          {{ cardType }}
        </div>
      </div>
      <AuEthHash
        class="fs14 transaction-info__item-name a_link"
        :volume="hash"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

export default {
  props: {
    icon: {
      type: String,
      default: "/svg/token/criptoactive_icon.svg"
    },
    name: {
      type: String,
      required: true
    },
    addressFrom: {
      type: String,
      required: true
    },
    addressTo: {
      type: String,
      required: true
    },
    addressEth: {
      type: String,
      required: true
    },
    hash: {
      type: String,
      required: true
    },
    transactionType: {
      type: String,
      required: true
    },
    amount: {
      type: [Number, String],
      required: true
    },
    cardType: {
      type: String,
      required: true
    },
    isRefill: {
      type: Boolean,
      default: true
    },
    decimals: {
      type: Number,
      default: 0
    },
    mnemonic: {
      type: String,
      default: ""
    }
  },

  computed: {
    ...mapGetters({
      tokens: "tokens"
    }),

    sign() {
      return this.isRefill ? "+ " : "- ";
    },

    tokenId() {
      return _.find(this.tokens, { mnemonicEng: this.mnemonic })?.id;
    }
  },

  methods: {
    toToken() {
      this.$router.push({ name: "token info", params: { id: this.tokenId } });
    }
  }
};
</script>

<style scoped lang="scss">
.a_link {
  color: var(--text-color);
}

.t-image {
  width: 30px;
  height: 30px;
  border-radius: 40px;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
}
.transaction {
  display: flex;
  height: 35px;
  align-items: center;

  &-info {
    display: flex;
    justify-content: space-between;
    width: 100%;

    margin-left: 8px;

    &__type {
      color: var(--color-60);
    }

    &__card-type {
      color: var(--color-60);
    }

    &__amount[isRefill="true"] {
      color: #5ac396;
    }

    &__amount[isRefill="false"] {
      color: #e94545;
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-name {
        text-decoration: underline;
      }
    }

    &__left {
      align-items: flex-start;
    }

    &__right {
      align-items: flex-end;
    }
  }
}
</style>
