import StateControllerBase from "@/store/stateControllerBase";

import MessageController from "@/api/messageController";

const messageController = new MessageController();

class Actions extends StateControllerBase {
  async getMessages({ commit }, { page, itemsPerPage, userToId }) {
    const resp = await messageController.getMessages(page, itemsPerPage, userToId);
    commit("setMessages", resp.data);
    commit("setTotalMessages", resp.totalItems);
  }

  async postMessage(_context, body) {
    return await messageController.postMessage(body);
  }

  async setViewed(_context, id) {
    return await messageController.setViewed(id);
  }

  async getUnviewedMessageCount(context) {
    const resp = await messageController.getUnviewedMessageCount();
    context.commit("setUnviewedMessageCount", resp.data?.count);
  }
}

export default (new Actions).asPlainObject();