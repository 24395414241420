<template>
  <div class="panel">
    <div class="top-panel__logo">
      <div class="current-page">
        <!-- {{ $t(title) }} -->
      </div>
      <div class="logo">
        <div class="logo-wrap">
          <a href="/">
            SoluExchange
          </a>
        </div>
      </div>

      <ThemeSwitcher class="theme-switcher" />
    </div>

    <div class="sections">
      <RouterLink
        v-for="section in allSections"
        :key="section.name"
        :to="section.route"
        class="section"
      >
        {{ section.title }}
      </RouterLink>
    </div>

    <div class="sections-mobile">
      <RouterLink
        :to="allSections[0].route"
        class="section"
        @click="showMenu = false"
      >
        {{ allSections[0].title }}

        <div
          v-if="showMenu"
          v-click-outside="() => showMenu = false"
          class="sections-menu"
        >
          <RouterLink
            :active="isCurrent(allSections[1].title)"
            :to="allSections[1].route"
            class="section"
          >
            {{ allSections[1].title }}
          </RouterLink>

          <RouterLink
            :active="isCurrent(allSections[2].title)"
            :to="allSections[2].route"
            class="section"
          >
            {{ allSections[2].title }}
          </RouterLink>
        </div>
      </RouterLink>

      <AuIcon
        clickable
        class="sections-selector"
        icon="arrow-down"
        @click="showMenu = !showMenu"
      />
    </div>

    <div class="profile-item">
      <Profile />
    </div>
  </div>
</template>


<script>
import _ from "lodash";

import Profile from "./components/Profile.vue";
import ThemeSwitcher from "./components/ThemeSwitcher";

export default {
  components: {
    Profile,
    ThemeSwitcher
  },

  data() {
    return {
      showMenu: false
    };
  },

  computed: {
    allSections() {
      return [
        {
          title: this.$t("router.wallet"),
          name: "wallet",
          route: "/wallet"
        },
        {
          title: this.$t("router.exchange"),
          name: "exchange",
          route: "/exchange"
        },
        {
          title: this.$t("router.blockDex"),
          name: "blockDex",
          route: "/blockDex"
        }
      ];
    },

    path() {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      return _.get(parent, "path");
    }
  },

  methods: {
    isCurrent(path) {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      return parent.name == path;
    }
  }
};
</script>

<style scoped lang="scss">
.logo-wrap {
  margin-top: 20px;
  height: 50px;
  font-size: 20px;
  font-weight: normal;

  a {
    color: var(--text-color)
  }
}

.panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 25px;
  height: 67px;

  border-bottom: 1px solid var(--color-90);

  @media screen and (max-width:640px) {
    background: var(--card-color);
    height: 69px;

    border-color: var(--color-70);
  }
}

.current-page {
  display: block;
  font-size: 16px;

  @media screen and (max-width:1024px) {
    display: none;
  }
}

.logo {
  display: none;

  @media screen and (max-width:1024px) {
    display: block;
  }
}

.theme-switcher {
  display: none !important;

  @media screen and (max-width:640px) {
    display: flex !important;
  }
}

.top-panel__logo {
  display: flex;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
}

.section {
  font-size: 14px;
  text-decoration: none;
  color: var(--text-color);
  white-space: nowrap;

  &:hover {
    color: var(--color-60);
  }
}

.sections {
  display: flex;
  flex-direction: row;
  gap: 50px;

  @media screen and (max-width:1024px) {
    display: none;
  }

  &-mobile {
    display: none;
    position: relative;

    @media screen and (max-width:1024px) {
      display: block;
    }

    @media screen and (max-width:640px) {
      display: none;
    }
  }

  &-selector {
    margin-left: 10px;
  }

  &-menu {
    position: fixed;
    top: 60px;
    z-index: 100;
    background-color: var(--menu-color);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 17px;
  }
}

.router-link-active {
  color: #279063;
}
</style>