import axios from "axios";

export default class axiosClient {
  async postBlogPage(body) {
    await axios.post("/api/blog_pages", body);
  }

  async patchBlogPage(body) {
    const headers = {
      "Content-Type": "application/merge-patch+json"
    };
    await axios.patch(`api/blog_pages/${body.id}`, body, { headers });
  }

  async removeBlogPage(id) {
    await axios.delete(`api/blog_pages/${id}`);
  }

  async getBlogPages({ pageId = 1 }) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };

    const params = {
      page: pageId
    };

    const resp = await axios.get("api/blog_pages", { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }
}