<template>
  <div>
    <div class="ss-title">
      Управление счетами
    </div>
    <div class="ss-position">
      <div class="ss-start">
        <AuTable
          :columns="columns"
          :items="owners"
          :loading="loading"
        />
      </div>
      <div class="ss-center">
        <div class="d-flex justify-content-center">
          Списать средства со счета:
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <div
              :class="{ 'spot_checked': isCrypto }"
              class="ss-spot d-flex align-items-center"
              @click="isCrypto=true"
            >
              <div class="metamask" />
              <span>Крипто</span>
            </div>
            <div class="d-flex justify-content-center">
              <AuDecimalStatic
                v-if="tokenCrypto !== null"
                :value="tokenCrypto"
                :decimal="tokenOwner?.auToken?.decimals"
                :indent="tokenOwner?.auToken?.indent"
              />
            </div>
          </div>
          <div>
            <div
              :class="{ 'spot_checked': !isCrypto }"
              class="ss-spot d-flex align-items-center"
              @click="isCrypto=false"
            >
              <div class="spot_icon">
                <AuIcon icon="wallet" />
              </div>
              <span>Спотовый</span>
            </div>
            <div class="d-flex justify-content-center">
              <AuDecimalStatic
                v-if="tokenSpot !== null"
                :value="tokenSpot"
                :decimal="tokenOwner?.auToken?.decimals"
                :indent="tokenOwner?.auToken?.indent"
              />
            </div>
          </div>
        </div>
        <SwapInput
          v-model="swap"
          header="Обменять"
          :options="options"
        />
        <div class="d-flex justify-content-center mt-3">
          Зачислить средства на счет:
        </div>
        <div class="d-flex justify-content-between">
          <div
            :class="{ 'spot_checked': isCrypto }"
            class="ss-spot d-flex align-items-center"
            @click="isCrypto=true"
          >
            <div class="spot_icon">
              <AuIcon icon="wallet" />
            </div>
            <span>Спотовый</span>
          </div>
          <div
            :class="{ 'spot_checked': !isCrypto }"
            class="ss-spot d-flex align-items-center"
            @click="isCrypto=false"
          >
            <div class="metamask" />
            <span>Крипто</span>
          </div>
        </div>
        <div class="mt-5">
          <AuButton
            bordered
            border-radius="6"
            width="400px"
            center
            active-bg-color="#279063"
            @click="doSwap"
          >
            Перевести
          </AuButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment";
import SwapInput from "@/components/SwapInput";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SpotSwap",
  components: { SwapInput },
  data() {
    return {
      swap: {
        swapValue: "",
        swapToken: null,
        swapPrice: "",
        isActive: null
      },
      isCrypto: true,
      auOwners: null,
      loading: true,
      columns: [
        { name: "Название" },
        { name: "Всего" },
        { name: "Крипто-счет" },
        { name: "Спот (всего)" },
        { name: "В ордерах" }]
    };
  },
  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      exchangeBalances: "exchangeBalances"
    }),

    options() {
      return [...this.tokensFiat, ...this.tokensGold].map(token => {
        return {
          label: token.mnemonicEng,
          value: token.id,
          image: token.logoMain,
          name: token.nameEng
        };
      });
    },

    tokenOwner() {
      return this.auOwners.find(owner => owner.auToken.id == this.swap.swapToken);
    },

    tokenCrypto() {
      return this.swap.swapToken === null ? null : _.get(this.tokenOwner, "amountFree", 0);
    },

    tokenSpot() {
      return this.swap.swapToken === null ? null : _.get(
        _.find(this.exchangeBalances, item => item.token == `/api/au_tokens/${this.tokenOwner?.auToken?.id}`),
        "amount",
        0);
    },

    owners() {
      return _.map(this.auOwners, item => {
        const amount = BigNumber(item.amount).dividedBy(BigNumber(10).pow(item.auToken.decimals));
        const blocked = BigNumber(item.amountBlocked).dividedBy(BigNumber(10).pow(item.auToken.decimals));
        let spot = _.find(this.exchangeBalances, el => el.token == `/api/au_tokens/${item.auToken.id}`);
        spot = _.get(spot, "amount", 0);
        spot = BigNumber(spot).dividedBy(BigNumber(10).pow(item.auToken.decimals));
        const total = BigNumber(amount).plus(BigNumber(blocked).plus(spot));
        return { name: item.auToken.mnemonicEng,
          total: total,
          crypto: amount,
          spot: spot,
          blocked: blocked };
      });
    }
  },

  async mounted() {
    this.auOwners = await this.getAuOwnerByType({ type: "fiat,gold,utility" });
    this.loading = false;
  },

  methods: {
    ...mapActions({
      sendTokenEvents: "sendTokenEvents",
      getAuOwnerByType: "getAuOwnerByType"
    }),

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    async doSwap() {
      this.loading = true;
      const params = {
        token: `/api/au_tokens/${this.swap.swapToken}`,
        tokenQuote: `/api/au_tokens/${this.swap.swapToken}`,
        user: `/api/users/${this.$auth.user().id}`,
        createdAt: moment().format("DD.MM.YYYY hh:mm:ss"),
        type: this.isCrypto ? "token_block" : "token_unblock",
        description: "string",
        mode: "token_unblock",
        value: this.swap.swapValue,
        isTask: true
      };
      try {
        await this.sendTokenEvents(params);
        this.showSuccess("Ордер успешно создан");
      }
      catch (e) {
        console.log(e);
        this.showError("Ошибка при создании ордера");
      }
      this.loading = false;
    },

    getIriByName(name) {
      return `/api/au_tokens/${[...this.tokensFiat, ...this.tokensGold].find(item => item.nameEng == name).id}`;
    }
  }
};
</script>

<style scoped lang="scss">
.ss-title{
  font-weight: 600;
  font-size: 22px;
}
.ss-position{
  display: flex;
  justify-content: space-around;
  @media screen and (max-width:768px){
    flex-direction: column;
    align-items: center;
  }
}
.ss-center{
  width: min-content;
}
.ss-start{
  margin-top: 20px;
  @media screen and (min-width:768px) {
    width: 30%;
  }
}
.ss-spot{
  width: 180px;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 13px;
  color: var(--color-60);
  background: var(--color-90);
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
}
.spot_icon{
  margin-right: 5px;
}
.spot_checked{
  color: var(--text-color);
  background: var(--menu-color);
}
.metamask {
  background: url(../assets/img/metamask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  margin-right: 5px;
}
</style>