<template>
  <div
    :style="style"
    class="trade-block"
  >
    <div class="trade-block__exchange">
      <div class="exchange-rate">
        {{ block.sell.price }} / ${{ block.buy.price }}
      </div>
    </div>

    <div
      class="trade-block__sell trade-block__block"
      @click.native="showSellDialog=true"
    >
      <div class="trade-block__sell_remaining trade-block__remaining" />

      <div class="trade-block__title">
        Продать
      </div>

      <div class="trade-block__subtitle">
        ARB {{ block.sell.value }} / {{ sellLimit }}
      </div>
    </div>
    <div
      class="trade-block__buy trade-block__block"
      @click.native="showBuyDialog=true"
    >
      <div class="trade-block__buy_remaining trade-block__remaining" />

      <div class="trade-block__title">
        Купить
      </div>

      <div class="trade-block__subtitle">
        ETH {{ block.buy.value }} / {{ buyLimit }}
      </div>
    </div>

    <AuModal
      v-model="showBuyDialog"
      width="400px"
    >
      <template #header>
        <div class="modal_header">
          Покупка токена
        </div>
      </template>
      <template #body>
        <div class="modal_text_small">
          Вы собираетесь приобрести токен
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <AuButton
            bordered
            center
            width="80"
            @click="showBuyDialog=false"
          >
            Купить
          </AuButton>
          <AuButton
            bordered
            center
            width="80"
            @click="showBuyDialog=false"
          >
            Отмена
          </AuButton>
        </div>
      </template>
    </AuModal>

    <AuModal
      v-model="showSellDialog"
      width="400px"
    >
      <template #header>
        <div class="modal_header">
          Продажа токена
        </div>
      </template>
      <template #body>
        <div class="modal_text_small">
          Вы собираетесь продать токен
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <AuButton
            bordered
            center
            width="80"
            @click="showSellDialog=false"
          >
            Продать
          </AuButton>
          <AuButton
            bordered
            center
            width="80"
            @click="showSellDialog=false"
          >
            Отмена
          </AuButton>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    },
    sellLimit: {
      type: Number,
      required: true
    },
    buyLimit: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      showSellDialog: false,
      showBuyDialog: false
    };
  },

  computed: {
    style() {
      let sellRemaining = this.block.sell.value / this.sellLimit * 100;
      let buyRemaining = this.block.buy.value / this.buyLimit * 100;

      return {
        "--sell-remaining": sellRemaining + "%",
        "--buy-remaining": buyRemaining + "%"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.exchange-rate {
  line-height: 100%;
  color: var(--main-color);
  font-size: 14px;
  height: 26px;
  width: 114px;
  border-radius: 30px;
  padding: 6px 10px 6px 10px;
  z-index: 1;
  background-color: #FFFFFF;
}

.trade-block {
  display: flex;
  position: relative;

  &__exchange {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: top;
    height: 100%;
    width: 100%;
    margin-top: 15px;
  }

  &__block {
    width: 165px;
    height: 160px;

    @media screen and (max-width: 1024px) {
      width: 159px;
    }

    cursor: pointer;

    position: relative;
  }

  &__remaining {
    width: 100%;
    bottom: 0px;
    position: absolute;
  }

  &__sell {
    background-color: #FFB7AF;

    &_remaining {
      background-color: #BF4941;
      height: var(--sell-remaining);
    }
  }

  &__buy {
    background-color: #79C982;

    &_remaining {
      background-color: #3D8D46;
      height: var(--buy-remaining);
    }
  }

  &__title {
    font-size: 14px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    line-height: 100%;
    color: var(--main-color);
  }

  &__subtitle {
    font-size: 14px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: end;
    height: 100%;
    width: 100%;
    padding-bottom: 10px;
    line-height: 100%;
    color: var(--main-color);
  }
}

.modal_button {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
</style>