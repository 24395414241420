<template>
  <div class="top-panel">
    <div class="top-panel__info">
      <div class="top-panel__info-item">
        <img
          v-if="tokenFrom"
          :src="tokenFrom.logoMain"
        >

        <div class="fs16">
          <span
            class="token-button"
            @click="showTokenFromModal = true"
          >{{ tokenFrom ? tokenFrom.mnemonicEng : 'Выбрать' }}</span> – <span
            class="token-button"
            @click="showTokenToModal = true"
          >{{ tokenTo ? tokenTo.mnemonicEng : 'Выбрать' }}</span>
        </div>
      </div>

      <div class="top-panel__info-item">
        <div class="fs14 gray99">
          Все рынки
        </div>

        <AuIcon
          clickable
          icon="chevron_down-gray"
        />
      </div>
    </div>

    <AuModal
      v-model="showTokenFromModal"
      width="500px"
      height="400px"
    >
      <template #header>
        <span>
          Выбор валюты
        </span>
      </template>
      <template #body>
        <AuSelect
          :value="tokenIdFrom"
          :options="optionsFrom"
          track-by="value"
          label="label"
          :image="true"
          @input="value => {setTokenIdFrom(value); showTokenFromModal = false}"
        />
      </template>
    </AuModal>

    <AuModal
      v-model="showTokenToModal"
      width="500px"
      height="400px"
    >
      <template #header>
        <span>
          Выбор валюты
        </span>
      </template>
      <template #body>
        <AuSelect
          :value="tokenIdTo"
          :options="optionsTo"
          track-by="value"
          label="label"
          :image="true"
          @input="value => {setTokenIdTo(value); showTokenToModal = false}"
        />
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      swapValue: 0,
      percent: 0,
      showTokenFromModal: false,
      showTokenToModal: false
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      pairs: "pairs",
      tokenIdFrom: "tradePro/tokenIdFrom",
      tokenIdTo: "tradePro/tokenIdTo"
    }),

    tokenFrom() {
      return _.find(this.tokens, { id: this.tokenIdFrom });
    },

    tokenTo() {
      return _.find(this.tokens, { id: this.tokenIdTo });
    },

    pairToken() {
      return _.map(this.pairs, item => ({
        label: item.token.mnemonicEng,
        value: item.token.id,
        image: item.token.logoMain
      }));
    },

    pairTokenQuote() {
      return _.map(this.pairs, item => ({
        label: item.tokenQuote.mnemonicEng,
        value: item.tokenQuote.id,
        image: item.tokenQuote.logoMain
      }));
    },

    options() {
      let ret = [];
      _.forEach([...this.pairToken, ...this.pairTokenQuote], item => {
        if (!_.find(ret, el => _.isEqual(el, item))) {
          ret.push(item);
        }
      });
      return ret;
    },

    optionsFrom() {
      if (!this.tokenIdTo) {
        return this.options;
      }
      let bufSell = _.filter(this.pairs, pair => pair.tokenQuote.id === this.tokenIdTo);
      let bufBuy = _.filter(this.pairs, pair => pair.token.id === this.tokenIdTo);
      bufSell = _.map(bufSell, item => ({
        label: item.token.mnemonicEng,
        value: item.token.id,
        image: item.token.logoMain
      }));
      bufBuy = _.map(bufBuy, item => ({
        label: item.tokenQuote.mnemonicEng,
        value: item.tokenQuote.id,
        image: item.tokenQuote.logoMain
      }));
      return [...bufSell, ...bufBuy];
    },

    optionsTo() {
      if (!this.tokenIdFrom) {
        return this.options;
      }
      let bufSell = _.filter(this.pairs, pair => pair.token.id === this.tokenIdFrom);
      let bufBuy = _.filter(this.pairs, pair => pair.tokenQuote.id === this.tokenIdFrom);
      bufSell = _.map(bufSell, item => ({
        label: item.tokenQuote.mnemonicEng,
        value: item.tokenQuote.id,
        image: item.tokenQuote.logoMain
      }));
      bufBuy = _.map(bufBuy, item => ({
        label: item.token.mnemonicEng,
        value: item.token.id,
        image: item.token.logoMain
      }));
      return [...bufSell, ...bufBuy];
    }
  },

  methods: {
    ...mapActions({
      setTokenIdFrom: "tradePro/setTokenIdFrom",
      setTokenIdTo: "tradePro/setTokenIdTo"
    })
  }
};
</script>

<style lang="scss" scoped>
.top-panel {
  display: flex;
  flex-direction: column;

  &__info {
    width: 330px;
    height: 34px;
    border-right: 1px var(--color-90) solid;
    margin: 10px 0px;
    padding: 0px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.token-button {
 cursor: pointer;
}
</style>