<template>
  <div class="trading-leader">
    <AuIcon :icon="icon" />

    <div class="trading-leader-info">
      <div class="fs14 trading-leader-info__left">
        {{ name }}
      </div>

      <div class="trading-leader-info__right">
        <div class="fs14 trading-leader-info__percent">
          + {{ Math.abs(percent) }}
        </div>
        <Apexchart
          class="trading-leader-info__chart"
          :options="options"
          :series="series"
          height="18px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    values: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      series: [{
        name: "Изменение показателя",
        data: this.values
      }]
    };
  },

  computed: {
    formatedAmount() {
      let amount = this.amount.toLocaleString("ru", { style: "currency", currency: "RUB" });

      if (this.isRefill) {
        amount = "+ " + amount;
      }
      else {
        amount = "- " + amount;
      }

      amount = amount.replace(",00", "");

      return amount;
    },

    percent() {
      return Number(((this.values[this.values.length - 1] * 100 / this.values[this.values.length - 2]) - 100).toFixed(2));
    },

    currentColor() {
      if (this.percent > 0) {
        return "#5AC396";
      }

      return "#E94545";
    },

    options() {
      return {
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          width: 3,
          curve: "smooth"
        },
        chart: {
          type: "area",
          sparkline: {
            enabled: true
          },
          labels: {
            show: false
          }
        },
        title: {
          show: false
        },
        yaxis: {
          min: _.min(this.values) - _.sum(this.values) / 100,
          max: _.max(this.values) + _.sum(this.values) / 100,
          labels: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        colors: [this.currentColor]
      };
    }
  }
};
</script>

<style scoped lang="scss">
.trading-leader {
  display: flex;
  height: 35px;

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    margin-left: 8px;

    &__chart {
      width: 68px;
      margin-bottom: 10px;
    }

    &__left {
      align-items: flex-start;
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 10px
    }

    &__percent {
      color: var(--color-40);
    }
  }
}
</style>
