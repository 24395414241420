<template>
  <div class="left-panel panel">
    <div class="logo-panel">
      <div class="logo-wrap">
        <a href="/">
          SoluExchange
        </a>
      </div>
    </div>

    <div class="left-panel__panel">
      <Menu />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/menu/Menu.vue";

export default {
  components: {
    Menu
  },

  methods: {
    setRoute(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style scoped lang="scss">
.logo-wrap {
  margin-top: 20px;
  margin-left: 35px;
  height: 50px;
  font-size: 20px;

  @media screen and (max-width: 1200px) {
    margin-left: 20px;
  }

  a {
    color: var(--text-color)
  }
}

.logo-panel {
  width: 100%;
  height: 67px;

  border-bottom: 1px solid var(--color-90);
}

.left-panel {
  width: 210px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-right: 1px solid var(--color-90);

  @media screen and (max-width: 1200px) {
    width: 170px;
  }

  &__panel {
    margin: 18px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 160px;

    @media screen and (max-width: 1200px) {
      width: 150px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__card {
    width: 160px;
    height: 180px;

    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-radius: 13px;

    &-chard {
      margin-top: 8px;
    }

    &-value {
      position: absolute;
      top: 47px;
      font-size: 15px;
    }
  }

  &__help {
    margin-bottom: 96px;
  }
}
</style>
