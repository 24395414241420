<template>
  <div class="dashboard">
    <ExchangeRatesBottom />
    <ChangeChart />
    <div class="cards">
      <LastTransactions />
      <TradingLeaders />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ChangeChart from "../components/ChangeChart.vue";
import ExchangeRatesBottom from "../components/exchangeRates/bottom/ExchangeRates.vue";
import LastTransactions from "../components/lastTransactions/LastTransactions.vue";
import TradingLeaders from "../components/tradingLeaders/TradingLeaders.vue";

export default {
  components: {
    ExchangeRatesBottom,
    TradingLeaders,
    LastTransactions,
    ChangeChart
  },

  data() {
    return {
      loadedTopCharts: false,
      loadedBottomCharts: false
    };
  },

  computed: {
    chartsLoaded() {
      return this.loadedTopCharts && this.loadedBottomCharts;
    },

    pages() {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      if (parent) {
        return _.filter(parent.children, child => _.get(child, "meta.isHelp") == true);
      }
      else {
        return [];
      }
    }
  },

  methods: {
    setRoute(page) {
      this.$router.push({ name: page.name });
    },

    loadedTop() {
      this.loadedTopCharts = true;
    },

    loadedBottom() {
      this.loadedBottomCharts = true;
    }
  }
};
</script>

<style scoped lang="scss">
.manager-panel {
  margin-bottom: 30px;
}

.cards {
  display: flex;
  gap: 20px;

  @media screen and (max-width: 576px)  {
    flex-direction: column;
  }
}

.dashboard {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 768px)  {
    gap: 40px;
  }
}
</style>
