<template>
  <div class="sm-main">
    <div class="sm-title">
      <span>
        Структура рынка
      </span>
    </div>

    <div class="sm-dom col-2">
      <div>
        Выберите пару
      </div>

      <div class="mb-4 mt-2">
        <AuSelect
          :value="selectedPair"
          :options="options"
          label="label"
          @input="changePair"
        />
      </div>
      <div class="d-flex justify-content-between">
        <div class="col-5">
          <span class="sm-header sm-sale">Sale</span>
          <AuTable
            :columns="columns"
            :loading="loading"
            :items="sell"
          />
        </div>
        <div class="col-5">
          <span class="sm-header sm-buy">Buy</span>
          <AuTable
            :columns="columns"
            :loading="loading"
            :items="buy"
          />
        </div>
      </div>
      <!--<div>
        <DepthOfMarket
          :buy="buy"
          :sell="sell"
          :price="price"
          :last-price="lastPrice"
          width="100%"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "StateOfMarket",

  data() {
    return {
      loading: false,
      selectedPair: null,
      buy: null,
      sell: null,
      price: 0,
      lastPrice: 0,
      columns: [{ name: "Price" },
        { name: "Trading volume" }]
    };
  },

  computed: {
    ...mapGetters({
      pairs: "pairs"
    }),

    options() {
      return _.map(this.pairs, pair => ({
        label: `${pair.token.mnemonicEng}/${pair.tokenQuote.mnemonicEng}`,
        value: pair.id
      }));
    }
  },

  async mounted() {
    if (_.isEmpty(this.pairs)) {
      this.loading = true;
      await this.getPairs();
      this.loading = false;
    }
  },

  methods: {
    ...mapActions({
      getPairs: "getPairs",
      getOrderBook: "getOrderBook",
      getTrades: "getTrades"
    }),

    async changePair(value) {
      this.selectedPair = value;
      this.loading = true;
      const pair = _.find(this.pairs, item => item.id == this.selectedPair);
      const tokenDecimal = pair.token.decimals;
      const tokenQuoteDecimal = pair.tokenQuote.decimals;
      const orderData = await this.getOrderBook({ pairId: this.selectedPair });
      const priceData = await this.getTrades({ pairId: this.selectedPair });
      this.loading = false;
      this.buy = _.map(orderData.buy, item => ({
        price: BigNumber(item.price).dividedBy(BigNumber(10).pow(tokenQuoteDecimal)).toString(10),
        size: BigNumber(item.size).dividedBy(BigNumber(10).pow(tokenDecimal)).toString(10)
      }));
      this.sell = _.map(orderData.sell, item => ({
        price: BigNumber(item.price).dividedBy(BigNumber(10).pow(tokenQuoteDecimal)).toString(10),
        size: BigNumber(item.size).dividedBy(BigNumber(10).pow(tokenDecimal)).toString(10)
      }));
      this.price = _.get(priceData, "at(-1).price", 0);
      this.lastPrice = _.get(priceData, "at(-2).price", 0);
    }
  }
};
</script>

<style scoped lang="scss">
.sm-main{
  height: 100vh;
}
.sm-title{
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}
.sm-header{
  font-weight: 450;
  font-size: 12px;
}
.sm-buy{
  color: green;
}
.sm-sale {
  color: red;
}
.sm-dom{
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  border: 1px solid var(--color-70);
  width: 400px;
}
</style>
