<template>
  <div class="card test_page">
    <div class="card__header">
      <div class="fs24">
        {{ $t("TestPage") }}
      </div>

      <div class="filters">
        <div class="filter-buttons">
          <AuSwitchButton
            :active="isAll"
            width="66px"
            border-radius="8"
            :label="$t('transactions.allPlaceholder')"
            @click="cleanFilters"
          >
            Все
          </AuSwitchButton>

          <AuSelect
            :value="cryptoAssetId"
            class="crypto-asset-select"
            :options="tokensOptions"
            :placeholder="$t('transactions.cryptoAssetPlaceholder')"
            @input="changeСryptoAssetId"
          />

          <Datepicker
            v-model="date"
            locale="ru"
            auto-apply
            :max-date="new Date()"
            :enable-time-picker="false"
            :format="format"
            :placeholder="$t('transactions.datePickerPlaceholder')"
            range
            :dark="!isLightTheme"
            input-class-name="dp-custom"
          />
        </div>

        <AuSelect
          :value="type"
          class="type-select"
          :options="typeArray"
          :placeholder="$t('transactions.transactionType.placeholder')"
          @input="(value) => (type = value)"
        />
      </div>

      <div class="card__header-line">
        <div class="card__header-date">
          <span>{{ typeTitle }} </span>
          <span class="card__header-month"> {{ dateTitle }} </span>
        </div>

        <div class="card__action">
          <div class="fs14">
            {{ spending }}
          </div>
          <AuButton
            :icon="isDetailView ? 'chevron_up' : 'chevron_down'"
            @click="switchView"
          />
        </div>
      </div>
    </div>

    <div
      v-if="isDetailView"
      class="transactions-info"
    >
      <div class="transactions-info__chart">
        <AuButton icon="chevron_left_big" />

        <Apexchart
          type="donut"
          :options="options"
          :series="series"
          height="200px"
          width="200px"
        />

        <AuButton icon="chevron_right_big" />
      </div>

      <div class="transactions-info__cards">
        <TransactionCard
          v-for="(card, index) in cards"
          :key="index"
          :name="card.name"
          :value="getFormatedValue(card.value)"
          :color="card.color"
        />
      </div>
    </div>
    <div class="transactions-list">
      <div
        v-for="(group, groupIndex) in filtered"
        :key="groupIndex"
        class="transactions-group"
      >
        <div class="period">
          {{ group.date }}
        </div>

        <div class="transactions">
          <LastTransaction
            v-for="(transaction, index) in group.transactions"
            :key="index"
            class="transaction"
            :icon="transaction.icon"
            :name="transaction.name"
            :transaction-type="transaction.type"
            :amount="transaction.amount"
            :is-refill="transaction.isRefill"
            :card-type="transaction.cardType"
            :decimals="transaction.decimals"
            :mnemonic="transaction.mnemonic"
            :hash="transaction.hash"
            :address-eth="transaction.addressEth"
            :address-from="transaction.addressFrom"
            :address-to="transaction.addressTo"
          />
        </div>
      </div>
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />

    <div class="transactions-footer">
      <AuButton
        height="42px"
        font-size="12px"
        bordered
        center
        @click="getMore"
      >
        {{ $t("transactions.moreButton") }}
      </AuButton>
    </div>
    <AuPagination
      v-if="totalItems > 1"
      :total-items="totalItems"
      @pageChanged="pageChanged"
    />
    <div class="tes1">
      1
    </div>
    <div class="tes2">
      2
    </div>
    <div class="tes3">
      3
    </div>
  </div>
</template>

<script>
import LastTransaction from "@/components/lastTransactions/LastTransaction.vue";
import ProgressLoader from "@/loaders/progress-bar";
import TransactionCard from "@/components/lastTransactions//TransactionCard.vue";

import _ from "lodash";
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "TestPage",
  components: {
    LastTransaction,
    TransactionCard,
    Datepicker,
    ProgressLoader
  },
  data() {
    return {
      isDetailView: false,
      addressEth: null,
      cards: [
        { value: 2164927, name: "Переводы", color: "#489EE1" },
        { value: 679608.75, name: "Инвестиции", color: "#E2A64D" },
        { value: 95020, name: "Товары", color: "#299667" },
        { value: 679382.75, name: "Биржа", color: "#9720F6" }
      ],
      transactions: [],
      page: 1,
      totalItems: 1,
      date: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date()
      ],
      cryptoAssetId: null,
      cryptoAsset: null,
      type: "all",
      loading: false,
      typeArray: [
        { display: this.$t("transactions.transactionType.all"), value: "all" },
        {
          display: this.$t("transactions.transactionType.receipts"),
          value: "receipts"
        },
        {
          display: this.$t("transactions.transactionType.spending"),
          value: "spending"
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      arrEth: "ethAddresses"
    }),

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    typeTitle() {
      return this.$t("transactions.titleType.spending");
    },

    sum() {
      return _.chain(this.filtered)
        .flatMap("transactions")
        .map((v) => {
          let res = Number(v.amount) / Math.pow(10, v.decimals);
          res = v.isRefill ? res : -1 * res;
          return res;
        })
        .sum()
        .value();
    },

    dateTitle() {
      let dateTitle = "всё время";
      if (this.date) {
        dateTitle = this.format(this.date);
      }
      return dateTitle;
    },

    tokensOptions() {
      return _.map(this.tokens, (token) => ({
        display: token.nameEng,
        value: token.id
      }));
    },

    isAll() {
      return (
        this.type == "all" && this.cryptoAsset == null && this.date == null
      );
    },

    filtered() {
      if (this.isAll) {
        return this.transactions;
      }

      let res = _.cloneDeep(this.transactions);
      if (this.cryptoAsset !== null) {
        res.forEach((item) => {
          item.transactions = _.filter(item.transactions, {
            mnemonic: this.cryptoAsset.mnemonicEng
          });
        });
        res = _.reject(res, (item) => _.isEmpty(item.transactions));
      }

      if (this.type !== "all") {
        res.forEach((item) => {
          if (this.type == "receipts") {
            item.transactions = _.filter(item.transactions, { isRefill: true });
          }
          else {
            item.transactions = _.filter(item.transactions, {
              isRefill: false
            });
          }
        });
        res = _.reject(res, (item) => _.isEmpty(item.transactions));
      }

      if (this.date !== null) {
        const day1 = this.date[0].getDate();
        const month1 = this.date[0].getMonth() + 1;
        const year1 = this.date[0].getFullYear();

        const day2 = this.date[1].getDate();
        const month2 = this.date[1].getMonth() + 1;
        const year2 = this.date[1].getFullYear();

        if (year1 == year2 && month1 == month2 && day1 == day2) {
          res = _.filter(res, (item) => {
            return (
              moment(item.date, "DD.MM.YYYY").format("DD.MM.YYYY").toString() ==
              moment(this.date[0]).format("DD.MM.YYYY").toString()
            );
          });
        }
        else {
          res = _.filter(res, (item) => {
            return (
              moment(item.date, "DD.MM.YYYY").format("DD").toString() >=
                moment(this.date[0]).format("DD").toString() &&
              moment(item.date, "DD.MM.YYYY").format("MM").toString() >=
                moment(this.date[0]).format("MM").toString() &&
              moment(item.date, "DD.MM.YYYY").format("YYYY").toString() >=
                moment(this.date[0]).format("YYYY").toString() &&
              moment(item.date, "DD.MM.YYYY").format("DD").toString() <=
                moment(this.date[1]).format("DD").toString() &&
              moment(item.date, "DD.MM.YYYY").format("MM").toString() <=
                moment(this.date[1]).format("MM").toString() &&
              moment(item.date, "DD.MM.YYYY").format("YYYY").toString() <=
                moment(this.date[1]).format("YYYY").toString()
            );
          });
        }
        res = _.reject(res, (item) => _.isEmpty(item.transactions));
      }
      return res;
    },

    spending() {
      return this.getFormatedValue(this.sum);
    },

    series() {
      return _.map(this.cards, "value");
    },

    options() {
      return {
        labels: _.map(this.cards, "name"),
        pie: {
          expandOnClick: false
        },
        colors: _.map(this.cards, "color"),
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return value
                .toLocaleString("ru", { style: "currency", currency: "RUB" })
                .replace(",00", "");
            }
          }
        },
        legend: {
          show: false
        }
      };
    },

    ourEth() {
      return _.find(this.arrEth, (eth) => !_.isNil(eth.verifiedAt))?.value;
    }
  },

  async mounted() {
    await this.getTransactions();
    console.log("tokensTest", this.tokens);
    console.log("arrEthTest", this.arrEth);
  },

  methods: {
    ...mapActions({
      getTransactionsPageTest: "getTransactionsPageTest"
    }),

    async pageChanged(actualPage) {
      this.page = actualPage;
      this.transactions = [];
      await this.getTransactions();
    },

    switchView() {
      this.isDetailView = !this.isDetailView;
    },

    cleanFilters() {
      this.cryptoAssetId = null;
      this.cryptoAsset = null;
      this.date = null;
      this.type = "all";
    },

    getFormatedValue(value) {
      return value
        .toLocaleString("ru", { style: "currency", currency: "RUB" })
        .replace(",00", "");
    },

    format(date) {
      const day1 = date[0].getDate();
      const month1 = date[0].getMonth() + 1;
      const year1 = date[0].getFullYear();

      const day2 = date[1].getDate();
      const month2 = date[1].getMonth() + 1;
      const year2 = date[1].getFullYear();

      if (year1 == year2 && month1 == month2 && day1 == day2) {
        return `${moment(date[0]).locale("ru").format("L")}`;
      }

      if (
        year1 == year2 &&
        month1 == month2 &&
        day1 == 1 &&
        (day2 == new Date(year2, month2, 0).getDate() ||
          day2 == new Date().getDate())
      ) {
        let month = moment(date[0]).locale("ru").format("MMMM");
        return month[0].toUpperCase() + month.slice(1) + " " + year1;
      }

      if (
        year1 == year2 &&
        month1 == 1 &&
        day1 == 1 &&
        month2 == 12 &&
        day2 == 31
      ) {
        return year1;
      }

      return `${moment(date[0]).locale("ru").format("L")} - ${moment(date[1])
        .locale("ru")
        .format("L")}`;
    },

    changeСryptoAssetId(value) {
      this.cryptoAssetId = value;
      if (value) {
        this.cryptoAsset = _.find(this.tokens, { id: value });
      }
    },

    async getTransactions() {
      this.loading = true;

      let resp = await this.getTransactionsPageTest({
        pageId: this.page,
        adr: this.ourEth
      });
      let transfer = resp.data;
      this.totalItems = resp.totalItems;
      if (transfer.length === 0) {
        this.loading = false;
        return;
      }
      transfer.forEach((transaction) => {
        const time = moment(transaction.createdAt).format("DD.MM.YYYY");
        let records = this.transactions.find(
          (el) => el.date == time
        )?.transactions;
        if (records === undefined) {
          this.transactions.push({
            date: time,
            sort: transaction.createdAt,
            transactions: []
          });
          records = this.transactions.at(-1).transactions;
        }
        this.arrEth.forEach((eth) => {
          if (
            _.lowerCase(eth.value) === _.lowerCase(transaction?.addressFrom)
          ) {
            this.addressEth = transaction?.addressTo;
          }
          if (_.lowerCase(eth.value) === _.lowerCase(transaction?.addressTo)) {
            this.addressEth = transaction?.addressFrom;
          }
          if (transaction?.addressFrom == 0) {
            this.addressEth = transaction?.addressTo;
          }
        });
        records.push({
          icon: _.get(transaction, "auToken.logoMain", "/img/eth.svg"),
          name: _.isNil(this.addressEth)
            ? "Не определено"
            : this.addressEth.slice(0, 4) + "..." + this.addressEth.slice(-4),
          type: transaction.addressFrom == 0 ? "Эмиссия" : "Перевод",
          addressFrom: _.get(transaction, "addressFrom", "-"),
          addressTo: _.get(transaction, "addressTo", "-"),
          addressEth: this.addressEth,
          amount: _.get(transaction, "amount", null),
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill: this.ourEth == transaction?.addressTo,
          decimals: _.get(transaction, "auToken.decimals", 2),
          mnemonic: _.get(transaction, "auToken.mnemonicEng", "-"),
          createdAt: _.get(transaction, "createdAt", "-"),
          hash: _.get(transaction, "hash", "-")
        });
      });

      this.loading = false;
    },

    async getMore() {
      this.page++;
      await this.getTransactions();
    }
  }
};
</script>

<style scoped lang="scss">
.period {
  margin: 20px 0px;
  font-size: 12px;
  color: var(--color-60);
}

.crypto-asset-select {
  min-width: 220px;
}
.type-select {
  width: 172px;
}

.date-input {
  width: 175px;
}
.transactions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &-footer {
    margin-top: 40px;
    padding-top: 20px;
    display: flex;
    justify-content: center;

    border-top: 1px dashed var(--color-70);
  }

  &-info {
    display: flex;
    border-bottom: 1px solid var(--color-70);
    padding: 12px 0px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }

    &__chart {
      display: flex;
      align-items: center;
      margin-left: -17px;
      margin-right: 40px;

      @media screen and (max-width: 576px) {
        justify-content: space-between;
      }
    }

    &__cards {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      padding: 50px 0px;
    }
  }
}

.card {
  width: 100%;
  padding: 12px 22px 22px 20px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 40px;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 25px;

    &-date {
      font-size: 14px;
    }

    &-line {
      display: flex;
      justify-content: space-between;
    }

    &-month {
      color: #279063;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: -15px;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
}

.filter-buttons {
  display: flex;
  gap: 10px;
}
.tes1 {
  padding: 4px;
  color: var(--text-color);
  background: var(--color-40);
}
.tes2 {
  padding: 4px;
  color: var(--text-color);
  background: var(--color-60);
}
.tes3 {
  padding: 4px;
  color: var(--text-color);
  background: var(--color-80);
}
</style>
