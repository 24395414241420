<template>
  <div class="swap-panel-wrapper">
    <div class="block-place">
      <div class="left-block">
        <span>
          SoluExchange SWAP
        </span>
      </div>
      <div>
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div class="d-flex justify-content-between">
            <div
              class="swap-tab"
              :class="{'checked_tab': isMarket}"
              @click="isMarket=true"
            >
              <span class="history-text">{{ $t("swap.tabs.market") }}</span>
            </div>
            <div
              class="swap-tab"
              :class="{'checked_tab': !isMarket}"
              @click="isMarket=false"
            >
              <span class="history-text">{{ $t("swap.tabs.limit") }}</span>
            </div>
          </div>
        </div>
        <div class="swap-wrapper">
          <SwapInput
            v-model="swapFrom"
            :header="$t('swap.swapInput.youPay')"
            :options="optionFrom"
            :label="$t('swap.swapInput.value')"
            :show-price="!isMarket"
            :token-quote="modeOrder === 'sale' ? swapTo.swapToken : swapFrom.swapToken"
            :token-spot="tokenSpot"
            :token-owner="tokenOwner"
          />
          <div>
            <div class="d-flex justify-content-center">
              <div
                class="circle"
                @click="changeObject"
              >
                <AuIcon
                  icon="swap"
                  @click="changeObject"
                />
              </div>
            </div>
            <div class="d-flex justify-content-center course-text mb-4">
              {{ $t("swap.swapInput.rate") }} {{ course }}
            </div>
          </div>
          <SwapInput
            v-model="swapTo"
            faded
            :label="$t('swap.swapInput.approximately')"
            :header="$t('swap.swapInput.youGet')"
            :options="optionTo"
            :token-spot="tokenQuoteSpot"
            :token-owner="tokenQuoteOwner"
          />
        </div>
        <div class="btn-indent">
          <AuButton
            bordered
            border-radius="6"
            width="400px"
            center
            active-bg-color="#279063"
            :disabled="isZeroPrice"
            @click="showAdrDialog"
          >
            {{ $t("swap.swapInput.exchangeButton") }}
          </AuButton>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div
          class="right-block mb-4"
          @click="goToTradeHistory"
        >
          <span class="history-text">
            {{ $t("swap.buttons.history") }}
          </span>
          <AuIcon icon="arrow-right" />
        </div>
        <div
          class="right-block mb-4"
          @click="goToOrderHistory"
        >
          <span class="history-text">
            {{ $t("swap.buttons.orders") }}
          </span>
          <AuIcon icon="arrow-right" />
        </div>
        <div
          class="right-block mb-4"
          @click="goToSpot"
        >
          <span class="history-text">
            {{ $t("swap.buttons.accountManagement") }}
          </span>
          <AuIcon icon="arrow-right" />
        </div>
        <div
          class="right-block"
          @click="goToSOM"
        >
          <span class="history-text">
            {{ $t("swap.buttons.marketStructure") }}
          </span>
          <AuIcon icon="arrow-right" />
        </div>
      </div>
    </div>
    <div class="question">
      <div class="question_header">
        {{ $t("swap.faq.label") }}
      </div>
      <div
        v-for="item in questions"
        :key="item.question"
        class="quest"
      >
        <div
          class="d-flex justify-content-between question-indent"
          @click="showHide(item)"
        >
          <span class="history-text">{{ item.question }}</span>
          <div>
            <AuIcon
              v-if="item.show"
              icon="minus"
            />
            <AuIcon
              v-else
              icon="plus"
            />
          </div>
        </div>
        <div
          v-if="item.show"
          class="history-text answear"
        >
          {{ item.answear }}
        </div>
      </div>
    </div>
    <AuModal
      v-model="adrDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          Выберете адрес
        </span>
      </template>
      <template #body>
        <span>
          Выберете адрес для завершения операции
        </span>
        <AuSelect
          :value="ethAdr"
          :options="ethOptions"
          label="value"
          @input="v => ethAdr = v"
        />
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="checkSwap"
            >
              SWAP
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="adrDialog=false"
            >
              Отмена
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment/moment";
import SwapInput from "@/components/SwapInput";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Swap",
  components: { SwapInput },

  data() {
    return {
      adrDialog: false,
      ethAdr: null,
      isMarket: true,
      isBuy: true,
      isCryptoToken: true,
      isCryptoTokenQuote: true,
      course: null,
      swapFrom: {
        swapValue: "",
        swapToken: null,
        swapPrice: "",
        isActive: null
      },
      swapTo: {
        swapValue: "",
        swapToken: null,
        swapPrice: "",
        isActive: null
      },
      questions: [
        {
          question: "1. Устанавливаются ли какие-либо торговые комиссии? ",
          answear: "При использовании SoluExchange SWAP комиссии не взимаются. " +
              "В режиме «Рынок» котировки обновляются в режиме реального времени в зависимости от текущих рыночных условий. " +
              "Указанная сумма активов — это фактическая сумма активов, которую вы получите на свой счет при совершении сделки. " +
              "В режиме «Лимит», когда ваш ордер исполняется по указанной лимитной цене, актив будет конвертирован по этой цене.",
          show: true
        },
        {
          question: "2. Каковы преимущества использования SoluExchange SWAP и как начать работу с платформой?",
          answear: "-",
          show: false
        },
        {
          question: "3. Каковы условия использования портала?",
          answear: "-",
          show: false
        },
        {
          question: "4. Каковы минимальные и максимальные суммы сделок?",
          answear: "-",
          show: false
        },
        {
          question: "5. Как осуществляется расчет?",
          answear: "-",
          show: false
        },
        {
          question: "6. Как вносить и выводить средства?",
          answear: "-",
          show: false
        },
        {
          question: "7. Что делать, если возникла ошибка «Недостаточно средств»?",
          answear: "-",
          show: false
        },
        {
          question: "8. В чем разница между режимом «Рынок» и режимом «Стоп-лимит»?",
          answear: "-",
          show: false
        },
        {
          question: "9. Почему цена котировки отличается от цены последней сделки на бирже?",
          answear: "-",
          show: false
        },
        {
          question: "10. Где я могу посмотреть свою историю сделок?",
          answear: "-",
          show: false
        },
        {
          question: "11. Могу ли я обменять «старые» версии токенов, которые подверглись миграции, на SoluExchange SWAP?",
          answear: "-",
          show: false
        },
        {
          question: "12. Включаются ли сделки по конвертации в объем спотовых сделок для расчета VIP-программы?",
          answear: "-",
          show: false
        },
        {
          question: "13. Сколько токенов и торговых пар поддерживает SoluExchange SWAP?",
          answear: "-",
          show: false
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      ethAddresses: "ethAddresses",
      auOwners: "auOwners",
      pairs: "pairs",
      exchangeBalances: "exchangeBalances"
    }),

    pairToken() {
      return _.map(this.pairs, item => ({
        label: item.token.mnemonicEng,
        value: item.token.id,
        image: item.token.logoMain
      }));
    },

    pairTokenQuote() {
      return _.map(this.pairs, item => ({
        label: item.tokenQuote.mnemonicEng,
        value: item.tokenQuote.id,
        image: item.tokenQuote.logoMain
      }));
    },

    modeOrder() {
      return _.find(this.pairToken, option => option.value === this.swapFrom.swapToken) ? "sale" : "buy";
    },

    option() {
      let ret = [];
      _.forEach([...this.pairToken, ...this.pairTokenQuote], item => {
        if (!_.find(ret, el => _.isEqual(el, item))) {
          ret.push(item);
        }
      });
      return ret;
    },

    tokenOwner() {
      return this.auOwners.find(owner => owner.auToken.id == this.swapFrom.swapToken);
    },

    tokenQuoteOwner() {
      return this.auOwners.find(owner => owner.auToken.id == this.swapTo.swapToken);
    },

    tokenCrypto() {
      return _.get(this.tokenOwner, "amountFree", null);
    },

    tokenSpot() {
      return _.get(
        _.find(this.exchangeBalances, item => item.token == `/api/au_tokens/${this.tokenOwner?.auToken?.id}`),
        "amount",
        null);
    },

    tokenQuoteSpot() {
      return _.get(
        _.find(this.exchangeBalances, item => item.token == `/api/au_tokens/${this.tokenQuoteOwner?.auToken?.id}`),
        "amount",
        null);
    },

    tokenQuoteCrypto() {
      return _.get(this.tokenQuoteOwner, "amountFree", null);
    },

    ethOptions() {
      return this.ethAddresses.map(adr => {
        return {
          value: adr.value
        };
      });
    },

    isZeroPrice() {
      return !this.isMarket && this.swapFrom.swapPrice == 0;
    },

    optionFrom() {
      if (_.isNull(this.swapTo.swapToken)) {
        return this.option;
      }
      let bufSell = _.filter(this.pairs, pair => pair.tokenQuote.id === this.swapTo.swapToken);
      let bufBuy = _.filter(this.pairs, pair => pair.token.id === this.swapTo.swapToken);
      bufSell = _.map(bufSell, item => ({
        label: item.token.mnemonicEng,
        value: item.token.id,
        image: item.token.logoMain,
        name: item.token.nameEng
      }));
      bufBuy = _.map(bufBuy, item => ({
        label: item.tokenQuote.mnemonicEng,
        value: item.tokenQuote.id,
        image: item.tokenQuote.logoMain,
        name: item.tokenQuote.nameEng
      }));
      return [...bufSell, ...bufBuy];
    },

    optionTo() {
      if (_.isNull(this.swapFrom.swapToken)) {
        return this.option;
      }
      let bufSell = _.filter(this.pairs, pair => pair.token.id === this.swapFrom.swapToken);
      let bufBuy = _.filter(this.pairs, pair => pair.tokenQuote.id === this.swapFrom.swapToken);
      bufSell = _.map(bufSell, item => ({
        label: item.tokenQuote.mnemonicEng,
        value: item.tokenQuote.id,
        image: item.tokenQuote.logoMain,
        name: item.tokenQuote.nameEng
      }));
      bufBuy = _.map(bufBuy, item => ({
        label: item.token.mnemonicEng,
        value: item.token.id,
        image: item.token.logoMain,
        name: item.token.nameEng
      }));
      return [...bufSell, ...bufBuy];
    },

    decimalQuote() {
      const ret = _.find(this.pairs, pair => pair?.token?.id == this.swapTo.swapToken || pair?.tokenQuote?.id == this.swapTo.swapToken);
      return ret?.token?.id == this.swapTo.swapToken ? ret?.token?.decimals : ret?.tokenQuote?.decimals;
    },

    decimalToken() {
      const ret = _.find(this.pairs, pair => pair?.token?.id == this.swapFrom.swapToken || pair?.tokenQuote?.id == this.swapFrom.swapToken);
      return ret?.token?.id == this.swapFrom.swapToken ? ret?.token?.decimals : ret?.tokenQuote?.decimals;
    }
  },

  watch: {
    async swapFrom(swapNew, swapOld) {
      if (swapNew.swapToken !== swapOld.swapToken) {
        this.course = BigNumber(await this.getRate()).toFixed(this.decimalQuote, 0);
      }

      const clearPrice = this.isMarket ? this.course :
        (BigNumber(this.swapFrom.swapPrice).dividedBy(BigNumber(10).pow(this.decimalQuote))).toString(10);
      const clearValue = BigNumber(this.swapFrom.swapValue).dividedBy(BigNumber(10).pow(this.decimalToken));

      const intermediateValue = this.modeOrder === "buy" ? clearValue.dividedBy(clearPrice) : clearValue.multipliedBy(clearPrice);
      this.swapTo.swapValue = intermediateValue.multipliedBy(BigNumber(10).pow(this.decimalQuote)).toFixed(2);
    },

    async swapTo(swapNew, swapOld) {
      if (swapNew.swapToken !== swapOld.swapToken) {
        this.course = BigNumber(await this.getRate()).toFixed(this.decimalQuote, 0);

        const clearPrice = this.isMarket ? this.course :
          (BigNumber(this.swapFrom.swapPrice).dividedBy(BigNumber(10).pow(this.decimalQuote))).toString(10);
        const clearValue = BigNumber(this.swapFrom.swapValue).dividedBy(BigNumber(10).pow(this.decimalToken));

        const intermediateValue = this.modeOrder === "buy" ? clearValue.dividedBy(clearPrice) : clearValue.multipliedBy(clearPrice);
        this.swapTo.swapValue = intermediateValue.multipliedBy(BigNumber(10).pow(this.decimalQuote)).toFixed(2);
      }
      if (this.swapTo.isActive) {
        const buf = BigNumber(this.swapTo.swapValue);
        this.swapFrom.swapValue = buf.dividedBy(this.course).toString(10);
      }
    }
  },

  async mounted() {
    if (_.isEmpty(this.pairs)) {
      await this.getPairs();
    }
    if (!_.isEmpty(this.optionFrom)) {
      this.swapFrom.swapToken = this.optionFrom[0].value;
    }
    if (!_.isEmpty(this.optionTo)) {
      this.swapTo.swapToken = this.optionTo[0].value;
    }
  },

  methods: {
    ...mapActions({
      sendTokenEvents: "sendTokenEvents",
      getCourse: "getCourse",
      getPairs: "getPairs"
    }),

    showHide(q) {
      q.show = !q.show;
    },

    changeObject() {
      [this.swapTo, this.swapFrom] = [this.swapFrom, this.swapTo];
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    getIriByName(name) {
      return `/api/au_tokens/${[...this.tokensFiat, ...this.tokensGold].find(item => item.nameEng == name).id}`;
    },

    checkSwap() {
      this.adrDialog = false;
      if (this.swapTo.swapToken === null || this.swapFrom.swapToken === null) {
        this.showError("Выберите токены для совершения сделки");
        return;
      }
      if (this.swapFrom.swapValue == 0) {
        this.showError("Суммы токенов не должны быть нулевыми");
        return;
      }
      if (this.ethAdr === null) {
        this.showError("Выберите корректный адрес");
        return;
      }
      this.doSwap();
    },

    async doSwap() {
      const price = BigNumber(this.swapFrom.swapPrice).toFixed(0, 4);
      const params = {
        token: `/api/au_tokens/${this.swapFrom.swapToken}`,
        tokenQuote: `/api/au_tokens/${this.swapTo.swapToken}`,
        user: `/api/users/${this.$auth.user().id}`,
        createdAt: moment().format("DD.MM.YYYY HH:mm:ss"),
        type: this.isMarket ? "token_order_market_create" : "token_order_limit_create",
        description: this.modeOrder,
        mode: this.modeOrder,
        priceString: this.isMarket ? "" : price,
        address: this.isCryptoToken ? this.ethAdr : null,
        isTask: true
      };
      if (this.modeOrder === "buy") {
        [params.token, params.tokenQuote] = [params.tokenQuote, params.token];
      }
      if (params.type === "token_order_limit_create") {
        if (this.modeOrder === "sale") {
          params.value = this.swapFrom.swapValue;
        }
        else {
          const clearValue = BigNumber(this.swapFrom.swapValue).dividedBy(BigNumber(10).pow(this.decimalToken));
          const pow = this.modeOrder === "sale" ? this.decimalQuote : this.decimalToken;
          const clearPrice = BigNumber(price).dividedBy(BigNumber(10).pow(pow));
          params.value = ((clearValue.dividedBy(clearPrice)).multipliedBy(BigNumber(10).pow(this.decimalQuote))).toFixed(0);
        }
      }
      else {
        if (this.modeOrder === "sale") {
          params.value = this.swapFrom.swapValue;
        }
        else {
          params.valueQuote = this.swapFrom.swapValue;
        }
      }
      try {
        await this.sendTokenEvents(params);
        this.showSuccess("Ордер успешно создан");
      }
      catch (e) {
        this.showError("Ошибка при создании ордера");
      }
    },

    goToOrderHistory() {
      this.$router.push({ name: "order history" });
    },

    goToTradeHistory() {
      this.$router.push({ name: "trade history" });
    },

    goToSpot() {
      this.$router.push({ name: "spot swap" });
    },

    goToSOM() {
      this.$router.push({ name: "state of market" });
    },

    async getRate() {
      let tokenId = this.swapFrom?.swapToken;
      const tokenQuote = [...this.tokensFiat, ...this.tokensGold].find(token => token.id === this.swapTo?.swapToken);
      let tokenQuoteId = tokenQuote?.id;
      const decimals = tokenQuote?.decimals;
      if (_.isNil(tokenId) || _.isNil(tokenQuoteId)) {
        return 0;
      }
      if (this.modeOrder === "buy") {
        [tokenId, tokenQuoteId] = [tokenQuoteId, tokenId];
      }
      let course = await this.getCourse({ "tokenId": tokenId, "tokenQuoteId": tokenQuoteId });
      course = BigNumber(course).dividedBy(BigNumber(10).pow(decimals));
      return course;
    },

    showAdrDialog() {
      if (this.isCryptoToken) {
        if (this.ethOptions.length === 1) {
          this.ethAdr = _.get(this.ethOptions, "[0].value", null);
          this.doSwap();
        }
        else {
          this.adrDialog = true;
        }
      }
      else {
        this.doSwap();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.block-place{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media screen and (max-width:690px) {
    flex-direction: column;
    gap: 30px;
  }
}
.left-block{
  font-weight: 600;
  font-size: 22px;
  @media screen and (max-width:690px) {
    margin-left: 20px;
  }
}
.right-block{
  padding: 10px 15px;
  background: var(--menu-color);
  border-radius: 10px;
  cursor: pointer;
  @media screen and (max-width:690px) {
    margin-left: 20px;
  }
}
.history-text{
  font-weight: 400;
  font-size: 14px;
}
.swap-tab{
  display: flex;
  padding: 15px 25px;
  border-radius: 9px;
  color: var(--color-60);
  background: var(--color-90);
  margin-right: 10px;
  cursor: pointer;
  &:first-child {
    margin-left: 20px;
  }
}
.checked_tab{
  color: var(--text-color);
  background: var(--menu-color);
}
.spot{
  font-weight: 400;
  font-size: 13px;
  color: var(--color-60);
}
.spot_icon{
  margin-right: 5px;
}
.spot_checked{
  color: var(--text-color);
  background: var(--menu-color);
}
.circle{
  border-radius: 50%;
  padding: 10px 15px;
  background: var(--menu-color);
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  transform: rotate(90deg);
  @media screen and (max-width:1220px) {
    transform: rotate(0deg);
  }
}
.swap-wrapper {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0 20px;
  @media screen and (max-width:1320px) {
    gap: 20px;
  }
  @media screen and (max-width:1220px) {
    flex-direction: column;
    gap: 0px
  }
}
.btn-indent{
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
.question{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 140px;
}
.quest{
  border-bottom: 1px solid var(--color-70);
}
.question_header{
  font-weight: 500;
  font-size: 20px;
  color: var(--color-60);
  padding-bottom: 40px;
  border-bottom: 1px solid var(--color-70);
}
.answear{
  color: var(--color-60);
  width: 80%;
  margin-bottom: 30px;
}
.question-indent {
  margin-top: 25px;
  margin-bottom: 30px;
  cursor: pointer;
}
.modal_head_text {
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.mode_switcher{
  font-weight: 400;
  font-size: 13px;
  color: var(--color-60);
  background: var(--color-90);
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
}
.checked_switch{
  color: var(--text-color);
  background: var(--menu-color)
}
.metamask {
  background: url(../assets/img/metamask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  margin-right: 5px;
}
.course-text{
  color: var(--color-80);
  font-size: 11px;
  width: 150px;
  @media screen and (max-width:1320px) {
    width: 100px;
  }
}
</style>
