<template>
  <div
    class="card"
    :no-card="!card"
  >
    <div class="card__first-line">
      <div class="fs15">
        {{ $t("rightPanel.bankcard.label") }}
      </div>
    </div>

    <div
      v-if="card"
      class="card__second-line"
    >
      <div class="bankcard">
        <img
          src="/img/card_empty.png"
          class="bankcard-card"
        >

        <div class="bankcard__number bankcard__item">
          {{ cardNumber }}
        </div>

        <div class="bankcard__date bankcard__item">
          {{ cardDate }}
        </div>

        <div class="bankcard__name bankcard__item">
          {{ card.cardHolder }}
        </div>

        <div class="bankcard__type bankcard__item">
          {{ cardType }}
        </div>
      </div>
    </div>

    <div class="card__third-line">
      <AuButton
        bordered
        center
        :border-radius="10"
        height="44px"
        width="100%"
        @click="addCard"
      >
        <div class="fs14">
          {{ $t("rightPanel.bankcard.addCardButton") }}
        </div>
      </AuButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import valid from "card-validator";

export default {
  data() {
    return {
      card: {}
    };
  },

  computed: {
    ...mapGetters({
      cards: "payment/cards",
      auOwners: "auOwners"
    }),

    cardDate() {
      if (this.card.cardNumber) {
        return moment(this.card.expiresAt).format("MM/YY");
      }

      return "";
    },

    cardNumber() {
      if (this.card.cardNumber) {
        return this.card.cardNumber.match(/.{1,4}/g).join(" ");
      }

      return "";
    },

    cardType() {
      let numberValidation = valid.number(this.card.cardNumber);

      if (numberValidation.isValid) {
        return numberValidation.card.niceType;
      }

      return "";
    }
  },

  async created() {
    await this.getCards(this.$auth.user().id);

    let cards = _.cloneDeep(this.cards);

    cards.sort(function (a, b) {
      return moment(b.expiresAt).diff(a.expiresAt);
    });

    this.card = cards[0];
  },

  methods: {
    ...mapActions({
      getCards: "payment/getCards"
    }),

    addCard() {
      this.$router.push({ name: "payment method" });
    },

    getIdByIRI(iri) {
      if (iri === null || iri === undefined) {
        return -1;
      }
      const slashPos = String(iri).lastIndexOf("/") + 1;
      if (slashPos === 0) {
        return -1;
      }
      return String(iri).slice(slashPos);
    }
  }
};
</script>

<style lang="scss" scoped>
.balance {
  font-weight: 600;
  font-size: 20px;
  color: #5AC396;
  margin-top: 8px;
}

.bankcard {
  width: 200px;
  height: 126px;
  position: relative;

  border-radius: 7px;

  &__item {
    position: absolute;

    font-weight: 450;
    font-size: 8px;
    line-height: 100%;
    color: #FFFFFF;
  }

  &__number {
    left: 25px;
    bottom: 50px;

    font-size: 14px;
  }

  &__date {
    left: 80px;
    bottom: 25px;
  }

  &__name {
    left: 15px;
    bottom: 15px;
  }

  &__type {
    right: 15px;
    bottom: 15px;

    font-size: 10px;
  }
}

.card {
  width: 310px;
  height: 315px;
  display: flex;
  justify-content: space-between;

  &[no-card=true] {
    height: auto;
    gap: 20px;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  padding: 20px;

  &__first-line {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__second-line {
    display: flex;
    justify-content: center;
  }

  &__third-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &-right {
      margin-right: 11px;
    }
  }
}
</style>