<template>
  <div>
    <AuBreadcrumb v-if="showNewsTable" />
    <div
      v-if="showNewsTable"
      class="card projects"
    >
      <div class="title">
        {{ $t("news.label") }}
      </div>
      <div class="count">
        {{ blogPagesCount }}
      </div>
      <div class="d-flex justify-content-between p-indent">
        <span class="explain">
          {{ $t("news.newsCount") }}
        </span>
      </div>
      <div v-if="haveNews">
        <AuTable
          :columns="columns"
          :items="formattedNews"
          :action-column="actionColumn"
          :icons="null"
          :loading="dataLoading"
          @edit="editNews"
          @del="showDeleteDialog"
        >
          <template #image="{ value }">
            <img
              class="image"
              :src="value"
            >
          </template>
        </AuTable>

        <AuPagination
          v-if="blogPagesCount > 30"
          :total-items="blogPagesCount"
          @pageChanged="pageChanged"
        />
      </div>
    </div>
    <RouterView />
    <AuModal
      v-model="deleteDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          {{ $t("news.deleteDialog.title") }}
        </span>
      </template>
      <template #body>
        <span>
          {{ $t("news.deleteDialog.subtitle") }}
        </span>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="modalClick"
            >
              {{ $t("news.deleteDialog.agree") }}
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="delCancel"
            >
              {{ $t("news.deleteDialog.cancel") }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "News",
  data() {
    return {
      isStep1: true,

      deleteDialog: false,
      delInd: null,
      totalItems: 40
    };
  },

  computed: {
    ...mapGetters({
      blogPages: "news/blogPages",
      blogPagesCount: "news/blogPagesCount",
      dataLoading: "news/dataLoading"
    }),

    haveNews() {
      return !_.isEmpty(this.blogPages);
    },

    formattedNews() {
      return _.map(this.blogPages, blogPage => ({
        image: blogPage.imageFile,
        title: blogPage.title || "Без названия",
        isDraft: blogPage.isDraft ? "Черновик" : "Опубликовано"
      }));
    },

    showNewsTable() {
      return !_.includes(this.$route.path, "edit-news");
    },

    actionColumn() {
      return { label: this.$t("news.fourthColumn"), icon: "more" };
    },

    columns() {
      let columns = [{ name: this.$t("news.firstColumn"), align: "start", slot: "image" },
        { name: this.$t("news.secondColumn"), align: "start" },
        { name: this.$t("news.thirdColumn") }];

      return columns;
    }
  },

  async created() {
    await this.getBlogPages();
  },

  async beforeDestroy() {
    await this.setBlogPagesPage(1);
  },

  methods: {
    ...mapActions({
      removeBlogPage: "news/removeBlogPage",
      setBlogPagesPage: "news/setBlogPagesPage",
      getBlogPages: "news/getBlogPages"
    }),

    async pageChanged(page) {
      console.log(page);
      this.setBlogPagesPage(page);
      await this.getBlogPages();
    },

    delCancel() {
      this.deleteDialog = false;
      this.delInd = null;
    },

    async modalClick() {
      try {
        await this.removeBlogPage(this.blogPages[this.delInd].id);
        this.delInd = null;
        this.deleteDialog = false;
        this.$toast.success(this.$t("toasts.success.newsDelete"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
      catch (error) {
        this.$toast.error(this.$t("toasts.error.newsDelete"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    },

    showDeleteDialog(delInd) {
      this.deleteDialog = true;
      this.delInd = delInd;
    },

    createNews() {
      this.$router.push({ name: "edit news", params: { id: "new" } });
    },

    editNews(index) {
      this.$router.push({ name: "edit news", params: { id: this.blogPages[index].id } });
    }
  }
};
</script>

<style scoped lang="scss">
.projects {
  padding: 20px;
  font-family: 'Suisse Intl', serif;
  font-style: normal;
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 35px;
}

.count {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}

.bnt-text {
  font-weight: 450;
  font-size: 12px;
}

.explain {
  font-weight: 450;
  font-size: 12px;
  color: var(--color-60);
}

.p-indent {
  margin-bottom: 30px;
}

.modal_head_text {
  font-family: 'Suisse Intl', serif;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}

.btn_indent {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}

.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.modal_button_margin {
  margin-right: 10px;
}

.btn-text {
  font-weight: 450;
  font-size: 12px;
}

.image {
  width: 80px;
  height: 80px;
  margin-right: 8px;
  border-radius: 7px;
  padding: 4px;
}
</style>
