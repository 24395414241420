<template>
  <div
    v-if="isPanelsDontNeed"
    class="wrapper"
    :style="cssVars"
  >
    <RouterView />
  </div>

  <GlobalLoader v-else-if="loading" />

  <div
    v-else
    :style="cssVars"
    class="app"
  >
    <div class="app__left">
      <LeftPanel />
    </div>

    <div class="app__right">
      <TopPanel />

      <RouterView />
      <ChatPanel
        v-if="isChatVisible"
        :name="$t('chat.title')"
        :visible="isChatVisible"
        @close="setChatVisible(false)"
      />

      <BottomPanel />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BottomPanel from "@/panels/BottomPanel.vue";
import ChatPanel from "@/components/ChatPanel";
import GlobalLoader from "@/components/GlobalLoader.vue";
import LeftPanel from "@/panels/LeftPanel.vue";
import localStorageController from "@/help/localStorageController";
import TopPanel from "@/panels/TopPanel.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    ChatPanel,
    LeftPanel,
    TopPanel,
    BottomPanel,
    GlobalLoader
  },

  data() {
    return {
      loading: true,
      chatMenu: false
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      isChatVisible: "isChatVisible",
      tokens: "tokens",
      tokensGold: "tokensGold",
      defaultTokenId: "defaultToken/defaultTokenId"
    }),

    isPanelsDontNeed() {
      const parent = _.find(this.$route.matched, (match) => match.meta.isMain);
      return (
        !this.$auth.user() ||
        this.$route?.name === "welcome" ||
        this.$route?.name === "logout" ||
        this.$route?.name === "login" ||
        parent?.name === "blockdex" ||
        parent?.name === "exchange"
      );
    },

    user() {
      return this.$auth.user();
    },

    cssVars() {
      return {
        "--main-color": this.isLightTheme ? "#F2F1F7" : "#121212",
        "--menu-color": this.isLightTheme ? "#DFDFDF" : "#404040",
        "--card-color": this.isLightTheme ? "#FFFFFF" : "#1C1C1E",
        "--text-color": this.isLightTheme ? "#080808" : "#FFFFFF",

        "--scrollbar-color": this.isLightTheme ? "#b0b7c3" : "#393939",
        color: this.isLightTheme ? "#080808" : "#FFFFFF",

        "--color-00": this.isLightTheme ? "#080808" : "#FFFFFF",
        "--color-10": "#E6E6E6",
        "--color-20": this.isLightTheme ? "#080808CC" : "#CDCDCD",
        "--color-30": "#B4B4B4",
        "--color-40": this.isLightTheme ? "#080808CC" : "#9B9B9B",
        "--color-50": this.isLightTheme ? "#08080866" : "#828282",
        "--color-60": this.isLightTheme ? "#08080899" : "#696969",
        "--color-70": this.isLightTheme ? "#0808081A" : "#505050",
        "--color-80": this.isLightTheme ? "#0808084D" : "#373737",
        "--color-90": this.isLightTheme ? "#e3e2e8" : "#1E1E1E",
        "--color-95": this.isLightTheme ? "#F2F1F7" : "#121212",
        "--color-100": "#050505"
      };
    }
  },

  watch: {
    user: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue) {
          this.setDefaultToken({
            id: newValue.defaultTokenId || process.env.VUE_APP_TOKEN_DEFAULT_ID,
            mnemonic: newValue.defaultTokenMnemonic || process.env.VUE_APP_TOKEN_DEFAULT
          });

          if (newValue?.id !== oldValue?.id) {
            await this.getAuTokens();
            await this.getCurrencyRate();
            await this.getBalances(this.defaultTokenId);
            await this.getFollowings();
            await this.getBlogPages();
            await this.getAuOwners();
            await this.getCountry();
            await this.getAllAuCapitals();
            await this.getRequestsToIssue();
            await this.getSectionMarket();
            await this.getEthAddresses();
            await this.getExchangeBalances(newValue?.id);
            await this.getCounterparties();
            await this.getUsersNftTransactions(newValue?.id);
            await this.getBalancesGold(
              this.tokensGold.find(
                (token) => token.mnemonicEng == process.env.VUE_APP_GOLD
              )?.id
            );
            await this.getCompanies();

            for (const token of this.tokens) {
              await this.getTokenHistory({ defaultTokenId: this.defaultTokenId, tokenId: token.id });
            }

            if (process.env.VUE_APP_USE_WEBSOCKET !== "false") {
              await this.sendNotify(newValue.id);
            }

            this.loading = false;
          }
        }
      }
    }
  },

  async created() {
    let isLightTheme = localStorageController.getIsLightTheme();
    if (isLightTheme !== null) {
      this.$store.dispatch("setIsLightTheme", !(isLightTheme === "false"));
    }
  },

  methods: {
    ...mapActions({
      getFollowings: "getFollowings",
      getAuTokens: "getAuTokens",
      getUsersNftTransactions: "getUsersNftTransactions",
      getBlogPages: "getBlogPages",
      getAuOwners: "getAuOwners",
      getCurrencyRate: "getCurrencyRate",
      getBalances: "getBalances",
      getAllAuCapitals: "getAllAuCapitals",
      getCompanies: "getCompanies",
      getRequestsToIssue: "getRequestsToIssue",
      getBalancesGold: "getBalancesGold",
      getSectionMarket: "getSectionMarket",
      getEthAddresses: "getEthAddresses",
      getCounterparties: "getCounterparties",
      getCountry: "getCountry",
      getTokenHistory: "getTokenHistory",
      setDefaultToken: "defaultToken/setDefaultToken",
      getExchangeBalances: "getExchangeBalances",
      sendNotify: "sendNotify",
      setChatVisible: "setChatVisible"
    })
  }
};
</script>

<style lang="scss">
.app {
  display: flex;
  width: 100%;
  overflow: hidden;
  background: var(--main-color);

  &__right {
    width: 100%;
    overflow: hidden;
  }

  &__left {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
.wrapper {
  width: 100%;
}
</style>
