<template>
  <div class="th-main">
    <div
      v-if="showHeader"
      class="d-flex justify-content-between"
    >
      <span class="th-title">
        История конвертаций
      </span>
    </div>

    <AuTable
      :columns="columns"
      :items="elements"
      :loading="loading"
    />

    <AuPagination
      v-if="totalItems > 30"
      :total-items="totalItems"
      @pageChanged="pageChanged"
    />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TradeHistory",

  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      page: 1,
      totalItems: null,
      loading: false,
      columns: [{ name: "Дата" },
        { name: "Время" },
        { name: "Цена" },
        { name: "Номер блока" },
        { name: "Количество" },
        { name: "Всего" }]
    };
  },

  computed: {
    ...mapGetters({
      trades: "trades",
      tokens: "tokens"
    }),

    elements() {
      let ret = [];
      for (let i = this.trades.length; i !== 0; i--) {
        const trade = this.trades[i - 1];
        const token = this.tokens.find(token => token.id == this.getIdByIRI(trade.orderBuy.token));
        const tokenQuote = this.tokens.find(token => token.id == this.getIdByIRI(trade.orderBuy.tokenQuote));

        let clearPrice = _.get(trade, "price", 0);

        let price = Number(Number(clearPrice) / Math.pow(10, tokenQuote.decimals)).toFixed(tokenQuote.indent);
        let amount = _.get(trade, "value", 0);
        amount = (amount / Math.pow(10, tokenQuote.decimals)).toFixed(tokenQuote.indent);
        let total = (price * amount).toFixed(tokenQuote.indent);

        ret.push({
          createdAtDate: moment(trade.createdAt).format("DD.MM.YYYY"),
          createdAtTime: moment(trade.createdAt).format("HH:mm:ss"),
          price: price + ` ${tokenQuote.mnemonicEng}`,
          number: 12,
          amount: amount + ` ${token.mnemonicEng}`,
          amountBig: total
        });
      }
      return ret;
    }
  },

  async mounted() {
    if (_.isEmpty(this.trades)) {
      this.loading = true;
      this.totalItems = await this.getTrades({ page: this.page, userId: this.$auth.user().id });
      this.loading = false;
    }
  },

  methods: {
    ...mapActions({
      getTrades: "getTrades"
    }),

    getIdByIRI(iri) {
      return _.isNil(iri) ? null : iri.slice(iri.lastIndexOf("/") + 1);
    },

    async pageChanged(actualPage) {
      this.loading = true;
      this.page = actualPage;
      this.totalItems = await this.getTrades({ page: this.page, userId: this.$auth.user().id });
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.th-main{
  margin-bottom: 40px;
}
.th-title{
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
}
.th-data-text{
  font-weight: 400;
  font-size: 12px;
}
.th-period{
  font-size: 11px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-70);
}
.th_image {
  width: 15px;
  height: 15px;
  object-fit: cover;
}
.th-indent{
  width: 40px;
  color: var(--color-50);
}
.th-new-trade{
  margin-bottom: 15px;
}
.th-option{
  margin-bottom: 25px;
}
</style>