import axios from "axios";

export default class axiosClient {
  async getMessages(page, itemsPerPage, userToId) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: page,
      itemsPerPage: itemsPerPage,
      userToId: userToId
    };
    const resp = await axios.get("/api/messages", {
      headers: headers,
      params: params
    });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async postMessage(body) {
    return await axios.post("/api/messages", body);
  }

  async setViewed(id) {
    return await axios.get(`/api/messages/set_viewed/${id}`);
  }

  async getUnviewedMessageCount() {
    return await axios.get("/api/messages/unviewed");
  }
}