import Accounts from "../appPages/Accounts.vue";
import AssetsInvest from "@/appPages/AssetsInvest";
import BlockDex from "@/pages/BlockDex";
import BlockTrade from "@/appPages/BlockTrade";
import Briefcase from "../appPages/Briefcase.vue";
import Counterparties from "../appPages/Counterparties.vue";
import CounterpartyForm from "@/components/counterpartyForm/CounterpartyForm.vue";
import DAOEvents from "@/appPages/DAOEvents";
import DAORequests from "@/appPages/DAORequests";
import DaoTokens from "../appPages/DaoTokens.vue";
import Dashboard from "../appPages/Dashboard.vue";
import Documentation from "@/appPages/Documentation";
import EmailVerify from "../pages/EmailVerify.vue";
import Exchange from "@/pages/Exchange";
import GoodForm from "@/appPages/GoodForm";
import Login from "../pages/Login.vue";
import Logout from "../pages/Logout.vue";
import MyOffice from "../pages/MyOffice.vue";
import News from "../appPages/News.vue";
import NewsForm from "../appPages/NewsForm.vue";
import NewsList from "../appPages/NewsList.vue";
import OrderHistory from "@/appPages/OrderHistory";
import PaymentMethod from "../pages/PaymentMethod.vue";
import Pays from "@/appPages/Pays";
import ReferralProgram from "../pages/ReferralProgram.vue";
import Reports from "../appPages/Reports.vue";
import Security from "../pages/Security.vue";
import SendToken from "../appPages/SendToken.vue";
import Settings from "../pages/Settings.vue";
import SingleNews from "@/appPages/SingleNews";
import StateOfMarket from "@/appPages/StateOfMarket";
import Support from "../pages/Support.vue";
import Swap from "@/appPages/Swap";
import TestPage from "../appPages/TestPage.vue";
import TokenInfo from "@/appPages/TokenInfo";
import TradeHistory from "@/appPages/TradeHistory";
import Transactions from "../appPages/Transactions.vue";
import User from "../pages/User.vue";

import Debug from "../appPages/Debug.vue";
import Unsubscribe from "../appPages/Unsubscribe.vue";

import InvestProjects from "@/appPages/InvestProjects";
import InvestProjectsApplication from "@/appPages/InvestProjectsApplication.vue";

import auth from "../auth";
import OldSwap from "@/appPages/OldSwap";
import SpotSwap from "@/appPages/SpotSwap";
import TradePro from "@/appPages/TradePro";
import Welcome from "@/pages/Welcome";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "",
    name: "base",
    redirect: "/wallet"
  },
  {
    path: "/debug",
    name: "debug",
    component: process.env.VUE_APP_DEBUG == 1 ? Debug : null,
    meta: {
      title: "router.wallet",
      logo: "logo",
      isMain: true,
      auth: true
    }
  },
  {
    path: "/unsubscribe/:secret",
    name: "unsubscribe",
    component: Unsubscribe,
    meta: {
      title: "router.wallet",
      logo: "logo",
      isMain: true,
      auth: true
    }
  },
  {
    path: "/wallet",
    name: "wallet",
    component: MyOffice,
    meta: {
      title: "router.wallet",
      logo: "logo",
      isMain: true,
      auth: true
    },
    children: [
      {
        path: "",
        name: "wallet empty",
        redirect: "/wallet/dashboard"
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          title: "router.pages.dashboard",
          icon: "grid_round_green",
          access: ["user", "manager"],
          adaptiveRightPanel: true
        }
      },
      {
        //TODO: Это скорее всего роут 3 уровня
        path: "dao-tokens/:id?",
        name: "dao tokens",
        component: DaoTokens,
        props: true,
        meta: {
          title: "router.pages.daoTokens",
          isHelp: true
          //access: ["user", "manager"]
        }
      },
      {
        path: "briefcase",
        component: Briefcase,
        name: "briefcase",
        meta: {
          title: "router.pages.briefcase",
          icon: "bag_green",
          access: ["user", "manager"]
        }
      },
      {
        path: "send-token/:id",
        component: SendToken,
        name: "send token",
        props: true,
        meta: {
          title: "router.pages.sendToken",
          noColumnReverse: true
        }
      },
      {
        path: "testpage",
        component: TestPage,
        name: "TestPage"
        // props: true,
        // meta: {
        //   title: "TestPage",
        //   icon: "swap_vertical_green",
        //   access: ["user", "manager", "issuer"]
        // }
      },
      {
        path: "token-info/:id?",
        component: TokenInfo,
        name: "token info",
        props: true,
        meta: {
          title: "router.pages.transactions"
        }
      },
      {
        path: "my-accounts",
        name: "my accounts",
        meta: {
          title: "router.pages.accounts",
          icon: "activity_green",
          access: ["manager"]
        }
      },
      {
        path: "transactions",
        component: Transactions,
        name: "transactions",
        meta: {
          title: "router.pages.transactions",
          icon: "swap_vertical_green",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "counterparties",
        component: Counterparties,
        name: "counterparties",
        meta: {
          title: "router.pages.counterparties",
          icon: "users_green",
          access: ["manager", "issuer"]
        },
        children: [
          {
            path: "edit-counterparty/:id",
            component: CounterpartyForm,
            name: "edit counterparty",
            props: true,
            meta: {
              title: "router.pages.editCounterparty",
              noColumnReverse: true
            }
          }
        ]
      },
      {
        path: "reports",
        component: Reports,
        name: "reports",
        meta: {
          title: "router.pages.reports",
          icon: "chat_green"
          //access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "invest-projects",
        component: InvestProjects,
        name: "invest projects",
        meta: {
          title: "router.pages.investProjects"
          //access: ["user"]
        }
      },
      {
        path: "invest-projects-application",
        component: InvestProjectsApplication,
        name: "invest projects application",
        meta: {
          title: "router.pages.investProjectsApplication"
        }
      },
      {
        path: "accounts",
        component: Accounts,
        name: "accounts",
        meta: {
          title: "router.pages.account"
        }
      },
      {
        path: "accounts",
        component: Accounts,
        name: "accounts",
        meta: {
          title: "Аккаунт"
        }
      },
      {
        path: "news",
        component: News,
        name: "news",
        meta: {
          title: "router.pages.news",
          icon: "news_glasses",
          access: ["user", "issuer"]
        },
        children: [
          {
            path: "edit-news/:id",
            component: NewsForm,
            name: "edit news",
            props: true,
            meta: {
              title: "router.pages.editNews",
              noColumnReverse: true
            }
          }
        ]
      },
      {
        path: "news/:id",
        component: SingleNews,
        name: "single news",
        props: true,
        meta: {
          title: "Новость",
          noColumnReverse: true,
          hideNews: true
        }
      },
      {
        path: "all-news",
        component: NewsList,
        name: "all news",
        props: true,
        meta: {
          title: "Новости",
          noColumnReverse: true,
          hideNews: true
        }
      },
      {
        path: "user",
        name: "user",
        component: User,
        meta: {
          title: "router.pages.user"
        }
      },
      {
        path: "referral-program",
        name: "referral program",
        component: ReferralProgram,
        meta: {
          title: "router.pages.referralProgram"
        }
      },
      {
        path: "payment-method",
        name: "payment method",
        component: PaymentMethod,
        meta: {
          title: "router.pages.paymentMethod"
        }
      },
      {
        path: "security",
        name: "security",
        component: Security,
        meta: {
          title: "router.pages.security"
        }
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
        meta: {
          title: "router.pages.settings"
        }
      },
      {
        path: "support",
        name: "support",
        component: Support,
        meta: {
          title: "router.pages.support"
        }
      },
      {
        path: "my-goods/edit-good/:id",
        name: "edit good",
        component: GoodForm,
        meta: {
          title: "router.pages.editGood"
        }
      },
      {
        path: "assets-invest",
        name: "assets invest",
        component: AssetsInvest,
        meta: {
          title: "router.pages.assetsInvest"
        }
      },
      {
        path: "pays/:hash?",
        name: "pays",
        props: true,
        component: Pays,
        meta: {
          title: "router.pages.pays"
        }
      },
      {
        path: "dao-requests/:id?",
        name: "dao requests",
        props: true,
        component: DAORequests,
        meta: {
          title: "router.pages.daoRequests"
        }
      },
      {
        path: "dao-events/:id?",
        name: "dao events",
        props: true,
        component: DAOEvents,
        meta: {
          title: "router.pages.daoEvents"
        }
      },
      {
        path: "documentation",
        name: "documentation",
        component: Documentation,
        meta: {
          title: "router.pages.documentation"
        }
      }
    ]
  },
  {
    path: "/exchange",
    name: "exchange",
    component: Exchange,
    meta: {
      isMain: true,
      auth: true
    },
    children: [
      {
        path: "",
        name: "exchange empty",
        redirect: "/exchange/trade-pro"
      },
      {
        path: "trade-pro",
        name: "trade pro",
        component: TradePro,
        meta: {
          title: "TradePro",
          access: ["user", "manager", "issuer"],
          showMenu: false
        }
      }
    ]
  },
  {
    path: "/blockdex",
    name: "blockdex",
    component: BlockDex,
    meta: {
      title: "router.blockDex",
      logo: "logo_dex",
      isMain: true,
      auth: true
    },
    children: [
      {
        path: "",
        name: "blockdex empty",
        redirect: "/blockdex/swap"
      },
      {
        path: "swap",
        name: "swap",
        component: Swap,
        meta: {
          title: "SWAP",
          access: ["user", "manager", "issuer"],
          showMenu: true
        }
      },
      {
        path: "block-trade",
        name: "block trade",
        component: BlockTrade,
        meta: {
          title: "BlockTrade",
          access: ["user", "manager", "issuer"],
          showMenu: true
        }
      },
      {
        path: "spot-swap",
        name: "spot swap",
        component: SpotSwap,
        meta: {
          title: "Управление",
          access: ["user", "manager", "issuer"],
          showMenu: false
        }
      },
      {
        path: "order-history",
        name: "order history",
        component: OrderHistory,
        meta: {
          title: "История ордеров",
          showMenu: false
        }
      },
      {
        path: "trade-history",
        name: "trade history",
        component: TradeHistory,
        meta: {
          title: "История конвертаций",
          showMenu: false
        }
      },
      {
        path: "state-of-market",
        name: "state of market",
        component: StateOfMarket,
        meta: {
          title: "Состояние рынка",
          showMenu: false
        }
      },
      {
        path: "old-swap",
        name: "old swap",
        component: OldSwap,
        meta: {
          title: "Состояние рынка",
          showMenu: false
        }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "login"
    }
  },
  {
    path: "/email_verify/:hash",
    name: "EmailVerify",
    component: EmailVerify,
    meta: {
      auth: false,
      title: "Verify"
    }
  },
  {
    path: "/login/:reg",
    name: "register1",
    component: Login,
    meta: {
      title: "register"
    }
  },
  {
    path: "/register/:reg?",
    name: "register2",
    component: Login,
    meta: {
      title: "register"
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeResolve((to, from, next) => {
  // проверка на случай если пользователь еще не аутентифицировался
  const role_user = auth.user() === null ? null : auth.user().type;
  switch (role_user) {
  case "guest":
    if (to.name === "welcome") {
      next();
    }
    else {
      next("welcome");
    }
    break;
  default:
    next();
    break;
  }
});

export default router;
