<template>
  <div>
    <div class="rates-buttons">
      <AuSwitchButton
        height="44px"
        width="66px"
        :font-size="14"
        :active="showAll"
        @click="showAll = true"
      >
        {{ $t("dashboard.rates.buttons.all") }}
      </AuSwitchButton>

      <AuSwitchButton
        height="44px"
        width="115px"
        :font-size="14"
        :active="!showAll"
        @click="showAll = false"
      >
        {{ $t("dashboard.rates.buttons.favorites") }}
      </AuSwitchButton>
    </div>

    <Swiper
      class="swiper"
      :navigation="true"
      :slides-per-view="4"
      :hash-navigation="{
        watchState: true,
      }"
      :space-between="20"
      :modules="modules"
    >
      <SwiperSlide
        v-for="(rate, index) in rates"
        :key="index"
      >
        <ExchangeRate
          :id="rate.id"
          :name="rate.name"
          :value="rate.value"
          :values="rate.values"
          :icon="rate.icon"
          :currency="rate.currency"
          :decimals="rate.decimals"
          :mnemonic="rate.mnemonic"
          :value-default="rate.valueDefault"
        />
      </SwiperSlide>
    </Swiper>

    <div class="exchange-rates">
      <ExchangeRate
        v-for="(rate, index) in rates"
        :id="rate.id"
        :key="index"
        :name="rate.name"
        :value="rate.value"
        :values="rate.values"
        :icon="rate.icon"
        :currency="rate.currency"
        :decimals="rate.decimals"
        :value-default="rate.valueDefault"
      />
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import ExchangeRate from "./ExchangeRate";
import { mapGetters } from "vuex";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import _ from "lodash";

import "swiper/css";

import "swiper/css/navigation";

export default {
  components: {
    Swiper,
    SwiperSlide,
    ExchangeRate
  },

  setup() {
    return {
      modules: [Navigation]
    };
  },

  data() {
    return {
      showAll: true,
      currencySup: new CurrencySupport()
    };
  },

  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      tokens: "tokens",
      followings: "followings",
      auOwners: "auOwners",
      tokenHistory: "tokenHistory",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),

    rates() {
      return _.chain([...this.tokensFiat, ...this.tokensGold]).filter(item => this.showAll ? true : this.getIsFollowing(item.id)).map(item => {
        let value = _.find(this.auOwners, owner => owner.auToken.id == item.id);
        value = _.get(value, "amount", 0);
        let valueDefault = BigNumber(this.currencySup.getCurrency(item.id, this.defaultTokenMnemonic)).toFixed(item.decimals);
        valueDefault = BigNumber(valueDefault).multipliedBy(value);

        let data = {
          id: item.id,
          name: item.nameEng,
          mnemonic: item.mnemonicEng,
          value: value,
          values: this.getTokenHistory(item.id),
          valueDefault: valueDefault,
          icon: item.logoMain,
          currency: this.defaultTokenMnemonic,
          decimals: item.decimals
        };

        return data;
      }).value();
    }
  },


  methods: {
    getIsFollowing(id) {
      return _.some(this.followings, { auToken: "/api/au_tokens/" + id });
    },

    getTokenHistory(id) {
      let values = [];
      let tokenInfo = _.find(this.tokenHistory, { tokenId: id });
      if (tokenInfo) {
        tokenInfo.tokenHistory.forEach(info => {
          values.push(Number(info.value));
        });
      }
      return values;
    }
  }
};
</script>

<style scoped lang="scss">
.rates-buttons {
  display: flex;
  gap: 10px;
}

.exchange-rates {
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
  margin-top: 20px;
  display: none;

  @media screen and (max-width: 640px)  {
    display: flex;
  }

  @media screen and (max-width: 450px)  {
    flex-direction: column;
  }
}

.swiper {
  display: auto;

  @media screen and (max-width: 640px)  {
    display: none;
  }
}
</style>

<style lang="scss">
.swiper-button-disabled {
  display: none;
}

.swiper-button-next {
  color: var(--text-color);

  box-shadow: inset -20px -1px 15px 0px var(--main-color);
  right: 0px;
  top: 35px;
  height: 100%;
  width: 30px;
  padding-right: 20px;

  &::after {
    font-size: 10px;

    border: var(--color-80) 1px solid;
    border-radius: 50%;
    padding: 11px 13px;
  }
}

.swiper-button-prev {
  color: var(--text-color);

  box-shadow: inset 20px -1px 15px 0px var(--main-color);
  left: 0px;
  top: 35px;
  height: 100%;
  width: 30px;
  padding-left: 20px;

  &::after {
    font-size: 10px;

    border: var(--color-80) 1px solid;
    border-radius: 50%;
    padding: 11px 13px;
  }
}

div.swiper {
  margin-left: 0px;
  width: 100%;

  &-slide {
    padding-top: 20px;
    cursor: pointer;
  }
}
</style>