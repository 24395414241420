<template>
  <div>
    <div class="d-flex align-items-center chart">
      <div class="col-4 info">
        {{ chartName }}
      </div>
      <div class="d-flex justify-content-between col-8 align-items-center">
        <div class="d-flex btn-margin">
          <AuSwitchButton
            width="50px"
            height="32px"
          >
            {{ $t("dashboard.changeChart.all") }}
          </AuSwitchButton>
          <AuSwitchButton
            width="40px"
            height="32px"
          >
            1{{ $t("dashboard.changeChart.oneMonth") }}
          </AuSwitchButton>
          <AuSwitchButton
            width="43px"
            height="32px"
          >
            4{{ $t("dashboard.changeChart.fourMonths") }}
          </AuSwitchButton>
          <AuSwitchButton
            width="43px"
            height="32px"
          >
            6{{ $t("dashboard.changeChart.sixMonths") }}
          </AuSwitchButton>
          <AuSwitchButton
            width="35px"
            height="32px"
          >
            1{{ $t("dashboard.changeChart.oneYear") }}
          </AuSwitchButton>
        </div>


        <div class="calendar">
          <Datepicker
            v-model="date"
            locale="ru"
            auto-apply
            :max-date="new Date()"
            :enable-time-picker="false"
            :format="format"
            :placeholder="$t('dashboard.changeChart.datePickerPlaceholder')"
            range
            :dark="!isLightTheme"
          />
        </div>
      </div>
    </div>
    <Apexchart
      :series="series"
      :options="options"
      height="180"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "StuffChart",

  components: {
    Datepicker
  },

  props: {
    chartName: {
      type: String,
      default: "Изменение цены"
    },
    color: {
      type: String,
      default: "#2ABA7C"
    },
    name: {
      type: String,
      default: "\"Green\""
    }
  },

  computed: {
    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    series() {
      return [{
        name: this.name,
        color: this.color,
        type: "area",
        data: [[1, 150], [3, 175], [5, 160], [7, 155], [10, 190], [13, 230], [15, 225], [18, 205], [20, 255], [23, 195], [27, 180], [30, 190]]
      },
      {
        name: "White",
        color: "#F2F2F7",
        type: "line",
        data: [[1, 190], [3, 160], [5, 200], [7, 185], [10, 225], [13, 200], [15, 195], [18, 230], [20, 160], [23, 185], [27, 170], [30, 150]]
      }];
    },

    options() {
      return {
        chart: {
          zoom: { enabled: false },
          toolbar: { show: false }
        },
        stroke: { curve: "smooth" },
        fill: {
          type: "solid",
          opacity: [0.35, 1]
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        yaxis: {
          labels: { formatter: function (val) {
            return val + "k";
          },
          style: {
            colors: this.isLightTheme ? "#08080899" : "#FFFFFF99" }
          },
          tickAmount: 3,
          min: 140,
          max: 260
        },
        xaxis: {
          tickAmount: 7,
          min: 1,
          max: 30,
          labels: {
            style: {
              colors: this.isLightTheme ? "#08080899" : "#FFFFFF99"
            }
          }
        }
      };
    }
  }
};
</script>

<style scoped lang="scss">
.calendar {
  display: flex;
  align-items: center;
  gap: 10px
}

.chart{
  font-family: 'Suisse Intl',serif;
  font-style: normal;
}
.info{
  font-weight: 400;
  font-size: 14px;
}
.text{
  font-weight: 450;
  font-size: 12px;
}
.btn-margin{
  gap: 8px;
}
</style>