<template>
  <div class="card">
    <div class="card__header">
      <div class="fs15">
        {{ $t("dashboard.news.label") }}
      </div>

      <div
        class="card__action"
        @click.native="goToNews"
      >
        {{ $t("dashboard.news.goTo") }}
        <AuIcon icon="chevron_right" />
      </div>
    </div>

    <Swiper
      :slides-per-view="3"
      :space-between="20"
      class="swiper"
    >
      <SwiperSlide
        v-for="(blogPage, index) in nonDraftedBlogPages"
        :key="index"
      >
        <BlogPanel
          :blog-item="blogPage"
          :style="`--bg-image: url('${blogPagesImages[index].image}');`"
        />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import _ from "lodash";

import BlogPanel from "./components/BlogPanel.vue";
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BlogPanel
  },

  data() {
    return {
      images: ["Western Union.svg", "Ziraat Auto.svg", "Tinkoff Drive.svg"]
    };
  },

  computed: {
    ...mapGetters({
      blogPages: "blogPages"
    }),

    nonDraftedBlogPages() {
      return _.filter(this.blogPages, { isDraft: false });
    },

    blogPagesImages() {
      return _.map(this.nonDraftedBlogPages, bp => ({ image: bp.imageFile }));
    }
  },
  methods: {
    goToNews() {
      this.$router.push({ name: "all news" });
    }
  }
};
</script>

<style scoped lang="scss">
div.swiper {
  margin-left: 0px;
  width: 100%;

  &-slide {
    padding-top: 20px;
    cursor: pointer;
    width: auto !important;
  }
}

.card {
  width: 100%;
  height: 229px;
  padding: 22px 22px 0 22px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 15px;
  }

  &__action {
    font-size: 12px;
    color: var(--color-60);
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>