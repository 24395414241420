<template>
  <div
    :class="{'variables_light': isLightTheme, 'variables_dark': !isLightTheme}"
    class="chat-panel"
  >
    <div
      v-if="showMenu"
      class="chat-menu"
    >
      <div
        v-for="user of contactList"
        :key="user.id"
        :class="{'cell-active': user.isActive}"
        class="cell"
        @mouseenter="user.isActive=true"
        @mouseleave="user.isActive=false"
        @click="changeUser(user.id, user.userId, user.name)"
      >
        <div class="d-flex justify-content-between">
          <span class="user">
            {{ user?.name }}&nbsp;
          </span>
          <a
            class="a-link"
            :href="user?.link"
            :isActive="user.isActive"
            target="_blank"
          >
            {{ user?.address }}
          </a>
        </div>
        <div>
          <span class="product">
            {{ user.nftName }}
          </span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="message">
            {{ user.message }}
          </span>
          <span
            class="time"
            :isActive="user.isActive"
          >
            {{ user.time }}
          </span>
        </div>
      </div>
      <div
        v-if="totalNft - loadingUsers > 0"
        class="d-flex justify-content-center"
      >
        <AuButton
          center
          bordered
          border-radius="15"
          @click="getNextPageUserContact"
        >
          <span class="chat-more">Еще...</span>
        </AuButton>
      </div>
    </div>
    <div class="chat-header">
      <AuIcon
        icon="toggler_chat"
        clickable
        @click="showMenu=!showMenu"
      />
      <div class="title">
        {{ title }}
      </div>
      <div class="close">
        <AuIcon
          icon="close_chat"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div
      ref="chatBody"
      class="chat-body"
    >
      <div
        v-for="(mess, date) in chat"
        :key="date"
      >
        <div class="date">
          <div class="divider" />
          <div>
            {{ date }}
          </div>
          <div class="divider" />
        </div>
        <div
          v-for="(block, index) of mess"
          :id="date + '-' + index"
          :key="date + '-' + index"
          class="message-line"
          :isMe="block.isMe"
        >
          <div
            class="message"
            :isMe="block.isMe"
          >
            <div class="text">
              {{ block.message }}
            </div>
            <div
              v-if="!block.isMe"
              class="image-container"
            >
              <img
                class="check-mark"
                :src="getCheckMark(block.isViewed)"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="loadingMessages !== 0 && totalMessages - loadingMessages > 0"
        class="d-flex justify-content-center"
      >
        <AuButton
          center
          bordered
          border-radius="15"
          @click="getNextPageMessage"
        >
          <span class="chat-more">Загрузить еще...</span>
        </AuButton>
      </div>
    </div>
    <div class="chat-bottom">
      <input
        v-model="chatText"
        class="chat-input"
        type="text"
        :placeholder="$t('chat.placeholder')"
        @keyup="checkPressEnter"
      >
      <AuIcon
        icon="arrow_green"
        clickable
        @click="addToChat"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ChatPanel",
  props: {
    name: {
      type: String,
      default: ""
    },
    nftId: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close"],

  data() {
    return {
      chat: {},
      chatText: null,
      page: 1,
      usersPage: 1,
      itemsPerPage: 50,
      loadingMessages: 0,
      loadingUsers: 0,
      userToId: null,
      showMenu: false,
      contactList: [],
      userName: "",
      userNftId: null,
      observer: null
    };
  },

  computed: {
    ...mapGetters({
      messages: "message/messages",
      totalMessages: "message/totalMessages",
      nfts: "marketplace/nfts",
      totalNft: "marketplace/totalNft",
      isLightTheme: "isLightTheme"
    }),

    title() {
      return _.isEmpty(this.userName) ? this.name : this.userName;
    },

    currentNftId() {
      return this.userNftId === null ? this.nftId : this.userNftId;
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        setTimeout(this.scrollBlock, 0);
      }
    }
  },
  async mounted() {
    if (this.nftId !== 0) {
      const resp = await this.getNftById(this.nftId);
      this.userToId = resp.owner?.id;
      await this.getMessagesPage();
    }
    await this.getContactList();
    this.initObserver();
    this.startNextObserve();
  },
  unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    ...mapActions({
      postMessage: "message/postMessage",
      getMessages: "message/getMessages",
      setViewed: "message/setViewed",
      getNftById: "marketplace/getNftById",
      getNfts: "marketplace/getNfts"
    }),

    getCheckMark(isViewed) {
      return "/svg/message/" + (isViewed ? "green_mark" : "grey_mark") + ".svg";
    },

    scrollBlock() {
      const block = this.$refs.chatBody;
      block.scrollTop = block.scrollHeight;
    },

    initObserver() {
      this.observer = new IntersectionObserver(this.checkIsViewed, { root: this.$refs.chatBody });
    },

    startNextObserve() {
      const items = Object.entries(this.chat);
      for (let i = items.length - 1; i >= 0; i--) {
        const index = _.findLastIndex(items[i][1], el => !el.isViewed);
        if (index !== -1) {
          this.observer.observe(document.getElementById(`${items[i][0]}-${index}`));
          return;
        }
      }
    },

    checkIsViewed(elements) {
      _.forEach(elements, el => {
        if ((el.isIntersecting && el.intersectionRatio > 0.01)) {
          const id = el.target.id;
          const date = id.slice(0, id.lastIndexOf("-"));
          const index = id.slice(id.lastIndexOf("-") + 1);
          this.chat[date][index].isViewed = true;
          // ответа не ждем, потому без await
          this.setViewed(this.chat[date][index].id);
          this.startNextObserve();
          this.observer.unobserve(document.getElementById(id));
        }
      });
    },

    async changeUser(id, newUserId, newUserName) {
      this.showMenu = false;
      this.userNftId = id;
      this.userToId = newUserId;
      this.userName = newUserName;
      this.page = 1;
      this.loadingMessages = 0;
      this.chat = {};
      await this.getMessagesPage();
      setTimeout(this.scrollBlock, 0);
    },

    async getNextPageMessage() {
      this.page++;
      await this.getMessagesPage();
      setTimeout(this.startNextObserve, 100);
    },

    async getNextPageUserContact() {
      this.usersPage++;
      await this.getContactList();
    },

    async addToChat() {
      this.addMessage(moment(), { message: this.chatText, isMe: true, isViewed: true });
      if (this.currentNftId !== 0) {
        await this.sendMessage(this.chatText);
      }
      this.chatText = null;
    },

    addMessage(time, params) {
      const date = moment(time).format("DD.MM.YYYY");
      let block = this.chat[date];
      if (!block) {
        this.chat[date] = [];
        block = this.chat[date];
      }
      block.push(params);
    },

    checkPressEnter(event) {
      if (event.key === "Enter") {
        this.addToChat();
      }
    },

    async sendMessage(message) {
      const body = {
        subject: this.name,
        userFrom: `/api/users/${this.$auth.user().id}`,
        userTo: `/api/users/${this.userToId}`,
        body: message,
        createdAt: moment().format("DD.MM.YYYY HH:mm:ss"),
        nft: `/api/nfts/${this.currentNftId}`,
        isViewed: false
      };
      await this.postMessage(body);
    },

    async getMessagesPage() {
      await this.getMessages({
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        userToId: this.userToId
      });
      this.loadingMessages += this.messages.length;

      _.forEach(this.messages, message => {
        const isMe = message.userFrom == `/api/users/${this.$auth.user().id}`;
        this.addMessage(message.createdAt, {
          id: message.id,
          message: message.body,
          isViewed: isMe || message.isViewed,
          isMe: isMe });
      });
    },

    async getContactList() {
      await this.getNfts({
        page: this.page,
        inSale: true,
        status: "issued"
      });

      for (const nft of this.nfts) {
        const id = nft.owner?.id;
        if (!id) {
          continue;
        }
        let name = "-";
        if (nft.owner?.email) {
          name = nft.owner.email.length < 25 ? nft.owner.email : nft.owner.email.slice(0, 25) + "...";
        }
        const address = nft?.ownerAddress ?
          nft.ownerAddress.slice(2, 6) + "..." + nft.ownerAddress.slice(-4) : "-";

        await this.getMessages({
          page: 1,
          itemsPerPage: 30,
          userToId: id
        });
        let message = "-";
        let time = "";
        if (this.totalMessages != 0) {
          message = this.messages[0].body.length < 20 ?
            this.messages[0].body : this.messages[0].body.slice(0, 20) + "...";
          time = moment(this.messages[0].createdAt).format("DD.MM.YYYY");
          const now = moment().format("DD.MM.YYYY");
          time = now === time ? moment(this.messages[0].createdAt).format("HH:mm:ss") : time ;
        }

        this.contactList.push({
          id: nft.id,
          name: name,
          nftName: nft.productName,
          userId: id,
          address: address,
          link: process.env.VUE_APP_SCAN_BASE_URL + "address/" + nft.ownerAddress,
          message: message,
          time: time,
          isActive: false
        });
      }

      this.loadingUsers += this.nfts.length;
    }
  }
};
</script>

<style scoped lang="scss">
.variables_light {
  --bg-panel: #1C1C1C;
  --bg-body: #FFFFFF;
  --title-text: #FFFFFF;
  --date-text: #8E8E8E;
  --divider-color: #EEEEEE;
  --bg-message: #FBFBFB;
  --bg-message-me: #D6FFEF;
  --bg-menu: #FBFBFB;
  --time-color: #676767;
}
.variables_dark {
  --bg-panel: #353535;
  --bg-body: #1C1C1C;
  --title-text: #FFFFFF;
  --date-text: #8E8E8E;
  --divider-color: #EEEEEE;
  --bg-message:  #353535;
  --bg-message-me: #279063;
  --bg-menu: #1C1C1C;
  --time-color: #D6FFEF;
}
.chat-menu {
  position: absolute;
  right: 400px;
  width: 300px;
  height: 100%;
  z-index: 1000;
  background-color: var(--bg-menu);
  border: 1px solid var(--scrollbar-color);
  overflow-y: scroll;
  @media screen and (max-width: 500px){
    left: 0;
    width: 400px;
  }
}
.cell {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid var(--scrollbar-color);
  display: flex;
  flex-direction: column;
  gap: 5px;

  .user {
    font-weight: 400;
    font-size: 13px;
    overflow: hidden;
  }

  .a-link{
    font-weight: 400;
    font-size: 11px;
    color: #279063;
    &[isActive="true"] {
      color: #ffffff;
    }
  }

  .product {
    font-weight: 600;
    font-size: 15px;
  }

  .message {
    font-weight: 400;
    font-size: 14px;
  }

  .time {
    font-weight: 400;
    font-size: 11px;
    color: var(--time-color);
    &[isActive="true"] {
      color: #ffffff;
    }
  }
}

.cell-active{
  background: #279063;
  color: #ffffff;
}

.chat-panel{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  width: 400px;
  height: 100%;
  background-color: var(--bg-panel);
  @media screen and (max-width: 500px){
    right: calc((100% - 400px) / 2);
    top: auto;
  }
}

.chat-header {
  display: flex;
  margin: 15px;

  .title {
    padding: 0 10px;
    width: 320px;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 450;
    font-size: 16px;
    color: var(--title-text);
  }

  .close {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
}
.chat-body{
  background-color: var(--bg-body);
  height: calc(100% - 120px);
  overflow-y: scroll;
  padding: 5px 20px;

  .date {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    color: var(--date-text);
    margin: 12px 0;

    .divider {
      width: 110px;
      height: 1px;
      border-bottom: 1px solid var(--divider-color);
    }
  }

  .message-line {
    display: flex;
    justify-content: end;
    &[isMe="true"] {
      justify-content: start;
    }

    .message{
      display: flex;
      max-width: 75%;
      margin: 5px 0;
      background-color: var(--bg-message);
      border-radius: 10px 10px 0 10px;
      &[isMe="true"] {
        background-color: var(--bg-message-me);
        border-radius: 10px 10px 10px 0;
      }

      .text {
        padding: 10px;
        word-wrap: anywhere;
      }

      .image-container {
        display: flex;
        align-items: end;
        padding: 10px;

        .check-mark {
          width: 16px;
          height: 10px;
        }
      }
    }
  }
}
.chat-bottom{
  display: flex;
  margin: 20px;
}
.chat-input{
  width: 320px;
  margin-right: 10px;
  outline: none;
  border: none;
  color: #FFFFFF;
}
.chat-more{
  font-size: 12px;
  color: var(--color-40);
}
</style>