<template>
  <div class="depth">
    <div class="depth__header">
      <div class="w-50">
        Цена {{ mode == MODES.SALE ? tokenTo?.mnemonicEng : tokenFrom?.mnemonicEng }}
      </div>

      <div class="w-25">
        Объём
      </div>

      <div class="w-25">
        Итого {{ mode == MODES.SALE ? tokenFrom?.mnemonicEng : tokenTo?.mnemonicEng }}
      </div>
    </div>

    <div class="depth__body">
      <div
        v-for="(item, index) in asks"
        :key="index"
        class="depth__body-item depth__body-item--ask"
        @click="$emit('select', item.price, countAsksTotal(index))"
      >
        <div class="w-50">
          {{ item.price }}
        </div>

        <div class="w-25">
          {{ item.count }}
        </div>

        <div class="w-25">
          {{ countAsksTotal(index) }}
        </div>

        <div :style="getAskPercentStyle(item.count)" />
      </div>

      <div class="depth__body-deal">
        &darr;{{ lastDeal.price }}
      </div>

      <div
        v-for="(item, index) in bids"
        :key="index"
        class="depth__body-item depth__body-item--bid"
        @click="$emit('select', item.price, countBidsTotal(index))"
      >
        <div class="w-50">
          {{ item.price }}
        </div>

        <div class="w-25">
          {{ item.count }}
        </div>

        <div class="w-25">
          {{ countBidsTotal(index) }}
        </div>

        <div :style="getBidPercentStyle(item.count)" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

import { MODES } from "./marketType";

export default {
  emits: ["change"],

  data() {
    return {
      MODES: MODES
    };
  },

  computed: {
    ...mapGetters({
      asks: "tradePro/asks",
      bids: "tradePro/bids",
      lastDeal: "tradePro/lastDeal",
      tokenIdFrom: "tradePro/tokenIdFrom",
      tokenIdTo: "tradePro/tokenIdTo",
      tokens: "tokens"
    }),

    tokenFrom() {
      return _.find(this.tokens, { id: this.tokenIdFrom });
    },

    tokenTo() {
      return _.find(this.tokens, { id: this.tokenIdTo });
    }
  },

  methods: {
    getAskPercentStyle(count) {
      let max = _.maxBy(this.asks, ask => Number(ask.count));

      let res = (count / max.count) * 100;
      if (res >= 50) {
        res = 100;
      }
      else {
        res *= 2;
      }

      return {
        background: "rgba(230, 103, 106, 0.16)",
        position: "absolute",
        width: `${res}%`,
        top: "0",
        bottom: "0",
        right: "0px"
      };
    },

    getBidPercentStyle(count) {
      let max = _.maxBy(this.bids, bid => Number(bid.count));

      let res = (count / max.count) * 100;
      if (res >= 50) {
        res = 100;
      }
      else {
        res *= 2;
      }

      return {
        background: "rgba(32, 178, 108, 0.16)",
        position: "absolute",
        width: `${res}%`,
        top: "0",
        bottom: "0",
        right: "0px"
      };
    },

    countAsksTotal(index) {
      let ask = this.asks[index];

      let sum = ask.price * ask.count;

      for (let i = this.asks.length - 1; i > index; i--) {
        sum += this.asks[i].price * this.asks[i].count;
      }

      return sum;
    },

    countBidsTotal(index) {
      let bid = this.bids[index];

      let sum = bid.price * bid.count;

      for (let i = 0; i < index; i++) {
        sum += this.bids[i].price * this.bids[i].count;
      }

      return sum;
    }
  }
};
</script>

<style lang="scss" scoped>
.depth {
  &__header {
    height: 16px;
    width: 100%;
    margin: 10px;

    display: flex;

    color: var(--color-50);
  }

  &__body {
    &-item {
      padding-left: 10px;
      position: relative;
      cursor: pointer;
      display: flex;

      &:hover {
        background: var(--color-80);
      }

      &--ask {
        .w-50 {
          color: red;
        }
      }

      &--bid {
        .w-50 {
          color: green;
        }
      }
    }

    &-deal {
      font-size: 18px;
      text-align: center;
      color: red;
    }
  }
}
</style>