<template>
  <div
    v-show="title == selectedTitle"
    class="tab-content"
  >
    <slot />
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "AuTabPanel",
  props: {
    title: {
      type: String,
      default: ""
    },
    showError: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const selectedTitle = inject("selectedTitle");
    const tabErrors = inject("tabErrors");
    const hiddens = inject("hiddens");

    return {
      selectedTitle,
      tabErrors,
      hiddens
    };
  },
  watch: {
    showError() {
      for (let i = 0; i < this.tabErrors.length; i++) {
        if (this.tabErrors[i].title == this.title) {
          this.tabErrors[i].status = !this.tabErrors[i].status;
          break;
        }
      }
    },
    hidden(newHidden) {
      for (let i = 0; i < this.hiddens.length; i++) {
        if (this.hiddens[i].title == this.title) {
          this.hiddens[i].status = newHidden;
          break;
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.tab-content {
  border-radius: 0 10px 10px 10px;
  padding: 1.25rem;
}
</style>
