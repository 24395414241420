<template>
  <div
    :style="style"
    class="trade-pro"
  >
    <TradeProHeader />

    <div class="trade-pro__body">
      <div class="trade-pro__left-panel">
        <div class="trade-pro__nav-panel">
          <div class="control-panel">
            <div class="control-panel-left">
              <div
                :active="marketType == MARKET_TYPES.LIMIT"
                class="control"
                @click="setMarketType(MARKET_TYPES.LIMIT)"
              >
                Лимит
              </div>
              <div
                :active="marketType == MARKET_TYPES.MARKET"
                class="control"
                @click="setMarketType(MARKET_TYPES.MARKET)"
              >
                Рынок
              </div>
              <div
                :active="marketType == MARKET_TYPES.STOP_LIMIT"
                class="control"
                @click="setMarketType(MARKET_TYPES.STOP_LIMIT)"
              >
                Стоп-лимит
              </div>
            </div>
          </div>

          <div
            v-if="tokenFrom && tokenTo"
            class="trade-pro__left-panel-body"
          >
            <div class="trade-pro__button-panel">
              <div
                class="trade-pro__button trade-pro__button_buy"
                :active="mode == MODES.BUY"
                @click="mode = MODES.BUY"
              >
                Купить
              </div>
              <div
                :active="mode == MODES.SALE"
                class="trade-pro__button trade-pro__button_sale"
                @click="mode = MODES.SALE"
              >
                Продать
              </div>
            </div>

            <div class="trade-pro__left-panel-block">
              <div class="fs13">
                Доступный баланс
              </div>

              <div class="trade-pro__left-panel-block-balance">
                {{ amount }}
              </div>
            </div>

            <div class="trade-pro__left-panel-block">
              <span class="fs13">Сумма</span> <span class="fs13 gray">Купить на сумму</span>

              <div class="d-flex justify-content-between gap-2">
                <div class="trade-pro__left-panel-block-balance w-100 gap-2">
                  <AuDecimalInput
                    :value="swapValue"
                    :decimal="tokenFrom?.decimals"
                    class="swap-input"
                    @input="changeValue"
                  />

                  <span
                    class="gray fs11"
                  > {{ mode == MODES.SALE ? tokenFrom?.mnemonicEng : tokenTo?.mnemonicEng }} </span>
                </div>

                <div
                  class="trade-pro__left-panel-block-balance"
                >
                  {{ mode == MODES.SALE ? tokenTo?.mnemonicEng : tokenFrom?.mnemonicEng }}
                </div>
              </div>
            </div>

            <div class="trade-pro__left-panel-block">
              <input
                v-model="percent"
                type="range"
                class="form-range"
                min="0"
                max="100"
                @input="changePercent"
              >
              <span class="gray d-flex justify-content-between"><span>0</span><span>100%</span></span>
            </div>

            <div
              v-if="marketType == MARKET_TYPES.STOP_LIMIT"
              class="trade-pro__left-panel-block"
            >
              <div class="fs13">
                Тригерная цена
              </div>

              <div class="trade-pro__left-panel-block-balance">
                <AuDecimalInput
                  v-model="priceTrigger"
                  :decimal="tokenTo?.decimals"
                  class="swap-input"
                />
              </div>
            </div>

            <div
              v-if="marketType == MARKET_TYPES.LIMIT || marketType == MARKET_TYPES.STOP_LIMIT"
              class="trade-pro__left-panel-block"
            >
              <div class="fs13">
                Цена за единицу <span
                  class="gray"
                > {{ mode == MODES.SALE ? tokenTo?.mnemonicEng : tokenFrom?.mnemonicEng }} </span>
              </div>

              <div class="trade-pro__left-panel-block-balance">
                <AuDecimalInput
                  v-model="valueQuote"
                  :decimal="tokenTo?.decimals"
                  class="swap-input"
                />
              </div>
            </div>

            <div class="buy-wrap">
              <div class="buy-panel">
                <div class="fs13 gray d-flex justify-content-between">
                  <span>Комиссия</span> <span>-</span>
                </div>

                <div class="fs13 gray d-flex justify-content-between">
                  <span>Комиссия <span class="gray99 fs11">Тейкер</span></span> <span>-</span>
                </div>

                <div class="fs13 gray d-flex justify-content-between">
                  <span>Итого</span> <span>-</span>
                </div>
              </div>

              <div
                class="trade-pro__button"
                @click="showAdrDialog"
              >
                {{ mode == MODES.BUY ? 'Купить' : 'Продать' }}
              </div>
            </div>
          </div>
          <div v-else>
            Выберите токены
          </div>
        </div>
      </div>

      <div class="trade-pro__right-panel">
        <div class="trade-pro__right-panel-top">
          <div class="control-panel__trade">
            <div class="control-panel">
              <div class="control-panel-full">
                <div
                  :active="orderType == ORDER_TYPES.LOG"
                  class="control"
                  @click="orderType = ORDER_TYPES.LOG"
                >
                  Журнал
                </div>

                <div
                  :active="orderType == ORDER_TYPES.COUNT"
                  class="control"
                  @click="orderType = ORDER_TYPES.COUNT"
                >
                  Число сделок
                </div>
              </div>
            </div>
            <div class="control-panel__trade-body">
              <DepthOfTrade
                v-if="orderType == ORDER_TYPES.LOG"
                @select="selectToken"
              />
              <DepthOfTradeTotal
                v-if="orderType == ORDER_TYPES.COUNT"
                @select="selectToken"
              />
            </div>
          </div>
          <div class="control-panel__graph">
            <div class="control-panel">
              <div class="control-panel-full">
                <div
                  :active="true"
                  class="control"
                >
                  Цена
                </div>
                <div class="control">
                  Сведения
                </div>
              </div>
            </div>
            <div>
              <TradingViewChart />
            </div>
          </div>
        </div>

        <div class="trade-pro__right-panel-bottom">
          <div class="control-panel">
            <div class="control-panel-full">
              <div
                :active="orderMode == ORDER_MODES.OPEN_ORDERS"
                class="control"
                @click="orderMode = ORDER_MODES.OPEN_ORDERS"
              >
                Открытые ордера
              </div>
              <div
                :active="orderMode == ORDER_MODES.HISTORY_ORDERS"
                class="control"
                @click="orderMode = ORDER_MODES.HISTORY_ORDERS"
              >
                История ордеров
              </div>
              <div
                :active="orderMode == ORDER_MODES.TRANSACTION_HISTORY"
                class="control"
                @click="orderMode = ORDER_MODES.TRANSACTION_HISTORY"
              >
                История сделок
              </div>
              <div
                :active="orderMode == ORDER_MODES.FACILITIES"
                class="control"
                @click="orderMode = ORDER_MODES.FACILITIES"
              >
                Средства
              </div>
            </div>
          </div>

          <div>
            <OrderHistory
              v-if="orderMode == ORDER_MODES.OPEN_ORDERS"
              :show-header="false"
            />
            <TradeHistory
              v-if="orderMode == ORDER_MODES.HISTORY_ORDERS"
              :show-header="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepthOfTrade from "@/components/tradePro/DepthOfTrade";
import DepthOfTradeTotal from "@/components/tradePro/DepthOfTradeTotal";
import OrderHistory from "@/appPages/OrderHistory.vue";
import TradeHistory from "@/appPages/TradeHistory.vue";
import TradeProHeader from "@/components/tradePro/TradeProHeader";
import TradingViewChart from "@/components/tradePro/TradingViewChart";
import { MARKET_TYPES, MODES, ORDER_MODES, ORDER_TYPES } from "@/components/tradePro/marketType";

import moment from "moment/moment";

import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TradeProHeader,
    DepthOfTrade,
    DepthOfTradeTotal,
    TradeHistory,
    OrderHistory,
    TradingViewChart
  },

  data() {
    return {
      swapValue: 0,
      percent: 0,
      valueQuote: 0,
      priceTrigger: 0,
      ethAdr: null,
      MARKET_TYPES: MARKET_TYPES,
      ORDER_TYPES: ORDER_TYPES,
      orderType: 0,
      MODES: MODES,
      mode: 0,
      ORDER_MODES: ORDER_MODES,
      orderMode: 0
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      tokens: "tokens",
      pairs: "pairs",
      ethAddresses: "ethAddresses",
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      tokenIdFrom: "tradePro/tokenIdFrom",
      tokenIdTo: "tradePro/tokenIdTo",
      marketType: "tradePro/marketType",
      isLightTheme: "isLightTheme"
    }),

    style() {
      return {
        "--control-color": this.isLightTheme ? "#FFFFFF" : "var(--card-color)",
        "--control-color-hover": this.isLightTheme ? "#FFFFFFCC" : "#424246"
      };
    },

    amount() {
      if (this.mode == MODES.BUY) {
        let owner = _.find(this.auOwners, o => o.auToken.id == this.tokenIdTo);
        if (owner) {
          return Number(owner.amount).toLocaleString("ru");
        }
      }

      let owner = _.find(this.auOwners, o => o.auToken.id == this.tokenIdFrom);
      if (owner) {
        return Number(owner.amount).toLocaleString("ru");
      }

      return 0;
    },

    amountRes() {
      if (this.mode == MODES.BUY) {
        let owner = _.find(this.auOwners, o => o.auToken.id == this.tokenIdFrom);
        if (owner) {
          return owner.amount;
        }
      }

      let owner = _.find(this.auOwners, o => o.auToken.id == this.tokenIdTo);
      if (owner) {
        return owner.amount;
      }

      return 0;
    },

    tokenFrom() {
      return _.find(this.tokens, { id: this.tokenIdFrom });
    },

    tokenTo() {
      return _.find(this.tokens, { id: this.tokenIdTo });
    },

    pairToken() {
      return _.map(this.pairs, item => ({
        label: item.token.mnemonicEng,
        value: item.token.id,
        image: item.token.logoMain
      }));
    },

    pairTokenQuote() {
      return _.map(this.pairs, item => ({
        label: item.tokenQuote.mnemonicEng,
        value: item.tokenQuote.id,
        image: item.tokenQuote.logoMain
      }));
    },

    modeOrder() {
      return this.mode == MODES.BUY ? "buy" : "sale";
    },

    ethOptions() {
      return this.ethAddresses.map(adr => {
        return {
          value: adr.value
        };
      });
    },

    decimalQuote() {
      const ret = _.find(this.pairs, pair => pair?.token?.id == this.tokenIdTo || pair?.tokenQuote?.id == this.tokenIdTo);
      return ret?.token?.id == this.tokenIdTo ? ret?.token?.decimals : ret?.tokenQuote?.decimals;
    },

    decimalToken() {
      const ret = _.find(this.pairs, pair => pair?.token?.id == this.tokenIdFrom || pair?.tokenQuote?.id == this.tokenIdFrom);
      return ret?.token?.id == this.tokenIdFrom ? ret?.token?.decimals : ret?.tokenQuote?.decimals;
    }
  },

  async mounted() {
    if (_.isEmpty(this.pairs)) {
      await this.getPairs();
    }

    this.setMarketType(MARKET_TYPES.LIMIT);

    if (this.pairs.length >= 1) {
      this.setTokenIdFrom(this.pairs[0].token.id);
      this.setTokenIdTo(this.pairs[0].tokenQuote.id);
    }
  },

  methods: {
    ...mapActions({
      getPairs: "getPairs",
      setMarketType: "tradePro/setMarketType",
      sendTokenEvents: "sendTokenEvents",
      setTokenIdFrom: "tradePro/setTokenIdFrom",
      setTokenIdTo: "tradePro/setTokenIdTo"
    }),

    selectToken(price, total) {
      this.swapValue = (Number(total) * 100).toString();
      this.valueQuote = price;
    },

    changeValue(value) {
      if (Number(value) > Number(this.amountRes)) {
        value = this.amountRes;
      }
      let percent = (value / this.amountRes) * 100;
      this.swapValue = value.toString();

      if (!percent) {
        this.percent = 0;
      }
      else {
        this.percent = Number(percent);
      }
    },

    changePercent(e) {
      this.swapValue = ((this.amountRes / 100) * Number(e.target.value)).toString();
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showAdrDialog() {
      if (this.ethOptions.length === 1) {
        this.ethAdr = _.get(this.ethOptions, "[0].value", null);
        this.doSwap();
      }
      else {
        this.adrDialog = true;
      }
    },

    checkSwap() {
      if (this.tokenFrom === null || this.tokenTo === null) {
        this.showError("Выберите токены для совершения сделки");
        return;
      }
      if (this.swapValue == 0) {
        this.showError("Суммы токенов не должны быть нулевыми");
        return;
      }
      this.doSwap();
    },

    async doSwap() {
      const params = {
        token: `/api/au_tokens/${this.tokenIdFrom}`,
        tokenQuote: `/api/au_tokens/${this.tokenIdTo}`,
        user: `/api/users/${this.$auth.user().id}`,
        createdAt: moment().format("DD.MM.YYYY HH:mm:ss"),
        description: this.modeOrder,
        mode: this.modeOrder,
        priceString: "0",
        address: this.ethAdr,
        isTask: true,
        value: this.swapValue
      };

      if (this.marketType == MARKET_TYPES.MARKET) {
        params.type = "token_order_market_create";
      }
      else if (this.marketType == MARKET_TYPES.LIMIT) {
        params.type = "token_order_limit_create";
        params.valueQuote = this.valueQuote;
      }
      if (this.marketType == MARKET_TYPES.STOP_LIMIT) {
        params.type = "token_order_stop_limit_create";
        params.priceTrigger = this.priceTrigger;
      }

      try {
        await this.sendTokenEvents(params);
        this.showSuccess("Ордер успешно создан");
      }
      catch (e) {
        this.showError("Ошибка при создании ордера");
      }
    }
  }
};
</script>

<style scoped lang="scss">

.control-panel {
  height: 42px;
  display: flex;
  border-top: 1px var(--color-90) solid;
  border-bottom: 1px var(--color-90) solid;
  width: 100%;

  &__trade {
    width: 40%;

    &-body {

    }
  }

  &__graph {
    width: 60%;
  }


  &-left {
    width: 330px;
    display: flex;
  }

  &-mid {
    width: 302px;
    display: flex;
  }

  &-full {
    display: flex;
    flex: 1 1 auto;
  }
}

.control {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: var(--control-color-hover);
  }

  &[active=true] {
    background: var(--control-color);

    &:hover {
      background: var(--control-color-hover);
    }
  }

  border-right: 1px var(--color-90) solid;

  &:last-child {
    border-left: 1px var(--color-90) solid;
  }
}

.trade-pro {
  margin: -20px;

  &__body {
    display: flex;
    height: 100%;
    overflow: auto;
  }

  &__nav-panel {
    height: 100%;
    border-right: 1px var(--color-90) solid;
    border-top: 1px var(--color-90) solid;
  }

  &__left-panel {
    height: 100%;
    width: 330px;

    &-body {
      padding: 20px 25px 0px 25px;
    }

    &-block {
      margin-top: 30px;

      &-balance {
        margin-top: 10px;
        background-color: var(--card-color);
        border-radius: 8px;
        padding: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
      }
    }
  }

  &__right-panel {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-bottom {
      height: 300px;
    }

    &-top {
      display: flex;
    }
  }

  &__button-panel {
    background-color: var(--control-color);
    border-radius: 8px;
    height: 42px;

    display: flex;
    justify-content: space-between;
  }

  &__button {
    background-color: var(--control-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    border-radius: 8px;

    cursor: pointer;

    &_buy {
      &[active=true] {
        background: #279063;
        color: #FFFFFF;
      }
    }

    &_sale {
      &[active=true] {
        background: #883F41;
        color: #FFFFFF;
      }
    }

    &:not([active="true"]) {
      &:hover {
        background-color: var(--control-color-hover);
      }
    }
  }
}

.gray99 {
  color: var(--color-70);
}

.gray {
  color: var(--color-50);
}

.buy-wrap {
  background-color: var(--card-color);
  border-radius: 8px;

  .trade-pro__button {
    height: 44px;
    width: 100%;
    background: var(--color-80);

    &:not([active=true]) {
      &:hover {
        background-color: var(--color-70);
      }
    }
  }
}

.buy-panel {
  background-color: var(--card-color);
  border-radius: 8px;
  padding: 15px;

  margin-top: 35px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  line-height: 14px;
}

.swap-input {
  border: none;
}
</style>