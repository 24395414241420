<template>
  <div class="dex-page">
    <div class="dex-header">
      <div class="dex-logo">
        <a href="/">
          SoluExchange
        </a>
      </div>
      <div class="dex-top-panel">
        <TopPanel />
      </div>
    </div>
    <div class="main-panel">
      <RouterView />
    </div>
  </div>
</template>

<script>
import TopPanel from "@/panels/TopPanel";
export default {
  name: "BlockDex",
  components: { TopPanel }
};
</script>

<style scoped lang="scss">
.dex-page {
  height: 100%;
  overflow: hidden;
}

.dex-header{
  display: flex;
  width: 100vw;
  overflow: hidden;
}

.dex-logo{
  width: 210px;
  padding-left: 25px;
  padding-top: 18px;
  background: var(--main-color);
  border-right: 1px solid var(--color-90);
  border-bottom: 1px solid var(--color-90);

  @media screen and (max-width: 1200px) {
    width: 170px;
  }

  font-size: 20px;

  a {
    color: var(--text-color)
  }
}
.dex-top-panel{
  width: 100%;
}
.main-panel {
  padding: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
}
</style>