<template>
  <div>
    <AuBreadcrumb />
    <div class="card in-assets">
      <div class="in-assets-title">
        Криптоактивы
      </div>
      <span class="in-assets-count">822 494,7 {{ defaultTokenMnemonic }}</span>
      <span class="in-assets-explain">Стоимость</span>
      <div>
        <AuTable
          :columns="columns"
          :items="edditedOwners"
          action-type="Dropdown"
          :icons="companyIcons"
          :action-column="actionColumn"
        >
          <StuffChart
            chart-name="Изменение цены криптоактива"
          />
        </AuTable>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencySupport from "@/help/currencySupport";
import StuffChart from "@/components/StuffChart";
import { mapGetters } from "vuex";

import _ from "lodash";

export default {
  name: "AssetsInvest",
  components: { StuffChart },
  data() {
    return {
      columns: [{ name: this.$t("briefcase.firstColumn") },
        { name: this.$t("briefcase.secondColumn") },
        { name: this.$t("briefcase.thirdColumn") },
        { name: "", align: "end" }],
      currencySup: new CurrencySupport(),
      actionColumn: { icon: ["chevron_down", "chevron_up"] }
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),

    companyIcons() {
      return _.map(_.cloneDeep(this.auOwners), company => {
        company.icon = company.auToken.logoMain || "/svg/companies/" + _.get(company, "icon", "s_logo") + ".svg";
        return company.icon;
      });
    },

    theme() {
      return this.$store.getters.isLightTheme ? "light" : "dark";
    },

    edditedOwners() {
      return _.map(_.cloneDeep(this.auOwners), auOwner => {
        let newAuOwner = {
          companyName: auOwner.auToken.nameEng,
          tockensCount: this.currencySup.getAmount(auOwner.auToken.id),
          tockenCost: auOwner.amount + auOwner.auToken.mnemonicEng
        };
        return newAuOwner;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.in-assets{
  padding: 20px;
  font-family: 'Suisse Intl',serif;
  font-style: normal;
}
.in-assets-title{
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 35px;
}
.in-assets-count{
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.in-assets-explain{
  font-weight: 450;
  font-size: 12px;
  color: var(--color-60);
  margin-bottom: 40px;
}
</style>