<template>
  <div
    class="card briefcase"
  >
    <div class="card__header-line">
      <div class="card__first-line">
        <div class="fs15">
          {{ $t("rightPanel.briefcase.label") }}
        </div>
      </div>

      <div
        v-if="!loading"
        class="card__second-line"
      >
        <div class="balance">
          {{ fullSum }} {{ defaultTokenMnemonic }}
        </div>
      </div>
    </div>

    <div
      v-if="!loading && defaultTokenMnemonic"
      class="card__third-line"
    >
      <Apexchart
        v-if="!chartLoading"
        class="chard"
        :options="options"
        :series="series"
        height="200px"
        @mounted="changeChart"
        @updated="changeChart"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import CurrencySupport from "@/help/currencySupport";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currencySup: new CurrencySupport(),
      loading: false,
      fullSum: 0,
      series: [],
      width: null,
      chartLoading: false
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      tokensDao: "tokensDao",
      tokensUtility: "tokensUtility",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      exchangeBalances: "exchangeBalances"
    }),

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    nonAsset() {
      return [...this.tokensFiat, ...this.tokensGold,
        this.tokensDao, ...this.tokensUtility];
    },

    labels() {
      let resp = [];
      _.forEach(this.auOwners, owner => {
        if (_.find(this.nonAsset, token => token.id == owner.auToken.id)) {
          resp.push(owner.auToken.mnemonicEng);
        }
      });
      resp.push("tokens");
      return resp;
    },

    options: {
      get: function () {
        return {
          labels: this.labels,
          chart: {
            type: "donut"
          },
          pie: {
            expandOnClick: false,
            donut: {
              background: "transparent",
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true
                }
              }
            }
          },
          colors: [
            "#299667",
            "#9720F6",
            "#E2A64D",
            "#489EE1",
            "#294896",
            "#C3B3D0",
            "#E2684D",
            "#504DE2"
          ],
          dataLabels: {
            enabled: false
          },
          tooltip: {
            enabled: true,
            y: {
              formatter(value) {
                return value.toLocaleString("ru") + " " + this.defaultTokenMnemonic;
              }
            }
          },
          legend: {
            show: true,
            position: "left",
            floating: true,
            height: "100%",
            fontSize: "10px",
            fontFamily: "Suisse Intl",
            labels: {
              colors: [this.isLightTheme ? "#080808" : "#FFFFFF"]
            },
            markers: {
              width: 12,
              height: 8
            }
          }
        };
      },
      set: function (newValue) {
        return newValue;
      }
    }
  },

  watch: {
    auOwners: {
      immediate: true,
      handler(value) {
        let tokenSum = 0;
        _.forEach(value, owner => {
          let price = this.currencySup.getCurrency(owner.auToken.id, this.defaultTokenMnemonic);
          price = price * owner.amount;
          price = price / Math.pow(10, owner.auToken.decimals);

          let spotBalance = _.get(_.find(this.exchangeBalances, { token: `/api/au_tokens/${owner.auToken.id}` }), "amount") || "0";

          price = price + Number(spotBalance) / Math.pow(10, owner.auToken.decimals);

          if (_.find(this.nonAsset, token => token.id == owner.auToken.id)) {
            this.series.push(Math.floor(price));
          }
          else {
            tokenSum = tokenSum + price;
          }
        });

        this.series.push(tokenSum);
        this.fullSum = _.sum(this.series).toLocaleString("ru");
      }
    }
  },

  mounted() {
    this.options.tooltip.y.formatter = (value) => {
      return value.toLocaleString("ru") + " " + this.defaultTokenMnemonic;
    };

    window.addEventListener("resize", this.resizeHandler);
  },

  methods: {
    resizeHandler() {
      this.chartLoading = true;
      this.$nextTick(() => {
        this.chartLoading = false;
      });
    },
    changeChart(chartContext, _config) {
      this.$nextTick(() => {
        chartContext.pie.centerX = 150;
        //chartContext.update();
        //chartContext.render();
      });
    }
  }
};
</script>


<style lang="scss">
.briefcase {
  .apexcharts-legend.apx-legend-position-right,
  .apexcharts-legend.apx-legend-position-left {
    height: 100% !important;
    top: 0px !important;
  }

  .apexcharts-legend {
    width: 100px;
  }
}
</style>

<style lang="scss" scoped>
.balance {
  font-weight: 500;
  font-size: 25px;
  line-height: 100%;
  white-space: nowrap;

  color: #5ac396;
}

.loader-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.card {
  width: 310px;
  height: 312px;

  @media screen and (max-width: 1200px) {
    height: 315px;
    width: 100%;
  }

  @media screen and (max-width: 840px) {
    height: 315px;
  }

  padding: 20px;

  &__first-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__second-line {
  }

  &__third-line {
    margin-left: -15px;
  }
}

.briefcase{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
