<template>
  <span>{{ displayValue }}&nbsp;{{ addition ? addition : "" }}</span>
</template>

<script>
export default {
  name: "AuDecimalStatic",

  props: {
    value: {
      type: [String, Number],
      default: ""
    },

    decimal: {
      type: Number,
      default: 0
    },

    indent: {
      type: Number,
      default: 2
    },

    //Валюта
    addition: {
      type: String,
      default: ""
    },

    //Возможность разделять цифры пробелом
    formatValue: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    displayValue() {
      if (this.formatValue) {
        return Number(this.formatedValue).toLocaleString("ru", { maximumSignificantDigits: 20 }).replace(",", ".");
      }
      else {
        return this.formatedValue.replace(",", ".");
      }
    },

    formatedValue() {
      if (!this.indent) {
        return this.value;
      }
      else {
        let value = this.value ? this.value.toString().replace(",", ".") : 0;
        return (Number(value) / Math.pow(10, this.decimal)).toFixed(this.indent);
      }
    }
  }
};

</script>

<style scoped>

</style>