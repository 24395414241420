import * as validators from "@vuelidate/validators";
import i18n from "./localization.js";

const { createI18nMessage } = validators;

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });
const _containsUppercase = (value) => /[A-Z]/.test(value);
const _containsLowercase = (value) => /[a-z]/.test(value);
const _containsNumber = (value) => /[0-9]/.test(value);

export const requiredPassword = withI18nMessage(validators.required);
export const containsUppercase = withI18nMessage(_containsUppercase);
export const containsLowercase = withI18nMessage(_containsLowercase);
export const containsNumber = withI18nMessage(_containsNumber);
export const requiredEmail = withI18nMessage(validators.required);
export const email = withI18nMessage(validators.email);
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });