<template>
  <div
    class="main-panel"
    :no-column-reverse="noColumnReverse"
  >
    <div
      :adaptive="$route.meta.adaptiveRightPanel"
      class="main-panel__center"
    >
      <RouterView />
      <div class="footer">
        <News v-if="!$route.meta.hideNews" />
        <Footer />
      </div>
    </div>
    <RightPanel />
  </div>
</template>

<script>
import Footer from "@/panels/Footer.vue";
import News from "@/panels/News.vue";
import RightPanel from "@/panels/RightPanel.vue";

export default {
  components: {
    RightPanel,
    Footer,
    News
  },

  computed: {
    noColumnReverse() {
      return this.$route.meta?.noColumnReverse;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
}

.main-panel {
  display: flex;

  @media screen and (max-width: 1200px) {
    // flex-direction: column-reverse; //old version
    flex-direction: column-reverse;
    overflow: auto;
  }

  &[no-column-reverse] {
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  &__center {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: auto;
    padding: 25px 30px 15px 25px;
    background-color: var(--main-color);
    @media screen and (max-width: 840px) {
      padding: 25px 15px 15px 15px;
    }

    @media screen and (max-width: 1200px) {
      overflow-x: unset;
    }

    &:not([adaptive="true"]) {
      @media screen and (max-width: 1200px) {
        overflow-x: auto;
      }
    }
  }
}
</style>
