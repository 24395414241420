<template>
  <div class="oh-main">
    <div
      v-if="showHeader"
      class="oh-title d-flex justify-content-between"
    >
      <span>Ваши ордера</span>
    </div>
    <div class="d-flex mb-3">
      <div
        class="oh-tab me-3"
        :class="{'oh-checked': isOpen}"
        @click="getOpen"
      >
        Открытые
      </div>
      <div
        class="oh-tab"
        :class="{'oh-checked': !isOpen}"
        @click="getClose"
      >
        Закрытые
      </div>
    </div>

    <AuTable
      :columns="columns"
      :items="elements"
      actiontype="Simple"
      :action-column="actionColumn"
      :loading="loading"
      color-order
      @click="cancelOrder"
    />

    <AuPagination
      v-if="totalItems > 30"
      :total-items="totalItems"
      @pageChanged="pageChanged"
    />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OrderHistory",

  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      page: 1,
      totalItems: null,
      loading: false,
      isOpen: true,
      columns: [{ name: "Цена" },
        { name: "Действие" },
        { name: "Количество" },
        { name: "Заполнено" },
        { name: "Тип ордера" },
        { name: "Время создания" },
        { name: "Всего" },
        { name: "Статус" }],
      actionColumn: { label: "Отменить", icon: "close_red" },
      orderType: {
        token_order_market_create: "Рынок",
        token_order_limit_create: "Лимитный"
      }
    };
  },

  computed: {
    ...mapGetters({
      orders: "orders",
      tokens: "tokens"
    }),

    elements() {
      return this.orders.map(item => {
        const token = this.tokens.find(t => t.id == this.getIdByIRI(item.token));
        const tokenQuote = this.tokens.find(t => t.id == this.getIdByIRI(item.tokenQuote));

        if (!tokenQuote) {
          return {};
        }

        let clearPrice = _.get(item, "priceString", 0);
        clearPrice = Number(Number(clearPrice) / Math.pow(10, tokenQuote.decimals)).toFixed(tokenQuote.indent);
        let price = clearPrice + ` ${tokenQuote.mnemonicEng}`;

        let amount = _.get(item, "amount", 0);
        amount = Number(Number(amount) / Math.pow(10, tokenQuote.decimals)).toFixed(tokenQuote.indent);
        let amountExecuted = _.get(item, "amountExecuted", 0);
        amountExecuted = Number(Number(amountExecuted) / Math.pow(10, tokenQuote.decimals)).toFixed(tokenQuote.indent);
        let total = (clearPrice * amount).toFixed(tokenQuote.indent) + ` ${tokenQuote.mnemonicEng}`;

        return {
          price: price,
          mode: item.mode,
          amount: amount + ` ${token.mnemonicEng}`,
          amountExecuted: amountExecuted,
          type: _.get(this.orderType, item?.type, "-"),
          createdAtTime: moment(item?.createdAt).format("HH:mm:ss DD.MM.YYYY"),
          total: total,
          status: item.isFinish ? (item?.isCancelRequest ? "Отменен" : "Закрыт") : "Открыт"
        };
      });
    }
  },

  async mounted() {
    this.isOpen = true;
    this.loading = true;
    this.totalItems = await this.getOrders({ page: this.page, isFinish: !this.isOpen });
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getOrders: "getOrders",
      sendTokenEvents: "sendTokenEvents"
    }),

    getIdByIRI(iri) {
      if (_.isNil(iri)) {
        return -1;
      }
      const slashPos = iri.lastIndexOf("/") + 1;
      if (slashPos === 0) {
        return -1;
      }
      return iri.slice(slashPos);
    },

    async cancelOrder(index) {
      const params = {
        auOrder: `/api/au_orders/${this.orders[index].id}`,
        user: `/api/users/${this.$auth.user().id}`,
        createdAt: moment().format("DD.MM.YYYY hh:mm:ss"),
        type: "token_order_cancel",
        description: "token_order_cancel",
        value: "",
        address: "",
        isTask: true
      };
      try {
        await this.sendTokenEvents(params);
        this.showSuccess("Событие на отмену ордера успешно создано");
      }
      catch (e) {
        this.showError("Ошибка отмены ордера");
      }
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    async getOpen() {
      this.loading = true;
      this.isOpen = true;
      this.totalItems = await this.getOrders({ page: 1, isFinish: !this.isOpen });
      this.loading = false;
    },

    async getClose() {
      this.loading = true;
      this.isOpen = false;
      this.totalItems = await this.getOrders({ page: 1, isFinish: !this.isOpen });
      this.loading = false;
    },

    async pageChanged(actualPage) {
      this.loading = true;
      this.page = actualPage;
      this.totalItems = await this.getOrders({ page: this.page, isFinish: !this.isOpen });
      this.loading = false;
    }

  }
};
</script>

<style scoped lang="scss">
.oh-main{
  margin-bottom: 40px;
}
.oh-title{
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
}
.oh-text-data{
  font-weight: 450;
  font-size: 12px;
  color: var(--color-60);
}
.oh-button-text{
  font-weight: 400;
  font-size: 12px;
}
.oh-divider-bold{
  border-bottom: 3px solid var(--color-70);
}
.oh-divider{
  border-bottom: 1px solid var(--color-70);
}
.oh-tab{
  display: flex;
  padding: 10px;
  font-size: 12px;
  border-radius: 9px;
  color: var(--color-60);
  background: var(--color-90);
  cursor: pointer;
}
.oh-checked{
  color: var(--text-color);
  background: var(--menu-color);
}
</style>