<template>
  <div class="card">
    <div class="card__header">
      <div class="fs15">
        {{ $t("dashboard.transactions.label") }}
      </div>

      <a
        href="/wallet/transactions"
        class="card__action"
      >
        {{ $t("dashboard.transactions.goTo") }}
        <AuIcon icon="chevron_right" />
      </a>
    </div>

    <div class="period">
      Вчера
    </div>

    <div class="transactions">
      <LastTransaction
        v-for="(transaction, index) in transactions"
        :key="index"
        class="transaction"
        :icon="transaction.icon"
        :name="transaction.name"
        :hash="transaction.hash"
        :address-eth="transaction.addressEth"
        :address-from="transaction.addressFrom"
        :address-to="transaction.addressTo"
        :transaction-type="transaction.type"
        :amount="transaction.amount"
        :is-refill="transaction.isRefill"
        :card-type="transaction.cardType"
        :decimals="transaction.decimals"
        :mnemonic="transaction.mnemonic"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import LastTransaction from "./LastTransaction.vue";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    LastTransaction
  },

  data() {
    return {
      transactions: [],
      addressEth: null,
      totalItems: 1
    };
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses",
      contacts: "marketplace/userContact"
    })
  },

  async mounted() {
    await this.getTransactions();
    await this.getUserContact({ page: 1, itemsPerPage: 30 });
  },

  methods: {
    ...mapActions({
      getTransactionsPage: "getTransactionsPage",
      getUserContact: "marketplace/getUserContact"
    }),

    async getTransactions() {
      const resp = await this.getTransactionsPage({ pageId: 1, sort: "desc" });
      const allData = resp.data;
      this.totalItems = resp.totalItems;

      allData.forEach((transaction) => {
        if (_.isUndefined(transaction.auToken)) {
          return;
        }

        this.arrEth.forEach((eth) => {
          if ((eth.value).toLowerCase() === (transaction.addressFrom).toLowerCase()) {
            this.addressEth = transaction.addressTo;
          }
          if ((eth.value).toLowerCase() === (transaction.addressTo).toLowerCase()) {
            this.addressEth = transaction.addressFrom;
          }
          if (transaction.addressFrom == 0) {
            this.addressEth = transaction.addressTo;
          }
        });
        let type = _.find(this.contacts, contact => contact.address == transaction.addressTo);
        type = _.get(type, "name", transaction.addressFrom == 0 ? "Эмиссия" : "Перевод");
        this.transactions.push({
          icon: transaction?.auToken?.logoMain,
          name:
            this.addressEth.slice(0, 4) +
            "..." +
            this.addressEth.slice(-4),
          type: type,
          addressFrom: transaction.addressFrom,
          addressTo: transaction.addressTo,
          addressEth: this.addressEth,
          amount: transaction.amount,
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill:
            transaction?.auToken?.id == transaction?.addressFrom?.auToken?.id,
          decimals: transaction?.auToken?.decimals,
          mnemonic: transaction?.auToken?.mnemonicEng,
          createdAt: transaction.createdAt,
          hash: transaction.hash
        });
      });
      this.transactions.sort((a, b) => {
        const diff = moment(b.createdAt).unix() - moment(a.createdAt).unix();
        if (diff > 0) {
          return 1;
        }
        if (diff < 0) {
          return -1;
        }
        return 0;
      });
      this.transactions.length = 4;
    }
  }
};
</script>

<style scoped lang="scss">
.period {
  margin: 15px 0px;
  font-size: 12px;
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.indention {
  margin-top: 50px;
}

.card {
  width: 100%;
  height: 305px;
  padding: 15px 22px 22px 22px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 11px;
  }

  &__action {
    font-size: 11px;
    color: var(--color-40);
    text-decoration: none;
  }
}
</style>
