<template>
  <Chart
    v-if="!loading"
    :options="options"
  />
</template>

<script>
import { Chart } from "vue-tradingview-widgets";
import { mapGetters } from "vuex";

export default {
  components: {
    Chart
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      language: "language"
    }),

    options() {
      return {
        theme: this.isLightTheme ? "light" : "dark",
        height: "450px",
        width: "100%",
        locale: this.language
      };
    }
  },

  watch: {
    async isLightTheme() {
      this.loading = true;
      await this.$nextTick();
      this.loading = false;
    }
  }
};
</script>