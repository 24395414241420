import actions from "./actions";

export default {
  namespaced: true,
  state: {
    nfts: [],
    units: [],
    dataLoading: false
  },

  getters: {
    nfts: state => {
      return state.nfts;
    },

    units: state => {
      return state.units;
    },

    dataLoading: state => {
      return state.dataLoading;
    }
  },

  mutations: {
    setNfts(state, nfts) {
      state.nfts = nfts;
    },

    setUnits(state, units) {
      state.units = units;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    }
  },

  actions
};
