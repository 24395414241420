<template>
  <div>
    <AuBreadcrumb />

    <!-- <div class="title">
      {{ $t("briefcase.label") }}
    </div> -->

    <AuTabView>
      <AuTabPanel :title="$t('briefcase.fiatTabLabel')">
        <AuTable
          class="briefcase-table"
          :columns="columns"
          :items="fiatOwners"
          :action-column="actionColumn"
          action-type="contextMenu"
          :loading="loading"
          @send="send"
        >
          <template #company="{ value }">
            <div
              class="link"
              @click="toLink(value.link)"
            >
              <img
                :src="value.icon"
                class="icon-with-bg"
                alt=""
              >
              {{ value.name }}
            </div>
          </template>
          <template #tokensCount="{ value }">
            <div class="split-balance">
              <AuDecimalStatic
                :value="value.crypto"
                :addition="value.mnemonic"
                :decimal="value.decimals"
              />
              <div
                v-if="value.spot != '0'"
                class="spot-balance"
              >
                Спот:
                <AuDecimalStatic
                  :value="value.spot"
                  :addition="value.mnemonic"
                  :decimal="value.decimals"
                />
              </div>
            </div>
          </template>
          <template #tokenSum="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimals"
            />
          </template>
        </AuTable>
        <AuPagination
          v-if="totalItems > 30"
          :total-items="totalItems"
          @pageChanged="pageChanged"
        />
      </AuTabPanel>
      <AuTabPanel :title="$t('briefcase.cryptoTabLabel')">
        <AuTable
          class="briefcase-table"
          :columns="columns"
          :items="assetsOwners"
          :action-column="actionColumn"
          action-type="contextMenu"
          :loading="loading"
          @send="send"
        >
          <template #company="{ value }">
            <div
              class="link"
              @click="toLink(value.link)"
            >
              <img
                :src="value.icon"
                class="icon-with-bg"
                alt=""
              >
              {{ value.name }}
            </div>
          </template>
          <AuPagination
            v-if="totalItems > 30"
            :total-items="totalItems"
            @pageChanged="pageChanged"
          />
        </Autable>
      </AuTabPanel>
      <AuTabPanel :title="$t('briefcase.investTabLabel')">
        <AuTable
          class="briefcase-table"
          :columns="columns"
          :items="companyOwners"
          :action-column="actionColumn"
          action-type="contextMenu"
          :loading="loading"
          @send="send"
        >
          <template #company="{ value }">
            <div
              class="link"
              @click="toLink(value.link)"
            >
              <img
                :src="value.icon"
                class="icon-with-bg"
                alt=""
              >
              {{ `${value.name} (${value.mnemonic})` }}
            </div>
          </template>
          <AuPagination
            v-if="totalItems > 30"
            :total-items="totalItems"
            @pageChanged="pageChanged"
          />
        </Autable>
      </AuTabPanel>
    </AuTabView>

    <div class="briefcase-accordion">
      <AuAccordion
        v-for="(owner, index) in edditedOwners"
        :key="owner"
        :last-child="(index == edditedOwners.length - 1)"
      >
        <template #title>
          <span class="company-header">{{ (index + 1) }}</span>
        </template>
        <template #content>
          <div class="company-info container-fluid">
            <div class="row">
              <div class="col company-info-title">
                <span>
                  {{ $t("briefcase.firstColumn") }}
                </span>
              </div>
              <div class="col">
                <img
                  class="company-icon"
                  :src="companyIcons[index]"
                  :alt="owner.companyName"
                >
                <span>{{ owner.companyName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col company-info-title">
                <span>
                  {{ $t("briefcase.secondColumn") }}
                </span>
              </div>
              <div class="col">
                <span>{{ owner.tokensCount }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col company-info-title">
                <span>
                  {{ $t("briefcase.thirdColumn") }}
                </span>
              </div>
              <div class="col">
                <span>{{ owner.tockenCost }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <AuButton
                  width="100%"
                  center
                  @click="send(index)"
                >
                  {{ $t("briefcase.sendAction") }}
                </AuButton>
              </div>
            </div>
          </div>
        </template>
      </AuAccordion>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import AuTabPanel from "@/components/extended/tabs/AuTabPanel";
import AuTabView from "@/components/extended/tabs/AuTabView";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { AuTabPanel, AuTabView },
  data() {
    return {
      actionColumn: { label: "···", icon: "more" },
      currencySup: new CurrencySupport(),
      goldBalances: [],
      page: 1,
      totalItems: 0,
      auOwners: [],
      loading: true
    };
  },

  computed: {
    ...mapGetters({
      tokensGold: "tokensGold",
      balancesGold: "balancesGold",
      tokensFiat: "tokensFiat",
      tokensUtility: "tokensUtility",
      tokens: "tokens",
      exchangeBalances: "exchangeBalances",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      defaultTokenId: "defaultToken/defaultTokenId"
    }),

    columns() {
      return [{ name: this.$t("briefcase.firstColumn"), align: "start", slot: "company" },
        { name: this.$t("briefcase.secondColumn"), slot: "tokensCount" },
        { name: `${this.$t("briefcase.thirdColumn")} (${this.defaultTokenMnemonic})` },
        { name: `${this.$t("briefcase.sumColumn")} (${this.defaultTokenMnemonic})`, slot: "tokenSum" }];
    },

    goldDecimal() {
      return this.tokensGold.find(token => token.mnemonicEng == process.env.VUE_APP_GOLD)?.decimals;
    },

    fiatOwners() {
      let ret = [];
      _.forEach(this.auOwners, auOwner => {
        const token = _.find(this.tokensFiat, fiat => fiat.id === auOwner.auToken.id);
        const defaultToken = _.find(this.tokens, token => token.id == this.defaultTokenId);
        if (!_.isUndefined(token)) {
          const count = BigNumber(auOwner.amount).dividedBy(BigNumber(10).pow(auOwner.auToken.decimals));
          let currency = this.currencySup.getCurrency(auOwner.auToken.id, this.defaultTokenMnemonic);
          currency = currency ? currency.toString().replace(",", ".") : 0;
          currency = Number(currency).toFixed(auOwner.auToken.indent);

          let spotBalance = _.get(_.find(this.exchangeBalances, { token: `/api/au_tokens/${token.id}` }), "amount") || "0";

          let sum = count.multipliedBy(currency);
          sum = sum ? sum.toString().replace(",", ".") : 0;
          sum = Number(sum) + Number(spotBalance);
          ret.push({
            company: {
              icon: auOwner.auToken.logoMain || "/svg/companies/" + _.get(auOwner, "icon", "s_logo") + ".svg",
              link: auOwner.auToken.id,
              name: auOwner.auToken.nameEng
            },
            tokensCount: {
              crypto: count.toString(10),
              spot: spotBalance,
              decimals: token.decimals,
              mnemonic: token.mnemonicEng
            },
            tockenCost: currency,
            tokenSum: {
              value: sum.toString(10),
              decimals: defaultToken.decimals
            }
          });
        }
      });

      return ret;
    },

    assetsOwners() {
      let ret = [];
      _.forEach(this.auOwners, auOwner => {
        const token = _.find([...this.tokensGold, ...this.tokensUtility],
          fiat => fiat.id === auOwner.auToken.id);
        if (!_.isUndefined(token)) {
          const count = BigNumber(auOwner.amount).dividedBy(BigNumber(10).pow(auOwner.auToken.decimals));
          let currency = this.currencySup.getCurrency(auOwner.auToken.id, this.defaultTokenMnemonic);
          currency = currency ? currency.toString().replace(",", ".") : 0;
          currency = Number(currency).toFixed(auOwner.auToken.indent);

          let sum = count.multipliedBy(currency);
          sum = sum ? sum.toString().replace(",", ".") : 0;
          sum = Number(sum).toFixed(auOwner.auToken.indent);
          ret.push({
            company: {
              icon: auOwner.auToken.logoMain || "/svg/companies/" + _.get(auOwner, "icon", "s_logo") + ".svg",
              link: auOwner.auToken.id,
              name: auOwner.auToken.nameEng
            },

            tokensCount: count.toString(10),
            tockenCost: currency + ` (${this.defaultTokenMnemonic})`,
            tokenSum: sum.toString(10)
          });
        }
      });
      return ret;
    },

    companyOwners() {
      let ret = [];
      _.forEach(this.auOwners, auOwner => {
        const token = _.find(this.tokens, fiat => fiat.id === auOwner.auToken.id);
        if (_.isUndefined(token)) {
          const count = BigNumber(auOwner.amount).dividedBy(BigNumber(10).pow(auOwner.auToken.decimals));
          let currency = this.currencySup.getCurrency(auOwner.auToken.id, this.defaultTokenMnemonic);
          currency = currency ? currency.toString().replace(",", ".") : 0;
          currency = Number(currency).toFixed(auOwner.auToken.indent);

          let sum = count.multipliedBy(currency);
          sum = sum ? sum.toString().replace(",", ".") : 0;
          sum = Number(sum).toFixed(auOwner.auToken.indent);
          ret.push({
            company: {
              icon: auOwner.auToken.logoMain || "/svg/companies/" + _.get(auOwner, "icon", "s_logo") + ".svg",
              link: auOwner.auToken.id,
              mnemonic: auOwner.auToken.mnemonicEng,
              name: auOwner.auToken.nameEng
            },
            tokensCount: count.toString(10),
            tockenCost: currency + ` (${this.defaultTokenMnemonic})`,
            tokenSum: sum.toString(10)
          });
        }
      });
      return ret;
    }
  },

  async mounted() {
    this.auOwners.forEach(owner => {
      const fullName = this.currencySup.getFullName(owner.auToken.id);
      let amount = this.balancesGold.find(token => token.auToken == fullName);
      const fmt = {
        prefix: "",
        decimalSeparator: "",
        groupSeparator: "",
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: " ",
        fractionGroupSize: 0,
        suffix: ""
      };
      amount = _.isUndefined(amount) ? 0 : BigNumber(amount.amountWithRate).toFormat(this.goldDecimal, 0, fmt);
      this.goldBalances[owner.auToken.id] = amount;
    });
    await this.getDataPage();
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getOwnerPage: "getOwnerPage"
    }),

    toLink(link) {
      this.$router.push({ name: "token info", params: { id: link } });
    },

    send(index) {
      this.$router.push({ name: "send token", params: { id: this.auOwners[index].id } });
    },

    async getDataPage() {
      const resp = await this.getOwnerPage({ page: this.page });
      this.auOwners = resp.data;
      this.totalItems = resp.totalItems;
    },

    async pageChanged(actualPage) {
      this.page = actualPage;
      await this.getDataPage();
    }
  }
};
</script>

<style lang="scss" scoped>
.briefcase {
  &-info {
    padding: 11px 20px 0 18px;

    .title {
      font-weight: 600;
      font-size: 24px;
      color: var(--text-color);
      margin-bottom: 30px;
    }
  }

  &-table {
    @media screen and (max-width:520px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:520px) {
      display: block;
    }
  }
}

.spot-balance {
  color: var(--color-50);
  font-weight: 500;
  font-size: 12px;
}

.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }

  &-info {
    color: var(--text-color);
    display: flex;
    padding-right: 0;
    padding-left: 0;
    flex-direction: column;
    gap: 20px;

    &-title {
      color: var(--color-60)
    }

    .row {
      font-weight: 450;
      font-size: 12px;

      .col:last-child {
        text-align: end;
      }
    }
  }

  &-icon {
    width: 26px;
    height: 26px;
    margin-right: 5px;
  }
}

.icon-with-bg {
  width: 26px;
  height: 26px;
  margin-right: 8px;
  // background: var(--color-00);
  border-radius: 7px;
  padding: 4px;
}

.link {
  &:hover {
    cursor: pointer;
  }
}
</style>
