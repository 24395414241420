import actions from "./actions";

export default {
  namespaced: true,
  state: {
    daoRequests: null,
    daoRequestsTotal: null,
    daoElements: null,
    daoEvents: null,
    daoEventsTotal: null,
    dataLoading: false,
    token: null
  },

  getters: {
    daoRequests: state => {
      return state.daoRequests;
    },

    daoRequestsTotal: state => {
      return state.daoRequestsTotal;
    },

    daoElements: state => {
      return state.daoElements;
    },

    daoEvents: state => {
      return state.daoEvents;
    },

    daoEventsTotal: state => {
      return state.daoEventsTotal;
    },

    dataLoading: state => {
      return state.dataLoading;
    },

    token: state => {
      return state.token;
    }
  },

  mutations: {
    setDaoRequests(state, daoRequests) {
      state.daoRequests = daoRequests;
    },

    setDaoRequestsTotal(state, daoRequestsTotal) {
      state.daoRequestsTotal = daoRequestsTotal;
    },

    setDaoElements(state, daoElements) {
      state.daoElements = daoElements;
    },

    setDaoEvents(state, daoEvents) {
      state.daoEvents = daoEvents;
    },

    setDaoEventsTotal(state, daoEventsTotal) {
      state.daoEventsTotal = daoEventsTotal;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    },

    setToken(state, token) {
      state.token = token;
    }
  },

  actions
};