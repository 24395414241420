<template>
  <div class="news card">
    <div class="line">
      <div class="head_text">
        {{ $t("news.form.label") }}
      </div>
    </div>
    <div class="line">
      <AuInput
        :model-value="image"
        type="File"
        :label="$t('news.form.imageLabel')"
        required
        :uploaded-file-name="uploadedFileName"
        @update:modelValue="imageChanged"
      />
    </div>
    <div class="line">
      <AuInput
        v-model="news.title"
        :placeholder="$t('news.form.titlePlaceholder')"
        required
        :label="$t('news.form.titleLabel')"
        clearable
      />
    </div>
    <div class="line">
      <AuInput
        v-model="news.subtitle"
        :placeholder="$t('news.form.subtitlePlaceholder')"
        required
        :label="$t('news.form.subtitleLabel')"
        clearable
      />
    </div>
    <div class="line">
      <AuInput
        v-model="news.content"
        :placeholder="$t('news.form.contentPlaceholder')"
        required
        :label="$t('news.form.contentLabel')"
        clearable
        :type="'TextArea'"
      />
    </div>
    <div class="line">
      <AuSelect
        :value="news.auToken"
        :options="tokensOptions"
        required
        clearable
        :select-label="$t('news.form.auTokenLabel')"
        :placeholder="$t('news.form.auTokenPlaceholder')"
        @input="value => news.auToken = value"
      />
    </div>
    <div class="d-flex justify-content-between">
      <AuButton
        bordered
        center
        class="text publish-btn"
        :height="'44px'"
        :width="'120px'"
        :border-radius="18"
        @click="goBack"
      >
        {{ $t("news.form.cancelButton") }}
      </AuButton>
      <div class="btns-wrapper d-flex justify-content-sm-end">
        <AuButton
          bordered
          center
          class="text publish-btn"
          :height="'44px'"
          :width="'146px'"
          :border-radius="18"
          @click="publishNews"
        >
          {{ $t("news.form.publishNewsButton") }}
        </AuButton>
        <AuButton
          bordered
          center
          class="text draft-btn"
          :height="'44px'"
          :width="'136px'"
          :border-radius="18"
          @click="addToDraft"
        >
          {{ $t("news.form.addToDraftButton") }}
        </AuButton>
      </div>
    </div>
    <div
      v-if="reqError"
      class="req-error"
    >
      {{ $t("news.form.error") }}
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import { v4 } from "uuid";

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      news: {
        locale: this.$i18n.locale == "ru" ? "rus" : "eng",
        title: null,
        subtitle: null,
        content: null,
        image: null,
        auToken: null,
        isDraft: null,
        author: `/api/users/${this.$auth.user().id}`,
        slug: v4().split("-")[0],
        dateForNews: moment().format("DD.MM.YYYY hh:mm:ss")
      },
      image: null,
      uploadedFileName: null,
      reqError: false
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      blogPages: "news/blogPages",
      uploadedFile: "files/uploadedFile"
    }),

    tokensOptions() {
      let usersTokens = _.filter(_.cloneDeep(this.tokens), { owner: `/api/users/${this.$auth.user().id}` });
      return _.map(usersTokens, token => ({ display: token.nameEng, value: `/api/au_tokens/${token.id}` }));
    },

    checkFields() {
      let check = Object.values(this.news).length == _.filter(Object.values(this.news), value => value !== null && value !== "").length ? true : false;
      return check;
    },

    newsId() {
      return this.$attrs.id;
    },

    editMode() {
      return this.newsId != "new";
    }
  },

  async mounted() {
    if (this.editMode) {
      let newsForEdit = _.find(this.blogPages, { id: Number(this.newsId) });

      await this.getUploadedFile(newsForEdit.image.split("/")[newsForEdit.image.split("/").length - 1]);

      this.uploadedFileName = this.uploadedFile.nameOriginal;
      this.news.locale = newsForEdit.locale;
      this.news.title = newsForEdit.title;
      this.news.subtitle = newsForEdit.subtitle;
      this.news.content = newsForEdit.content;
      this.news.auToken = newsForEdit.auToken;
      this.news.isDraft = newsForEdit.isDraft;
      this.news.slug = newsForEdit.slug;
      this.news.dateForNews = newsForEdit.dateForNews;
    }
  },

  methods: {
    ...mapActions({
      postBlogPage: "news/postBlogPage",
      uploadFile: "files/uploadFile",
      getUploadedFile: "files/getUploadedFile",
      patchBlogPage: "news/patchBlogPage"
    }),

    imageChanged(file) {
      this.uploadedFileName = null;
      this.news.image = file;
    },

    async publishNews() {
      this.news.isDraft = false;
      try {
        if (!this.editMode) {
          if (this.news.image === null) {
            this.reqError == true;
          }
          else if (typeof this.news.image !== "string") {
            let file = await this.uploadFile(this.news.image);
            this.news.image = `/api/uploaded_files/${file.id}`;
          }
          if (this.checkFields) {
            this.reqError = false;
            await this.postBlogPage(this.news);

            this.$toast.success(this.$t("toasts.success.newsPublish"), {
              position: "bottom-right",
              duration: 4000,
              dismissible: true
            });
            this.$router.push({ name: "news" });
          }
          else {
            this.reqError = true;
          }
        }
        else {
          if (!this.uploadedFileName) {
            let file = await this.uploadFile(this.news.image);
            this.news.image = `/api/uploaded_files/${file.id}`;
          }
          else {
            this.news.image = `/api/uploaded_files/${this.uploadedFile.id}`;
          }
          let newsCopy = _.cloneDeep(this.news);
          newsCopy.id = Number(this.newsId);
          if (this.checkFields) {
            this.reqError = false;
            await this.patchBlogPage(newsCopy);

            this.$toast.success(this.$t("toasts.success.newsUptPublish"), {
              position: "bottom-right",
              duration: 4000,
              dismissible: true
            });
            this.$router.push({ name: "news" });
          }
          else {
            this.reqError = true;
          }
        }
      }
      catch (error) {
        this.$toast.error(this.$t("toasts.error.newsPublish"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    },

    goBack() {
      this.$router.push({ name: "news" });
    },

    async addToDraft() {
      this.news.isDraft = true;
      try {
        if (!this.editMode) {
          if (this.news.image === null) {
            this.reqError == true;
          }
          else if (typeof this.news.image !== "string") {
            let file = await this.uploadFile(this.news.image);
            this.news.image = `/api/uploaded_files/${file.id}`;
          }
          if (this.checkFields) {
            this.reqError = false;
            await this.postBlogPage(this.news);

            this.$toast.success(this.$t("toasts.success.newsDraft"), {
              position: "bottom-right",
              duration: 4000,
              dismissible: true
            });
            this.$router.push({ name: "news" });
          }
          else {
            this.reqError = true ;
          }
        }
        else {
          if (!this.uploadedFileName) {
            let file = await this.uploadFile(this.news.image);
            this.news.image = `/api/uploaded_files/${file.id}`;
          }
          else {
            this.news.image = `/api/uploaded_files/${this.uploadedFile.id}`;
          }
          let newsCopy = _.cloneDeep(this.news);
          newsCopy.id = Number(this.newsId);
          if (this.checkFields) {
            this.reqError = false;
            await this.patchBlogPage(newsCopy);

            this.$toast.success(this.$t("toasts.success.newsUptDraft"), {
              position: "bottom-right",
              duration: 4000,
              dismissible: true
            });
            this.$router.push({ name: "news" });
          }
          else {
            this.reqError = true;
          }
        }
      }
      catch (error) {
        this.$toast.error(this.$t("toasts.error.newsDraft"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    }

  }
};
</script>

<style lang="scss" scoped>
.news{
  padding: 20px 20px 30px 20px;
  font-family: 'Suisse Intl',serif;
  font-style: normal;
  display: flex;
  gap: 30px;
}
.head_text {
  font-weight: 600;
  font-size: 24px;
}
.text {
  font-weight: 400;
  font-size: 14px;
}
.req-error {
  display: flex;
  justify-content: center;
  color: red;
}
.btns-wrapper {
  gap: 10px
}
</style>