<template>
  <div
    v-click-outside="() => hideMenu()"
    class="profile"
  >
    <div class="profile__person">
      <AuButton
        v-if="!mmInstalled"
        width="175"
        height="42px"
        active-bg-color="#279063"
        hovered-bg-color="#279063aa"
      >
        <a
          class="pucket-button"
          href="https://metamask.io/download"
          target="_blank"
        >
          {{ $t("profile.connectWallet") }}
        </a>
      </AuButton>
      <AuButton
        v-if="mmInstalled && !accountAddress"
        width="175"
        height="42px"
        active-bg-color="#279063"
      >
        <span
          class="pucket-button"
          @click.prevent="connectMetamaskAction"
        >
          {{ $t("profile.connectWithWallet") }}
        </span>
      </AuButton>
      <AuButton
        v-if="mmInstalled && accountAddress"
        center
        bordered
        width="100"
      >
        <div class="btn_metamask">
          <div
            class="metamask"
          />
          <div>
            {{ $filters.shortAddress(accountAddress) }}
          </div>
        </div>
      </AuButton>

      <div class="d-flex">
        <AuIcon
          icon="notification"
          :clickable="true"
          @click="setChatVisible(!isChatVisible)"
        />
        <div v-if="unviewedMessageCount !== 0">
          {{ `(${unviewedMessageCount})` }}
        </div>
      </div>

      <AuIcon
        icon="profile"
        :clickable="true"
        @click="toggleMenu"
      />
    </div>

    <ProfileMenu
      v-if="profileMenu"
      class="menu"
    />

    <AuIcon
      class="profile__person-photo profile__person-mini"
      icon="profile"
      :clickable="true"
      @click="toggleMenu"
    />

    <ThemeSwitcher class="theme-switcher" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import MetaMask from "@/mixins/MetaMask";
import ProfileMenu from "./ProfileMenu";
import ThemeSwitcher from "./ThemeSwitcher";

export default {
  components: {
    ThemeSwitcher,
    ProfileMenu
  },

  mixins: [MetaMask],

  data() {
    return {
      name: "Александр Иванов",
      profileMenu: false
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      unviewedMessageCount: "message/unviewedMessageCount",
      isChatVisible: "isChatVisible"
    })
  },

  async mounted() {
    await this.getUnviewedMessageCount();
  },

  methods: {
    ...mapActions({
      getUnviewedMessageCount: "message/getUnviewedMessageCount",
      setChatVisible: "setChatVisible"
    }),

    toggleMenu() {
      this.profileMenu = !this.profileMenu;
    },

    hideMenu() {
      this.profileMenu = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-switcher {
  @media screen and (max-width: 640px) {
    display: none !important;
  }
}

.profile {
  display: flex;
  align-items: center;

  &__person {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-right: 30px;

    &-photo {
      width: 40px;
      height: 40px;

      cursor: pointer;

      border-radius: 15px;
    }

    @media screen and (max-width: 640px) {
      display: none;
    }

    &-mini {
      display: none;
      @media screen and (max-width: 640px) {
        display: block;
      }
    }
  }
}

.menu {
  position: fixed;
  top: 80px;
  right: 30px;
  z-index: 100;
}

.pucket-button {
  white-space: nowrap;
  color: #ffffff;
}

.btn_metamask {
  display: flex;
  justify-content: center;
  align-items: center;
}

.metamask {
  background: url(@/assets/img/metamask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  margin-right: 10px;
}
</style>
