import axios from "axios";

export default class axiosClient {
  async getAuOwners() {
    const params = {
      pagination: false
    };
    const resp = await axios.get("api/au_owners", { params: params });
    return resp.data;
  }

  async getOwnerPage({ page = 1 }) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };

    const params = {
      page: page
    };

    const resp = await axios.get("api/au_owners", { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getCompanies() {
    const resp = await axios.get("api/companies");
    return resp.data;
  }

  async getCurrencyRate() {
    const resp = await axios.get("api/currency_rates");
    return resp.data;
  }

  async getTransactions() {
    const resp = await axios.get("api/transactions?methodName=transfer");
    return resp.data;
  }

  async getTokenHistory({ defaultTokenId, tokenId }) {
    const params = {
      page: 1,
      itemsPerPage: 7,
      isD: true,
      isW: false,
      isM: false,
      tokenId: tokenId,
      tokenQuoteId: defaultTokenId
    };

    const resp = await axios.get("api/history_courses", { params });
    return resp.data;
  }

  async getNftOwnersPage(page = 1, userId) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: page,
      userId: userId
    };
    const resp = await axios.get("api/nft_owners", { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getTransactionsPage({ pageId = 1, sort = "desc", auTokenId, adr }) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: pageId,
      itemsPerPage: 30,
      pagination: true,
      sortById: sort,
      auTokenId: auTokenId,
      address: adr
    };
    try {
      const resp = await axios.get("api/transactions", { headers, params });
      const data = resp.data["hydra:member"];
      const totalItems = resp.data["hydra:totalItems"];
      return { data, totalItems };
    }
    catch (error) {
      console.log(error);
    }
  }

  // delete after test end
  async getTransactionsPageTest({ pageId = 1, sort = "desc", auTokenId, adr }) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: pageId,
      itemsPerPage: 30,
      pagination: true,
      sortById: sort,
      auTokenId: auTokenId,
      address: adr
    };
    try {
      const resp = await axios.get("api/transactions", { headers, params });
      const data = resp.data["hydra:member"];
      const totalItems = resp.data["hydra:totalItems"];
      return { data, totalItems };
    }
    catch (error) {
      console.log(error);
    }
  }

  async getTransactionsPageOwnerFrom(pageId, ownerId) {
    const params = {
      page: pageId,
      itemsPerPage: 30,
      auOwnerFromId: ownerId
    };
    const resp = await axios.get("api/transactions", { params });
    return resp.data;
  }

  async getTransactionsPageOwnerTo(pageId, ownerId) {
    const params = {
      page: pageId,
      itemsPerPage: 30,
      auOwnerToId: ownerId
    };
    const resp = await axios.get("api/transactions", { params });
    return resp.data;
  }

  async getSectionMarket() {
    const resp = await axios.get("api/section_markets");
    return resp.data;
  }

  async getSectionCapital() {
    const resp = await axios.get("api/section_capitals");
    return resp.data;
  }

  async getCountry() {
    const resp = await axios.get("api/countries?pagination=false");
    return resp.data;
  }

  async getCounterparties() {
    const resp = await axios.get("api/user_contacts");
    return resp.data;
  }

  async getCounterparty() {
    await axios.get("api/user_contacts");
  }

  async postCounterparty(body) {
    await axios.post("api/user_contacts", body);
  }

  async postCapitalMp(body) {
    const resp = await axios.post("api/au_capitals/post_mp", body);
    return resp.data;
  }

  async patchCurrentUser({ userId, body }) {
    const headers = {
      "Content-Type": "application/merge-patch+json"
    };
    await axios.patch(`/api/users/${userId}`, body, { headers });
  }

  async getAuTokens() {
    const params = {
      type: "fiat,dao,gold,utility",
      pagination: false
    };
    const resp = await axios.get("api/au_tokens", { params });
    return resp.data;
  }

  async getAllAuCapitals({ pageId = 1 }) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: pageId,
      itemsPerPage: 30,
      pagination: true
    };
    try {
      const resp = await axios.get("api/au_capitals", { headers, params });
      const data = resp.data["hydra:member"];
      const totalItems = resp.data["hydra:totalItems"];
      return { data, totalItems };
    }
    catch (error) {
      console.log(error);
    }
  }

  async getSearchedAuCapitals(search) {
    const params = {
      page: 1,
      itemsPerPage: 30,
      description: search
    };
    const resp = await axios.get("api/au_capitals/search", { params });
    return resp.data;
  }

  async getUserFromNftTransactions(userId) {
    const params = {
      type: "transfer",
      userFrom: userId
    };

    const resp = await axios.get("api/nft_transactions", { params });
    return resp.data;
  }

  async getUserToNftTransactions(userId) {
    const params = {
      type: "transfer",
      userTo: userId
    };

    const resp = await axios.get("api/nft_transactions", { params });
    return resp.data;
  }

  async getFollowings() {
    const resp = await axios.get("api/followings");
    return resp.data;
  }

  async setFollowing(id) {
    const resp = await axios.post("api/followings/start", {
      auToken: `/api/au_tokens/${id}`
    });
    return resp.data;
  }

  async unsetFollowing(id) {
    const resp = await axios.post("api/followings/stop", {
      auToken: `/api/au_tokens/${id}`
    });
    return resp.data;
  }

  async setRequestToIssue(requestToIssue) {
    const resp = await axios.post("/api/request_to_issues", requestToIssue);
    return resp.data;
  }

  async getBalances(tokenQuote) {
    const resp = await axios.get("/api/au_owners/with_price/" + tokenQuote);
    return resp.data;
  }

  async sendTokenEvents(params) {
    await axios.post("api/token_events", params);
  }

  async sendTokenEventsById(id, params) {
    await axios.post(`api/token_events${id}`, params);
  }

  async register(body) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };

    await axios.post("api/users/register", body, { headers });
  }

  async defaultRequest(request) {
    const resp = await axios.get(request);
    return resp.data;
  }

  async getEthAddresses() {
    const resp = await axios.get("/api/eth_addresses");
    return resp.data;
  }

  async postEthAddresses(body) {
    await axios.post("/api/eth_addresses", body);
  }

  async patchEthAddresses(id, body) {
    const headers = {
      "Content-Type": "application/merge-patch+json"
    };

    await axios.patch(`/api/eth_addresses/${id}`, body, { headers });
  }

  async deleteEthAddresses(id) {
    await axios.delete(`/api/eth_addresses/${id}`);
  }

  async deleteCounterparty(id) {
    await axios.delete(`/api/user_contacts/${id}`);
  }

  async updateCounterparty(id, newValue) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    await axios.put(`/api/user_contacts/${id}`, newValue, headers);
  }

  async getRequestsToIssue() {
    const resp = await axios.get("api/request_to_issues");
    return resp.data;
  }

  async delRequestToIssue(id) {
    const resp = await axios.delete(`api/request_to_issues/${id}`);
    return resp.data;
  }

  async editRequestToIssue(id, params) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const resp = await axios.put(
      `api/request_to_issues/${id}`,
      params,
      headers
    );
    return resp.data;
  }

  async getValidateContent(currentEth) {
    const resp = await axios.get(
      `/api/eth_addresses/get_validate_content/${currentEth}`
    );
    return resp.data;
  }

  async signAndVerify(accountAddress, sign) {
    const resp = await axios.get(
      `/api/eth_addresses/sign_and_verify/${accountAddress}`,
      {
        params: { signature: sign }
      }
    );
    return resp.data;
  }

  async emailVerify(body) {
    const headers = {
      accept: "*/*",
      "Content-Type": "application/json"
    };

    return await axios.post("api/users/email_verify", body, headers);
  }

  async addCompany(params) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const resp = await axios.post("api/companies", params, headers);
    return resp.data;
  }

  async addCompanyFromWelcome(body) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const resp = await axios.post("api/companies", body, { headers });
    return resp.data;
  }

  async editCompany(id, params) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const resp = await axios.put(`api/companies/${id}`, params, headers);
    return resp.data;
  }

  async deleteCompany(id) {
    const resp = await axios.delete(`api/companies/${id}`);
    return resp.data;
  }

  async getExchangeBalances(userId, tokenId) {
    const params = {
      userId: userId,
      tokenId: tokenId,
      pagination: false
    };
    const resp = await axios.get("api/exchange_balances", { params: params });
    return resp.data;
  }

  async getHistory(dateFrom, dateTo, userId, tokenId) {
    const params = {
      dateFrom,
      dateTo,
      userId,
      tokenOfValueId: tokenId,
      pagination: false
    };

    const resp = await axios.get("api/history_briefcases", { params });
    return resp.data;
  }

  async getOrders(page, isFinish) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      isFinish: isFinish,
      page: page,
      itemsPerPage: 30
    };
    const resp = await axios.get("api/au_orders", {
      headers: headers,
      params: params
    });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getTrades(page, userId, pairId) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: page,
      userId: userId,
      pairId: pairId,
      itemsPerPage: 30
    };
    const resp = await axios.get("api/trades", { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getCourse(tokenId, tokenQuoteId) {
    const resp = await axios.get(
      `/api/au_tokens/${tokenId}/currency_rates/${tokenQuoteId}`
    );
    return resp.data.currency;
  }

  async getPairs() {
    const resp = await axios.get("/api/pairs");
    return resp.data;
  }

  async getOrderBook(pairId) {
    const resp = await axios.get(`/api/pairs/order-book/${pairId}?count=30`);
    return resp.data;
  }

  async getUserContact(page, itemsPerPage) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: page,
      itemsPerPage: itemsPerPage
    };
    const resp = await axios.get("/api/user_contacts", {
      headers: headers,
      params: params
    });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async unsubscribe(secret) {
    const resp = await axios.get(`/api/users/unsubscribe/${secret}`);
    return resp.data;
  }

  async changePassword(params) {
    try {
      const resp = await axios.post("/api/users/change_password", params);
      return resp;
    }
    catch (error) {
      return error;
    }
  }

  async getQr2FA(userId) {
    try {
      const resp = await axios.get(`api/users/${userId}/create_token`);
      return resp;
    }
    catch (error) {
      return error;
    }
  }

  async on2Fa(params) {
    try {
      const resp = await axios.post("api/users/set2fa", params);
      return resp;
    }
    catch (error) {
      return error;
    }
  }

  async off2Fa(params) {
    try {
      const resp = await axios.post("/api/users/set2fa", params);
      return resp;
    }
    catch (error) {
      return error;
    }
  }

  async getAuOwnerByTokenId(tokenId, userId) {
    const params = {
      auTokenId: tokenId,
      user: userId
    };
    const resp = await axios.get("api/au_owners", { params: params });
    return resp.data;
  }

  async getAuOwnerByType(type) {
    const params = {
      type: type
    };
    const resp = await axios.get("api/au_owners", { params: params });
    return resp.data;
  }

  async payCreate(value, tokenId) {
    const params = {
      auToken: `/api/au_tokens/${tokenId}`,
      value: value
    };
    const resp = await axios.post("/api/pays/create", params);
    return resp.data;
  }

  async getPay(id) {
    const resp = await axios.get(`/api/pays/${id}`);
    return resp.data;
  }
}
