<template>
  <div
    class="loader-wrapper"
    :class="isLightTheme ? 'light' : 'dark'"
  >
    <SpinnerLoader
      color="#5AC396"
      size="150px"
    />
  </div>
</template>

<script>
import localStorageController from "@/help/localStorageController";
import SpinnerLoader from "@/loaders/progress-spinner";

export default {
  components: {
    SpinnerLoader
  },

  data() {
    return {
      loaderStyle: ""
    };
  },

  computed: {
    isLightTheme() {
      return localStorageController.getIsLightTheme() == "true";
    }
  }
};
</script>

<style scoped>
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.light {
 background: #DFDFDF;
}

.dark {
 background: #404040;
}
</style>