import AxiosController from "@/api/axiosController";
const axiosController = new AxiosController();
export default {
  state: {
    app: null,
    notifyActive: false
  },

  getters: {
    app: state => {
      return state.app;
    },

    notifyActive: state => {
      return state.notifyActive;
    }
  },

  mutations: {
    setApp(state, app) {
      state.app = app;
    },

    setNotifyActive(state, notifyActive) {
      state.notifyActive = notifyActive;
    },

    SOCKET_ONOPEN() {
    },

    SOCKET_ONCLOSE() {
    },

    SOCKET_ONERROR() {
    },

    SOCKET_RECONNECT() {
    },

    SOCKET_RECONNECT_ERROR() {
    },

    SOCKET_ONMESSAGE(state, message) {
      if (message?.body) {
        state.app.config.globalProperties.$toast.success(message?.body, {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
        message.isViewed = false;
        axiosController.postMessage(message);
        state["marketplace/unviewedMessageCount"]++;
      }
    }
  },

  actions: {
    setApp({ commit }, app) {
      commit("setApp", app);
    },

    setNotifyActive({ commit }, notifyActive) {
      commit("setNotifyActive", notifyActive);
    },

    async sendCapital(context, arrayOfId) {
      context.getters.app.config.globalProperties.$socket.sendObj({ type: "client" });
      context.getters.app.config.globalProperties.$socket.sendObj({ capital: arrayOfId });
    },

    async sendNotify(context, id) {
      context.getters.app.config.globalProperties.$socket.sendObj({ type: "client" });
      context.getters.app.config.globalProperties.$socket.sendObj({ id: id });
    }
  }
};