<template>
  <div class="footer">
    <div
      class="divider"
    />
    <div class="info">
      <div class="copyright">
        © 2022 - {{ new Date().getFullYear() }}, АО «Autentic», {{ $t("footer.officialSite") }}
      </div>

      <div class="media">
        <AuIcon
          icon="vk"
          class="btn"
          is-link
          href="https://vk.com/public217319173"
          target="_blank"
        />
        <AuIcon
          icon="youtube"
          class="btn"
          is-link
          href="https://www.youtube.com/channel/UCDZECH3uoZbET29NBmG7bwg"
          target="_blank"
        />
        <AuIcon
          icon="telegram"
          class="btn"
          is-link
          href="https://t.me/Atentic_channel"
          target="_blank"
        />
        <AuIcon
          icon="twitter"
          class="btn"
          is-link
          href="https://twitter.com/AutenticCapital"
          target="_blank"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped lang="scss">

.footer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
}

.media {
  display: flex;
  gap: 5px;
  margin-right: -15px;
}

.divider {
  border-bottom: 1px solid var(--color-70);
  width: 100%;
  margin-top: 65px;
}

.copyright {
  font-size: 13px;
  color: var(--text-color);
}

.info {
  display: flex;
  justify-content: space-between;
  margin: 10px 5px;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
</style>