<template>
  <div class="user-view">
    <div class="form-and-image">
      <div
        class="panel"
      >
        <div class="logo-scale">
          SoluExchange
        </div>
        <div
          v-if="isSuccess"
          class="text-center mt-4"
        >
          <div
            class="text-3xl font-medium mb-3"
            style="color: green"
          >
            Thank for registering!
          </div>
          <span class="text-600 font-medium">The e-mail is confirmed. You can
          </span>
          <RouterLink :to="'/login'">
            Login
          </RouterLink>
        </div>
        <div
          v-if="isError"
          class="text-center mt-4"
        >
          <p
            class="text-3xl font-medium mb-3"
            style="color: red"
          >
            The e-mail is not confirmed!
          </p>
          <span class="text-600 font-medium">Try to </span>
          <RouterLink :to="'/register'">
            Register
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Verify",
  data() {
    return {
      isSuccess: false,
      isError: false
    };
  },
  created() {
    this.checkEmail();
  },
  methods: {
    ...mapActions({
      emailVerify: "emailVerify"
    }),

    confirm() {
      console.log("111");
    },

    async checkEmail() {
      const hash = this.$route.params.hash;

      const params = {
        token: `${hash}`
      };
      try {
        const resp = await this.emailVerify(params);
        this.isError = false;
        this.isSuccess = true;
        console.log("resp", resp);
      }
      catch (e) {
        if (e.response.status === 400) {
          this.isError = true;
          this.isSuccess = false;
          console.log("err", e.response);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),
    buttonText() {
      return this.isLogin ? "Войти" : "Зарегистрироваться";
    }
  }
};
</script>

<style scoped lang="scss">
.logo-scale {
  font-size: 20px;
  display: flex;
  justify-content: center;
}
.user-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--main-color);
  height: 100vh;
  width: 100vw;
}
.form-and-image{
  display: flex;
  margin: auto;
}
.panel{
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-90);
  border-radius: 20px;
  padding: 20px;
  background: var(--card-color);
}
.logo-scale{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

</style>
