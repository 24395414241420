<template>
  <div class="nav-header">
    <div class="work-space">
      <div
        v-for="page in pages"
        :key="page.name"
        class="nav-link"
        @click="setRoute(page)"
      >
        <span
          class="drop-indent"
          :class="{ 'nav-active': $route.name == page.name }"
        >
          {{ page.meta.title }}
        </span>
        <AuIcon
          v-if="page.meta.showMenu"
          icon="arrow-down"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "NavPanel",

  computed: {
    pages() {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      if (parent) {
        return _.filter(parent.children, child => _.some(_.get(child, "meta.access"), acess => acess == this.$auth.user().type));
      }
      else {
        return [];
      }
    }
  },
  methods: {
    setRoute(page) {
      this.$router.push({ name: page.name });
    }
  }
};
</script>

<style scoped lang="scss">
.nav-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  background: var(--main-color);
  border-bottom: 1px solid var(--color-90);
}
.work-space{
  width: 40%;
  display: flex;
  justify-content: space-between;
  padding-left: 25px;
}
.nav-active{
  color: #28E593;
}
.nav-link{
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
}
.drop-indent{
  margin-right: 10px;
}
</style>