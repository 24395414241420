<template>
  <div
    v-if="$route.params.id"
    class="card request"
  >
    <div class="first-line-wrapper">
      <div class="tocken-label-wrapper">
        <div class="img-wrapper">
          <img
            :src="tockenImage"
            :alt="name"
          >
        </div>

        <span>{{ name }}</span>
      </div>
      <div class="swap-wrapper">
        <AuDecimalStatic
          class="balance"
          :value="balance"
          :decimal="decimals"
          :indent="indent"
          :addition="mnemonic"
        />
        <AuIcon
          icon="swap_horizontal_green"
        />
        <span>
          1 {{ defaultTokenMnemonic }}
        </span>
      </div>
    </div>

    <div class="balance-wrapper">
      <span class="balance">
        <AuDecimalStatic
          class="balance"
          :value="balance"
          :decimal="decimals"
          :indent="indent"
        />
        {{ mnemonic }}</span>
      <span>
        {{ $t("sendToken.balance") }}
      </span>
    </div>
    <form
      class="form"
      @submit.prevent="submit"
    >
      <div
        class="form-inner"
      >
        <div class="form-line">
          <div class="input-title">
            {{ $t("sendToken.address") }}
          </div>
          <div class="address-line">
            <div class="address-input-wrapper">
              <AuInput
                v-model="transferWallet"
                class="send-tocken-input"
                type="Mask"
                :mask="/^[0xa-fA-F0-9]+$/i"
                :placeholder="$t('sendToken.addressPlaceholder')"
              />
              <div
                v-if="transferWallet && !isCorrectETH"
                class="error"
              >
                {{ $t('sendToken.incorrectAddress') }}
              </div>
            </div>
            <AuButton
              bordered
              border-radius="10"
              center
              width="180px"
              height="45px"
              @click="selectFromCounterparties=true"
            >
              {{ $t('sendToken.myCounterparties') }}
            </AuButton>
          </div>
          <div
            v-if="selectedCounterparty"
            class="is-counterparty"
          >
            <span>
              {{ $t('sendToken.haveCounterparty') }}
            </span>
            <div>{{ selectedCounterparty }}</div>
          </div>
          <div
            v-else-if="isCorrectETH && !selectedCounterparty"
            class="no-counterparty"
          >
            <span>{{ $t('sendToken.noCounterparty') }}</span>

            <div class="input-title">
              {{ $t('sendToken.newCounterpartyNameLabel') }}
            </div>
            <div class="address-line">
              <AuInput
                v-model="newCounterpartyName"
                class="send-tocken-input"
                type="Mask"
                :placeholder="$t('sendToken.newCounterpartyNamePlaceholder')"
              />
              <AuButton
                bordered
                border-radius="10"
                center
                :class="{ 'added' : added }"
                width="180px"
                height="45px"
                @click="save"
              >
                {{ added ?
                  $t('sendToken.newCounterpartyAdded'):
                  $t('sendToken.newCounterpartyAdd') }}
              </AuButton>
            </div>
          </div>
        </div>

        <div class="form-line">
          <div class="input-title">
            {{ $t('sendToken.transferSum') }}
          </div>
          <div class="d-flex align-items-center">
            <AuDecimalInput
              :value="transferAmount"
              :decimal="decimals"
              :indent="indent"
              :placeholder="$t('sendToken.transferSumPlaceholder')"
              @input="value => transferAmount = value"
            />
            <span class="mnemonic">{{ mnemonic }}</span>
          </div>
        </div>

        <div class="buttons-wrapper">
          <div class="d-flex justify-content-between gap-3">
            <AuButton
              bordered
              width="120px"
              height="44px"
              center
              :border-radius="10"
              type="reset"
              @click="reset"
            >
              {{ $t('sendToken.cancel') }}
            </AuButton>
            <AuButton
              bordered
              class="submit-mobile"
              width="140px"
              height="44px"
              center
              :border-radius="10"
              active-bg-color="#279063"
              type="submit"
              :disabled="!isCorrectETH || isTransactionActive"
              @click="confirmationMethodChoice = true"
            >
              {{ $t('sendToken.send') }}
            </AuButton>
          </div>

          <div class="right-btns">
            <AuButton
              bordered
              class="submit-desktop"
              width="140px"
              height="44px"
              center
              :border-radius="10"
              active-bg-color="#279063"
              type="submit"
              :disabled="!isCorrectETH || isTransactionActive"
              @click="confirmationMethodChoice = true"
            >
              {{ $t('sendToken.send') }}
            </AuButton>
            <AuButton
              bordered
              width="100%"
              center
              height="44px"
              icon="metamask"
              :border-radius="10"
              :disabled="accountAddress == null"
              @click="sendMetamaskToken"
            >
              {{ $t('sendToken.sendTroughCryptoWallet') }}
            </AuButton>
          </div>
        </div>

        <div v-if="accountAddress == null">
          <div class="my-3">
            <div
              class="alert alert-warning"
              role="alert"
            >
              Адрес не верифицирован!
            </div>
          </div>
        </div>
      </div>
    </form>
    <AuModal
      v-model="selectFromCounterparties"
      width="360px"
      height="450px"
    >
      <template #header>
        <span class="modal_head_text">
          {{ $t('sendToken.form.label') }}
        </span>
      </template>
      <template #body>
        <div class="d-flex modal-wrapper">
          <div class="search-wrapper">
            <AuInput
              v-model="search"
              icon="search"
              clearable
              :placeholder="$t('sendToken.form.searchPlaceholder')"
            />
          </div>
          <div class="list-wrapper">
            <div
              v-for="(value, key) in shownCounterparties"
              :key="value"
              class="section-wrapper"
            >
              <div class="letter-wrapper">
                {{ key }}
              </div>
              <div
                v-for="val in value"
                :key="val"
                class="result-wrapper"
                @click="selectCounterparty(val)"
              >
                {{ val.name }} <br> <span>{{ $filters.shortAddress(val.address) }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="noResult"
            class="no-result"
          >
            <span>{{ $t("sendToken.form.noSearchResults") }}</span>
          </div>
        </div>
      </template>
    </AuModal>
    <AuModal
      v-model="confirmationMethodChoice"
      width="420px"
      :height="conformationModalHeight"
    >
      <template #header>
        <span class="modal_head_text">
          Выберете способ подтверждения
        </span>
      </template>
      <template #body>
        <div class="d-flex modal-code-wrapper">
          <AuSelect
            :value="confirmationType"
            :options="confirmationOptions"
            select-label="Способ подтверждения"
            placeholder="Выберите способ подтверждения"
            @input="value => confirmationType = value"
          />

          <AuButton
            v-if="!showConfirmationCodeInput"
            bordered
            height="44px"
            center
            :border-radius="10"
            :disabled="!confirmationType"
            @click="confirmationTypeSelect"
          >
            Выбрать способ подтверждения
          </AuButton>

          <AuInput
            v-if="showConfirmationCodeInput"
            v-model="confirmationCode"
            class="code-input"
            placeholder="Введите код подтверждения"
          />

          <AuButton
            v-if="showConfirmationCodeInput"
            bordered
            class="submit-desktop"
            width="140px"
            height="44px"
            center
            :border-radius="10"
            active-bg-color="#279063"
            type="submit"
            :disabled="!confirmationCode"
            @click="sendRequest"
          >
            {{ $t('sendToken.send') }}
          </AuButton>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import CurrencySupport from "@/help/currencySupport";
import MetaMask from "@/mixins/MetaMask";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [MetaMask],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      transferWallet: null,
      transferAmount: null,
      currencySup: new CurrencySupport(),
      selectFromCounterparties: false,
      search: null,
      newCounterpartyName: "",
      added: false,
      isTransactionActive: false,
      balance: null,
      owner: null,
      decimals: null,
      indent: null,
      confirmationMethodChoice: false,
      confirmationType: null,
      showConfirmationCodeInput: false,
      confirmationCode: null
    };
  },
  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      tokens: "tokens",
      counterparties: "counterparties",
      accountAddress: "accountAddress",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),

    isOn2Fa() {
      return this.$auth.user().is2faEnabled;
    },

    confirmationOptions() {
      let confirmationOptions = [];
      if (this.isOn2Fa) {
        confirmationOptions.push({ display: "По 2fa", value: "2faCode" });
      }
      else {
        confirmationOptions.push({ display: "По E-mail", value: "mailCode" });
      }
      return confirmationOptions;
    },

    conformationModalHeight() {
      return `${this.showConfirmationCodeInput ? 340 : 280}px`;
    },

    isCorrectETH() {
      return this.$filters.correctETH(this.transferWallet);
    },

    counterpartiesDictionary() {
      let counterparties = _.cloneDeep(this.counterparties);
      let dict = {};
      counterparties.forEach(counterparty => {
        if (!(counterparty.name[0] in dict)) {
          dict[counterparty.name[0]] = [counterparty];
        }
        else {
          dict[counterparty.name[0]].push(counterparty);
        }
      });
      const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
      return sortObject(dict);
    },

    noResult() {
      return _.isEmpty(this.shownCounterparties);
    },

    shownCounterparties() {
      if (this.search) {
        let shown = _.cloneDeep(this.counterpartiesDictionary);
        let finded = {};
        for (let key in shown) {
          shown[key].forEach(counterparty => {
            if (_.includes(counterparty.name.toLowerCase(), this.search.toLowerCase()) || _.includes(counterparty.address, this.search)) {
              if (!(counterparty.name[0] in finded)) {
                finded[counterparty.name[0]] = [counterparty];
              }
              else {
                finded[counterparty.name[0]].push(counterparty);
              }
            }
          });
        }
        return finded;
      }
      else {
        return this.counterpartiesDictionary;
      }
    },

    selectedCounterparty() {
      let counterparty = _.find(this.counterparties, { address: this.transferWallet });
      if (counterparty) {
        // let splittedName = counterparty.name.split(" ");
        // let abbrName;
        // if (splittedName.length > 2) {
        //   abbrName = `${splittedName[0]} ${splittedName[1][0]}.`;
        // }
        // else {
        //   abbrName = splittedName[0];
        // }
        return counterparty.name;
      }
      return null;
    },

    name() {
      return _.find(this.tokens, token => token.id == this.$route.params.id).nameEng;
    },

    mnemonic() {
      return _.find(this.tokens, token => token.id == this.$route.params.id).mnemonicEng;
    },

    tockenImage() {
      let imageName = _.find(this.tokens, token => token.id == this.$route.params.id).image;
      return `${process.env.VUE_APP_HOST_API}images/au_token/${imageName}`;
    }
  },
  watch: {
    confirmationMethodChoice() {
      this.confirmationType = null,
      this.showConfirmationCodeInput = false;
      this.confirmationCode = null;
    },
    confirmationType() {
      this.showConfirmationCodeInput = false;
      this.confirmationCode = null;
    },
    transferWallet() {
      this.added = false;
      this.newCounterpartyName = "";
    },
    selectFromCounterparties(val) {
      if (!val) {
        this.search = "";
      }
    }
  },
  async mounted() {
    const data = await this.getAuOwnerByTokenId({ tokenId: this.id, userId: this.$auth.user().id });
    this.owner = data[0];
    this.balance = this.owner.amount;
    this.decimals = this.owner.auToken.decimals;
    this.indent = this.owner.auToken.indent;
  },
  methods: {
    ...mapActions({
      postCounterparty: "postCounterparty",
      sendTokenEvents: "sendTokenEvents",
      getAuOwnerByTokenId: "getAuOwnerByTokenId"
    }),

    async confirmationTypeSelect() {
      if (this.confirmationType == "2faCode") {
        this.showConfirmationCodeInput = true;
      }
      else {
        await this.sendRequest();
        this.showConfirmationCodeInput = true;
      }
    },

    async sendMetamaskToken() {
      let token = _.find(this.tokens, token => token.id == this.id);

      if (token.createdAddress) {
        try {
          await this.sendToken(token, this.transferWallet, this.transferAmount, this.accountAddress);

          this.$toast.success("Токен отправлен", {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        }
        catch (error) {
          console.error(error);
          this.$toast.error("Ошибка при отправлении токена", {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        }
      }
    },

    reset() {
      this.$router.push({ name: "briefcase" });
    },

    selectCounterparty(val) {
      this.transferWallet = val.address;
      this.selectFromCounterparties = false;
    },

    async save() {
      try {
        await this.postCounterparty({
          name: this.newCounterpartyName,
          address: this.transferWallet,
          status: "Контрагент",
          user: `/api/users/${this.$auth.user().id}`
        });
        this.added = true;
        this.$toast.success(this.$t("toasts.success.contactCreate"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
      catch (error) {
        this.$toast.error(this.$t("toasts.error.contactCreate"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    },

    async sendRequest() {
      this.isTransactionActive = true;

      try {
        let params = {
          user: `api/users/${this.$auth.user().id}`,
          createdAt: moment().format("DD.MM.YYYY"),
          type: "token_transfer",
          description: "send",
          value: this.transferAmount,
          addressAfter: this.transferWallet,
          token: `api/au_tokens/${this.$route.params.id}`,
          address: this.owner.ownerAddress, // this.owner ? this.owner.ownerAddress : null,
          isTask: true
        };

        if (this.confirmationCode) {
          params.authCode = this.confirmationCode;
        }

        await this.sendTokenEvents(params);
        this.$toast.success(this.$t("toasts.success.tokenSend"), {
          position: "top",
          duration: 4000,
          dismissible: true
        });
        this.isTransactionActive = false;
        this.confirmationMethodChoice = false;
        this.$router.push({ name: "briefcase" });
      }
      catch (err) {
        if (!this.confirmationCode) {
          this.$toast.success("Код подтверждения отправлен на E-mail", {
            position: "top",
            duration: 4000,
            dismissible: true
          });
        }
        else {
          this.$toast.error(this.$t("toasts.error.tokenSend"), {
            position: "top",
            duration: 4000,
            dismissible: true
          });
        }

        this.isTransactionActive = false;
      }
    }
  }
};
</script>
<style lang="scss">
.send-tocken-input {
  .input_block {
    height: 44px;
  }
}
</style>
<style lang="scss" scoped>
.form {
  margin-top: 45px;
  position: relative;
}

.form-inner {
  display: flex;
  gap: 35px;
  flex-direction: column;
}
.form-request-result {
  position: absolute;
  visibility: hidden;
  opacity: 0;

  top: 50%;
  left: 50%;
}
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  gap: 15px;
  .submit-mobile {
    display: none;
  }
  .right-btns {
    display: flex;
    gap: 15px;
  }
  @media screen and (max-width:560px) {
    flex-direction: column;
    .submit-mobile {
      display: block;
    }
    .submit-desktop {
      display: none;
    }
  }
}
.input {
  height: 45px;
  border: 1px solid var(--color-80);
  color: var(--color-80);
  padding: 15px;
  border-radius: 14px;
  width: 100%;

  &:not(:placeholder-shown) {
    color: var(--text-color);
  }
}

.input-title {
  font-size: 14px;
  color: var(--color-60);
  margin-bottom: 15px;
}
.address-line {
  display: flex;
  gap: 20px
}
.request {
  padding: 20px;

  &-header {
    margin-top: 50px;
  }
}
.btn_metamask {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}
.mnemonic{
  position: absolute;
  right: 15px;
  color: var(--color-60);
  font-size: 14px;
}
.modal-wrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  margin: 0px -10px;
}
.no-result {
  padding: 0 30px;
  span {
    font-size: 14px;
  }
}
.address-input-wrapper {
  width: 100%;
  position: relative;
  .error {
    font-size: 12px;
    margin-top: 5px;
    position: absolute;
  }
}
.search-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 30px;
  margin-bottom: 30px;
}
.is-counterparty {
  span {
    color: #2ABA7C;
    font-size: 12px;
    display: block;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  div {
    padding: 12px 15px;
    font-size: 12px;
    border: 1px solid #373737;
    border-radius: 100px;
    width: fit-content;
  }
}
.no-counterparty {
  span {
    color: #D17440;
    font-size: 12px;
    display: block;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
.modal_head_text {
  font-weight: 600;
  font-size: 20px;
  padding: 20px 20px 0 20px;
}
.letter-wrapper {
  font-size: 12px;
  padding: 10px 30px;
  background: #262626;
}
.section-wrapper {
  .result-wrapper {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      color: var(--color-60);
    }
    &:not(:last-child) {
      border-bottom: 2px solid #373737;
    }
    &:hover {
      background: #4d4d4d;
      cursor: pointer;
    }
  }
}
.modal-code-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px;
}
.added {
  border-color: #2ABA7C;
}
.tocken-label-wrapper {
  display: flex;
  gap: 10px;
  .img-wrapper {
    width: 26px;
    height: 26px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 22px;
      height: 22px;
    }
  }
  span {
    font-weight: 600;
    font-size: 18px;
  }
}
.balance-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  span {
    &.balance {
      font-weight: 600;
      font-size: 24px;
    }
    &:not(.balance) {
      font-size: 12px;
      color: var(--color-50);
    }
  }
}
.first-line-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.swap-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 16px;

}
</style>