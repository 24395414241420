import App from "./pages/App.vue";
import auth from "./auth";
import axios from "axios";
import Components from "./components/extended/components";
import FloatingVue from "floating-vue";
import router from "./router";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import VueAxios from "vue-axios";
import Websocket from "vue-native-websocket-vue3";
// import VueYandexMetrika from "vue-yandex-metrika";
import VueYandexMetrika from "vue3-yandex-metrika";
import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";

import "../public/scss/main.scss";
import "bootstrap/dist/css/bootstrap.css";

import i18n from "./localization.js";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(Components)
  .use(Toaster)
  .use(i18n)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(VueAxios, axios)
  .use(FloatingVue)
  .use(auth)
  .component("Apexchart", VueApexCharts);
app.axios.defaults.baseURL = process.env.VUE_APP_HOST_API;

if (process.env.VUE_APP_USE_WEBSOCKET !== "false") {
  store.dispatch("setApp", app);
  app.use(Websocket, `${process.env.VUE_APP_NOTIFY}/message`, {
    store: store,
    reconnection: true,
    reconnectionDelay: 10000,
    format: "json"
  });
}

app.config.globalProperties.$filters = {
  correctETH(eth) {
    return /^0x[a-fA-F0-9]{40}$/.test(eth);
  },
  shortAddress: (address) => {
    return address ? address.substr(0, 5) + "..." + address.substr(-5) : "";
  }
};

app.mount("#app");

app.use(VueYandexMetrika, {
  id: 93842530,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: false
  }
});

app.use(createGtm({
  id: "GTM-852E4501E744"
}));

import "bootstrap/dist/js/bootstrap.js";
import "floating-vue/dist/style.css";